import React from 'react'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
// import { navigate } from 'gatsby'
import {
  withStyles,
} from '@material-ui/core'
import {
  CREATE,
  UPDATE,
} from '@src/constants/keys'
import { inject, observer } from 'mobx-react'
import Fade from '@material-ui/core/Fade'
import DeveloperMenu from '@src/components/layouts/DeveloperMenu'
import AdminMenu from '@src/components/layouts/AdminMenu'
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone'
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone'
import LanguageTwoToneIcon from '@material-ui/icons/LanguageTwoTone'
import Badge from '@material-ui/core/Badge'
import NotificationsNoneTwoToneIcon from '@material-ui/icons/NotificationsNoneTwoTone'
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone'
import { withTranslation } from 'react-i18next'
import { getLocalStorage, setLocalStorage, toJS } from '@src/utils/commonUtil'
import LooksOneIcon from '@material-ui/icons/LooksOne'
import LooksTwoIcon from '@material-ui/icons/LooksTwo'
import { prop } from 'ramda'

const drawerWidth = 260

const styles = theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
})

@withTranslation()
@withStyles(styles)
@inject('authStore', 'structureStore', 'contentStore', 'adminStore', 'uiStore')
@observer

class MiniDrawerLayout extends React.Component {
  constructor (props) {
    super(props)
    let isDrawerOpened = false
    if (window.screen.width > 800) {
      isDrawerOpened = true
    }
    let isAdminMode = false
    let isDeveloperMode = false
    const { authStore } = props
    if (authStore.jwt && authStore.jwt.role === 'ADMIN') {
      isAdminMode = true
    }
    if (authStore.jwt && authStore.jwt.role === 'DEVELOPER') {
      isDeveloperMode = true
    }
    if (getLocalStorage('MODE') === 'ADMIN') {
      isAdminMode = true
      isDeveloperMode = false
    }
    if (getLocalStorage('MODE') === 'DEVELOPER') {
      isDeveloperMode = true
      isAdminMode = false
    }
    this.state = {
      isDrawerOpened: isDrawerOpened,
      isStructureDialogOpened: false,
      isMenuItemVisableList: [],
      openAccountDialog: false,
      accountAnchorEl: null,
      languageAnchorEl: null,
      isAdminMode: isAdminMode,
      isDeveloperMode: isDeveloperMode
    }
  }

  componentDidMount () {
    // this.fetchStructureList()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    if (window.screen.width > 800) {
      this.setState({
        isDrawerOpened: true
      })
    } else {
      this.setState({
        isDrawerOpened: false
      })
    }
  }

  toogleAccountDialog = () => {
    this.setState({
      openAccountDialog: !this.state.openAccountDialog,
      accountAnchorEl: null
    })
  }

  openLanguageMenu = (event) => {
    this.setState({
      languageAnchorEl: event.currentTarget
    })
  }

  closeLanguageMenu = () => {
    this.setState({
      languageAnchorEl: null
    })
  }

  openAccountMenu = (event) => {
    this.setState({
      accountAnchorEl: event.currentTarget
    })
  }

  closeAccountMenu = () => {
    this.setState({
      accountAnchorEl: null
    })
  }

  initMenuItemActionList = async () => {
    const { structureStore } = this.props
    const list = structureStore.list.map(() => {
      return true
    })
    this.setState({ isMenuItemVisableList: list })
  }

  toogleMenuItem = (index) => {
    let list = this.state.isMenuItemVisableList
    list[index] = !this.state.isMenuItemVisableList[index]
    this.setState({ isMenuItemVisableList: list })
  }

  toogleStructureDialog = () => {
    this.setState({ isStructureDialogOpened: !this.state.isStructureDialogOpened })
  }

  toggleDrawer = () => {
    this.setState({ isDrawerOpened: !this.state.isDrawerOpened })
  }

  fetchStructureList = () => {
    const { structureStore } = this.props
    structureStore.fetchStructureList()
    // await this.initMenuItemActionList()
  }

  handleSubmitForm = async (values, { setSubmitting, setErrors }) => {
    const { structureStore } = this.props
    await structureStore.createStructure(values)
    // this.fetchStructureList()
    this.toogleStructureDialog()
  }

  handleLogout = async () => {
    const { logout } = this.props.authStore
    const result = await logout()
    if (result) {
      window.location.replace('/login')
    }
  }

  handleChangeLanguage = async (lang) => {
    this.props.i18n.changeLanguage(lang)
    // console.log(this.props.i18n.language)
    // const { changeLanguage } = this.props.uiStore
    await this.props.uiStore.changeLanguage(lang)
    this.closeLanguageMenu()
  }

  switchAdminMode = async () => {
    setLocalStorage('MODE', 'ADMIN')
    this.setState({ isAdminMode: true, isDeveloperMode: false })
  }

  switchDeveloperMode = async () => {
    setLocalStorage('MODE', 'DEVELOPER')
    this.setState({ isAdminMode: false, isDeveloperMode: true })
  }

  render () {
    const { switchDeveloperMode, switchAdminMode, handleChangeLanguage, openLanguageMenu, closeLanguageMenu, openAccountMenu, closeAccountMenu, toogleAccountDialog, handleLogout, updateAccount } = this
    const { classes, authStore, uiStore } = this.props
    const { toogleMenuItem, toggleDrawer, toogleStructureDialog, handleSubmitForm } = this
    const { isAdminMode, isDeveloperMode, isDrawerOpened, isStructureDialogOpened, isMenuItemVisableList, accountAnchorEl, languageAnchorEl } = this.state
    const { structureStore, contentStore } = this.props
    // toJS(authStore.jwt)
    // console.log(authStore.jwt)
    // toJS(uiStore.allContents)
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          color='secondary'
          elevation={0}
          position='fixed'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: isDrawerOpened
          })}
          style={{ borderBottom: '1px solid #e7e8f2' }}
        >
          <Toolbar>
            <div className='flex row space-between align-items-center full-width'>
              <div>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={toggleDrawer}
                  edge='start'
                  className={clsx(classes.menuButton, isDrawerOpened && classes.hide)}
                >
                  <MenuTwoToneIcon color='primary' />
                </IconButton>
                {/* <IconButton onClick={openAccountMenu}>
                  <SearchTwoToneIcon color='primary' />
                </IconButton> */}
              </div>
              <div>
                {/* <IconButton onClick={openAccountMenu}>
                  <Badge badgeContent={1} color='primary'><NotificationsNoneTwoToneIcon color='primary' /></Badge>
                </IconButton> */}
                {
                  authStore.jwt && authStore.jwt.role === 'DEVELOPER' && (
                    <>
                      <IconButton onClick={switchDeveloperMode}>
                        <LooksOneIcon color='primary' />
                      </IconButton>
                      <IconButton onClick={switchAdminMode}>
                        <LooksTwoIcon color='primary' />
                      </IconButton>
                      <IconButton onClick={openLanguageMenu}>
                        <LanguageTwoToneIcon color='primary' />
                      </IconButton>
                    </>
                  )
                }
                <Menu
                  anchorEl={languageAnchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  // keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(languageAnchorEl)}
                  onClose={closeLanguageMenu}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleChangeLanguage.bind(this, 'en')}>EN</MenuItem>
                  <MenuItem onClick={handleChangeLanguage.bind(this, 'tc')}>TC</MenuItem>
                  <MenuItem onClick={handleChangeLanguage.bind(this, 'sc')}>SC</MenuItem>
                </Menu>
                <IconButton
                  onClick={openAccountMenu}
                  color='inherit'
                >
                  <AccountCircleTwoToneIcon color='primary' />
                </IconButton>
                <Menu
                  anchorEl={accountAnchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  // keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(accountAnchorEl)}
                  onClose={closeAccountMenu}
                  TransitionComponent={Fade}
                >
                  {/* <MenuItem onClick={toogleAccountDialog}>帳戶 </MenuItem> */}
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          </Toolbar>
          {/* <Toolbar>
            <div>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={toggleDrawer}
                edge='start'
                className={clsx(classes.menuButton, {
                  [classes.hide]: isDrawerOpened,
                })}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                // keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={closeAccountMenu}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={toogleAccountDialog}>帳戶 </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar> */}
        </AppBar>
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isDrawerOpened,
            [classes.drawerClose]: !isDrawerOpened
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: isDrawerOpened,
              [classes.drawerClose]: !isDrawerOpened
            }),
          }}
        >
          <div className='menu-nav full-height column space-between'>
            <div className='column'>
              <div className={classes.toolbar, 'menu-nav-top'}>
                <div className='flex row space-between align-items-center full-width'>
                  <div className='logo-text'>{uiStore.panelName}</div>
                  {/* <IconButton color='primary' onClick={toggleDrawer}>
                    {isDrawerOpened === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton> */}
                </div>
              </div>
              {/*
              <Divider />
              <List>
                {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                  <ListItem button key={text}>
                    <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
              <Divider />
              <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                  <ListItem button key={text}>
                    <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
              */}
              {
                authStore.jwt && authStore.jwt.role === 'ADMIN' && (
                  <AdminMenu toggleDrawer={toggleDrawer} structureList={structureStore.list} />
                )
              }
              {
                authStore.jwt && authStore.jwt.role === 'DEVELOPER' && isAdminMode && (
                  <AdminMenu toggleDrawer={toggleDrawer} structureList={structureStore.list} />
                )
              }
              {
                authStore.jwt && authStore.jwt.role === 'DEVELOPER' && isDeveloperMode && (
                  <DeveloperMenu toggleDrawer={toggleDrawer} structureList={structureStore.list} />
                )
              }
            </div>
            {/* <div className={clsx(classes.drawerFooter)}>
              <div className={'add-structure-btn'}>
                <StructureDialog
                  action={CREATE}
                  isDialogOpened={isStructureDialogOpened}
                  toogleDialog={toogleStructureDialog}
                  submitForm={handleSubmitForm}
                  initialValues={structureStore.form}
                />
              </div>
            </div> */}
          </div>

        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    )
  }
}

export default MiniDrawerLayout
