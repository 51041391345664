import React from 'react'
import AdminForm from '@src/components/forms/AdminForm'
import {
  // CustomTextField
  // DialogContentText,
  // FormControl,
  // FormControlLabel,
  // InputLabel,
  // MenuItem,
  // List,
  // ListItem,
  // Select,
  // Switch,
  // ListItemIcon,
  // ListItemText,
  // InboxIcon,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import {
  CREATE,
  UPDATE
} from '@src/constants/keys'
import CloseIcon from '@material-ui/icons/Close'
import { toJS } from '@src/utils/commonUtil'

class FormDialog extends React.Component {
  getTitle = () => {
    const { title, action } = this.props
    switch (action) {
      case CREATE:
        return `Create ${title}`
      case UPDATE:
        return `Update ${title}`
      default:
        break
    }
    return ''
  }

  render () {
    const { dialogMaxWidth, form, toogleDialog, isDialogOpened, submitForm } = this.props
    const _title = this.getTitle()
    return (
      <>
        <Dialog
          fullWidth
          maxWidth={dialogMaxWidth}
          open={isDialogOpened}
          onClose={toogleDialog}
          disableEscapeKeyDown
          // disableBackdropClick
        >
          <DialogTitle disableTypography className='flex-row space-between'>
            <h2>{_title}</h2>
            <div onClick={toogleDialog}><CloseIcon /></div>
          </DialogTitle>
          <DialogContent>
            <Box mb={3} />
            {form}
            <Box mb={3} />
          </DialogContent>
          <DialogActions>
            <div className='flex-row flex-end'>
              <Button disableElevation variant='contained' color='primary' onClick={submitForm}>Submit</Button>
              <div className='mr-10' />
              <Button disableElevation variant='contained' onClick={toogleDialog}>Cancel</Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default FormDialog
