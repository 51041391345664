import ExampleStore from '@src/stores/ExampleStore'
import AuthStore from '@src/stores/AuthStore'
import StructureStore from '@src/stores/StructureStore'
import StructureFieldStore from '@src/stores/StructureFieldStore'
import ContentStore from '@src/stores/ContentStore'
import UserStore from '@src/stores/UserStore'
import AdminStore from '@src/stores/AdminStore'
import FileUploaderStore from '@src/stores/FileUploaderStore'
import CategoryStore from '@src/stores/CategoryStore'
import UiStore from '@src/stores/UiStore'

export default class RootStore {
  constructor () {
    this.exampleStore = new ExampleStore(this)
    this.authStore = new AuthStore(this)
    this.structureStore = new StructureStore(this)
    this.structureFieldStore = new StructureFieldStore(this)
    this.contentStore = new ContentStore(this)
    this.userStore = new UserStore(this)
    this.adminStore = new AdminStore(this)
    this.fileUploaderStore = new FileUploaderStore(this)
    this.categoryStore = new CategoryStore(this)
    this.uiStore = new UiStore(this)
  }
}
