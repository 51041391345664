import {
  IMAGE,
  VIDEO,
  DOCUMENT,
  FILE,
  CREATE,
  UPDATE,
  DELETE,
  SEARCH
} from '@src/constants/keys'

// auth
export const login = 'cms/auth/login'
export const refresh = 'cms/auth/refresh'
export const register = 'cms/auth/register'
export const refreshToken = 'cms/auth/refresh-token'
export const account = 'cms/auth/account'

// export const forgotPassword = 'auth/forgot-password'
// export const resetPassword = 'auth/reset-password'
// export const changePassword = 'auth/change-password'

// init
export const init = () => 'cms/init'

// admin
export const fetchAdminList = () => 'cms/admin'
export const fetchSingleAdmin = (adminId) => `cms/admin/${adminId}`
export const createAdmin = () => 'cms/admin'
export const deleteAdmin = (adminId) => `cms/admin/${adminId}`
export const updateAdmin = (adminId) => `cms/admin/${adminId}`

// structure
export const fetchStructureList = () => 'cms/structure'
export const fetchSingleStructure = (structureId) => `cms/structure/${structureId}`
export const createStructure = () => 'cms/structure'
export const deleteStructure = (structureId) => `cms/structure/${structureId}`
export const updateStructure = (structureId) => `cms/structure/${structureId}`

// content
export const fetchContentList = (structureId) => `cms/structure/${structureId}/content`
export const fetchSingleContent = (structureId, contentId) => `cms/structure/${structureId}/content/${contentId}`
export const createContent = (structureId) => `cms/structure/${structureId}/content`
export const deleteContent = (structureId, contentId) => `cms/structure/${structureId}/content/${contentId}`
export const updateContent = (structureId, contentId) => `cms/structure/${structureId}/content/${contentId}`

export const content = (structureId, contentId) => {
  if (contentId) {
    return `cms/structure/${structureId}/content/${contentId}`
  }
  return `cms/structure/${structureId}/content`
}

// upload
export const upload = (fileType) => {
  let path = ''
  if (fileType === IMAGE) {
    path = 'cms/upload/upload-image'
  } else if (fileType === VIDEO) {
    path = 'cms/upload/upload-video'
  } else if (fileType === DOCUMENT) {
    path = 'cms/upload/upload-document'
  } else if (fileType === FILE) {
    path = 'cms/upload/upload-file'
  }
  return path
}

// export
export const exportToCsv = 'cms/export-to-file/csv'

// user
export const user = (structureId, userId, fileType) => {
  let path = ''
  if (userId !== undefined) {
    path = `cms/structure/${structureId}/user/${userId}`
  } else {
    path = `cms/structure/${structureId}/user`
  }
  return path
}

// category
export const category = (structureId, categoryId) => {
  if (structureId) {
    if (categoryId) {
      return `cms / structure / ${structureId} /category/${categoryId} `
    }
    return `cms / structure / ${structureId} /category`
  }
  return 'cms/category'
}
