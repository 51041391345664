import React from 'react'
import TextField from '@material-ui/core/TextField'
// import { observable, action, toJS } from 'mobx'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'

const BootstrapInput = withStyles((theme) => ({
  root: {

  },
  input: {
    padding: '10px 12px',
    // height: '36px',
    // lineHeight: '36px',
    backgroundColor: '#f3f6f9',
    borderColor: '#f3f6f9',
    transition: 'background-color 0.5s ease',
    borderRadius: '4px'
    // '&.Mui-focused': {
    //   backgroundColor: '#000'
    // }
  },
  // notchedOutline: {
  //   borderWidth: '0px',
  //   borderColor: '#f3f6f9 !important'
  // }
}))(InputBase)

class CustomTextArea extends React.Component {

  constructor (props) {
    super(props)
    const defaultValue = this.props.field.value
    this.state = {
      value: defaultValue
    }
  }

  handleChange = (event) => {
    const name = this.props.field.name
    let value = event.target.value
    if (this.props.type === 'number') {
      value = parseInt(value)
    }
    // console.log(value)
    const setFieldValue = this.props.form.setFieldValue
    // const setFieldValue = this.props.setFieldValue
    // console.log(name)
    // setTimeout(() => {
    //   setValues('email', 'tetstestets')
    // }, 500)
    setFieldValue(name, value)
    // console.log(toJS(this.props.form.values))
    // console.log(value)
    // console.log(this.props.form.handleChange)
    // this.setState({ value: value })
    // console.log(this.props)
    // setFieldValue(name, 'tetstestets')
  }

  render () {
    const { handleChange } = this
    const { label, type, placeholder, disabled, language = 'en' } = this.props
    const { value } = this.state
    // console.log(type)
    return (
      <BootstrapInput
        type={type}
        variant='outlined'
        // margin='normal'
        defaultValue={value}
        required
        fullWidth
        label={label}
        // name='email'
        // autoComplete='email'
        placeholder={placeholder ? placeholder[language] : ''}
        onChange={handleChange}
        multiline
        rows={6}
        disabled={disabled}
      />
    )
  }
}

export default CustomTextArea
