import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'

export const createCategory = (structureId, data) => {
  const authHeaders = authUtil.getAuthHeaders()
  return apiClient.post(
    paths.category(structureId),
    {
      data: data,
    },
    authHeaders
  )
}
