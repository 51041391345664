const structureTypeOptions = (isGetAll) => {
  const list = [{
    value: 'ALL',
    label: 'All'
  }]
  const options = [
    {
      value: 'INITIAL',
      label: 'Initial'
    },
    {
      value: 'INFORMATION',
      label: 'Information'
    },
    {
      value: 'USER',
      label: 'User'
    },
    {
      value: 'CATEGORY',
      label: 'Category'
    },
    {
      value: 'RELATION',
      label: 'Relation'
    }
  ]
  if (isGetAll) {
    return [...list, ...options]
  }
  return options
}

export default structureTypeOptions
