import React from 'react'
import {
  Box,
  Typography,
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
// import { TextField, Switch } from 'formik-material-ui'
import CustomPicker from '@src/components/inputs/CustomPicker'
import CustomTagPicker from '@src/components/inputs/CustomTagPicker'
import CustomTextField from '@src/components/inputs/CustomTextField'
import CustomTextArea from '@src/components/inputs/CustomTextArea'
import CustomRichText from '@src/components/inputs/CustomRichText'
import CustomDatePicker from '@src/components/inputs/CustomDatePicker'
import CustomSwitch from '@src/components/inputs/CustomSwitch'
import CustomImagePicker from '@src/components/inputs/CustomImagePicker'
import CustomVideoPicker from '@src/components/inputs/CustomVideoPicker'
import CustomDocumentPicker from '@src/components/inputs/CustomDocumentPicker'
import CustomMultiLangInput from '@src/components/inputs/CustomMultiLangInput'
import CustomDateTimePicker from '@src/components/inputs/CustomDateTimePicker'
import CustomJSONField from '@src/components/inputs/CustomJSONField'
import CustomFilePicker from '@src/components/inputs/CustomFilePicker'
import { toJS, isEmpty } from '@src/utils/commonUtil'
import {
  IMAGE,
  VIDEO,
  DOCUMENT,
  FILE
} from '@src/constants/keys'
import contentValidation from '@src/validations/contentValidation'
import * as yup from 'yup'
class CustomForm extends React.Component {
  getInputComponent = (field, component, uploadFileType, lang = '') => {
    const { initialValues, allCategories, uploadFile, categoryOptionList, action, role } = this.props
    // toJS(role)
    // toJS(action)
    // toJS(allCategories)
    const identifierKey = `${field.identifier}${lang}`
    let optionList = []
    if (allCategories[field.categoryStructureIdentifier]) {
      optionList = allCategories[field.categoryStructureIdentifier]
    }
    let limit = 1
    if (field.type === 'IMAGE_ALBUM' || field.type === 'VIDEO_ALBUM') {
      limit = 99
    }
    let disabled = false
    if (action === 'UPDATE' && field.isEditable === false) {
      if (role === 'DEVELOPER') {
        disabled = true
      }
      if (role === 'ADMIN') {
        return false
      }
    }
    // let required = false
    // if (field.isRequired) {
    //   required = true
    // }
    // console.log(identifierKey, required)
    // toJS(field.categoryStructureIdentifier)
    // if (field.categoryStructureIdentifier === 'categoryOptionList') {
    //   optionList = categoryOptionList
    // }
    // toJS(optionList)
    const inputComponent = (
      <Field
        name={identifierKey}
        type={field.type}
        placeholder={field.placeholder}
        component={component}
        // variant='outlined'
        optionList={optionList}
        uploadFile={uploadFile}
        uploadFileType={uploadFileType}
        isMultilingual={field.isMultilingual}
        limit={limit}
        disabled={disabled}
        // required={required}
        initialValue={initialValues[identifierKey]}
      />
    )
    return inputComponent
  }

  render () {
    const { initialValues, submitForm, bindSubmitForm, structureFieldList, allCategories, uploadFile, language } = this.props
    const { getInputComponent } = this
    // toJS(structureFieldList)

    const convertedValues = {}
    let uploadFileType = ''
    const validation = {}
    structureFieldList.map((field, key) => {
      // console.log(field.type)
      const identifierKeyEn = `${field.identifier}en`
      const identifierKeyTc = `${field.identifier}tc`
      const identifierKeySc = `${field.identifier}sc`
      switch (field.type) {
        case 'STRING':
        case 'PASSWORD':
        case 'TEXT':
        case 'RICHTEXT':
        case 'PICKER':
          if (field.isMultilingual) {
            convertedValues[identifierKeyEn] = ''
            convertedValues[identifierKeyTc] = ''
            convertedValues[identifierKeySc] = ''
            if (initialValues[identifierKeyEn]) {
              convertedValues[identifierKeyEn] = initialValues[identifierKeyEn]
            }
            if (initialValues[identifierKeyTc]) {
              convertedValues[identifierKeyTc] = initialValues[identifierKeyTc]
            }
            if (initialValues[identifierKeySc]) {
              convertedValues[identifierKeySc] = initialValues[identifierKeySc]
            }
          } else {
            convertedValues[field.identifier] = ''
            if (initialValues[field.identifier]) {
              convertedValues[field.identifier] = initialValues[field.identifier]
            }
          }
          break
        case 'NUMBER':
          convertedValues[field.identifier] = 0
          if (initialValues[field.identifier]) {
            convertedValues[field.identifier] = initialValues[field.identifier]
          }
          break
        case 'JSON':
          convertedValues[field.identifier] = {}
          if (initialValues[field.identifier]) {
            convertedValues[field.identifier] = initialValues[field.identifier]
          }
          break
        case 'DATE':
        case 'TIME':
        case 'DATETIME':
          convertedValues[field.identifier] = ''
          if (initialValues[field.identifier]) {
            convertedValues[field.identifier] = initialValues[field.identifier]
          }
          break
        case 'BOOLEAN':
          convertedValues[field.identifier] = false
          if (initialValues[field.identifier]) {
            convertedValues[field.identifier] = initialValues[field.identifier]
          }
          break
        case 'IMAGE':
        case 'IMAGE_ALBUM':
        case 'VIDEO':
        case 'VIDEO_ALBUM':
        case 'DOCUMENT':
        case 'FILE':
        case 'TAG':
          convertedValues[field.identifier] = []
          if (initialValues[field.identifier]) {
            convertedValues[field.identifier] = initialValues[field.identifier]
          }
          break
        default:
          break
      }
      // if (field.isRequired) {
      //   switch (field.type) {
      //     case 'STRING':
      //     case 'PASSWORD':
      //     case 'TEXT':
      //     case 'RICHTEXT':
      //       validation[field.identifier] = yup.string().required()
      //       break
      //     case 'IMAGE':
      //     case 'IMAGE_ALBUM':
      //     case 'VIDEO':
      //     case 'VIDEO_ALBUM':
      //     case 'DOCUMENT':
      //     case 'FILE':
      //     case 'TAG':
      //       validation[field.identifier] = yup.object().shape({
      //         measures: yup.array().min(1).required(),
      //         dimensions: yup.array().min(1).required()
      //       })
      //       break
      //     default:
      //       break
      //   }
      // }
    })
    return (
      <Formik
        initialValues={convertedValues}
        validationSchema={yup.object().shape(validation)}
        onSubmit={submitForm}
        enableReinitialize
      >
        {(formikProps) => {
          bindSubmitForm(formikProps.submitForm)
          console.log(formikProps.errors)
          return (
            <Form>
              {
                structureFieldList.map((field, key) => {
                  if (!field.categoryStructureIdentifier && field.type === 'PICKER') {
                    return false
                  }
                  if (field.displayConditions && !isEmpty(field.displayConditions)) {
                    let isHitTheCondition = false
                    let displayConditions = field.displayConditions
                    if (typeof field.displayConditions === 'string') {
                      displayConditions = JSON.parse(field.displayConditions)
                    }
                    // toJS(displayConditions)
                    if (displayConditions) {
                      if (!displayConditions) {
                        isHitTheCondition = true
                      }
                      if (displayConditions.or) {
                        displayConditions.or.forEach(condition => {
                          // toJS(formikProps.values[condition.field])
                          // toJS(formikProps.values)
                          if (formikProps.values[condition.field] === condition.value) {
                            isHitTheCondition = true
                          }
                        })
                      }
                    }
                    if (!isHitTheCondition) {
                      return <div key={key} />
                    }
                  }
                  let component
                  switch (field.type) {
                    case 'PASSWORD':
                    case 'STRING':
                    case 'NUMBER':
                      component = CustomTextField
                      break
                    case 'TEXT':
                      component = CustomTextArea
                      break
                    case 'JSON':
                      component = CustomJSONField
                      break
                    case 'DATE':
                      component = CustomDatePicker
                      break
                    case 'DATETIME':
                      component = CustomDateTimePicker
                      break
                    case 'textarea':
                      component = CustomTextArea
                      break
                    case 'RICHTEXT':
                      component = CustomRichText
                      break
                    case 'BOOLEAN':
                      component = CustomSwitch
                      break
                    case 'IMAGE':
                    case 'IMAGE_ALBUM':
                      component = CustomImagePicker
                      uploadFileType = IMAGE
                      break
                    case 'VIDEO':
                    case 'VIDEO_ALBUM':
                      component = CustomVideoPicker
                      uploadFileType = VIDEO
                      break
                    case 'DOCUMENT':
                      component = CustomDocumentPicker
                      uploadFileType = DOCUMENT
                      break
                    case 'PICKER':
                      component = CustomPicker
                      break
                    case 'TAG':
                      component = CustomTagPicker
                      break
                    case 'FILE':
                      component = CustomFilePicker
                      uploadFileType = FILE
                      break
                    default:
                      break
                  }
                  // toJS(field)
                  const inputComponent = getInputComponent(field, component, uploadFileType)
                  if (!inputComponent) {
                    return (<div key={key}> </div>)
                  }
                  const inputComponentEn = getInputComponent(field, component, uploadFileType, 'en')
                  const inputComponentTc = getInputComponent(field, component, uploadFileType, 'tc')
                  const inputComponentSc = getInputComponent(field, component, uploadFileType, 'sc')
                  return (
                    <div key={key}>
                      <div className='form-group flex-row'>
                        <div className='form-label'>
                          {field.displayName[language]}
                        </div>
                        <div className='form-input'>
                          {
                            field.isMultilingual &&
                            (
                              <CustomMultiLangInput
                                inputComponentEn={inputComponentEn}
                                inputComponentTc={inputComponentTc}
                                inputComponentSc={inputComponentSc}
                              />
                            )
                          }
                          {
                            !field.isMultilingual &&
                            inputComponent
                          }
                        </div>
                      </div>
                      <div className='mt-20' />
                    </div>
                  )
                })
              }
              <Box mb={3} />
              {/* {error && error.message} */}
              {
                Object.keys(formikProps.errors).length > 0 && (
                  <div className='error-message'>{'Please fill all required fields.'}</div>
                )
              }
            </Form>
          )
        }}
      </Formik>
    )
  }
}

export default CustomForm
