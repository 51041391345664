import React from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CustomTabPanel from '@src/components/inputs/CustomTabPanel'

export default class CustomMultiLangInput extends React.Component {
  state = {
    tablIndex: 0
  }

  handleChange = (event, index) => {
    this.setState({ tablIndex: index })
  }

  render () {
    const { handleChange } = this
    const { tablIndex } = this.state
    const { inputComponentEn, inputComponentTc, inputComponentSc } = this.props


    return (
      <>
        <Tabs
          value={tablIndex}
          indicatorColor='primary'
          textColor='primary'
          onChange={handleChange}
          aria-label='disabled tabs example'
        >
          <Tab label='EN' />
          <Tab label='TC' />
          <Tab label='SC' />
        </Tabs>
        <CustomTabPanel value={tablIndex} index={0}>
          {
            inputComponentEn
          }
        </CustomTabPanel>
        <CustomTabPanel value={tablIndex} index={1}>
          {
            inputComponentTc
          }
        </CustomTabPanel>
        <CustomTabPanel value={tablIndex} index={2}>
          {
            inputComponentSc
          }
        </CustomTabPanel>
      </>
    )
  }
}
