import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import enTranslation from '@src/locales/en/translation.json'
import tcTranslation from '@src/locales/tc/translation.json'
import scTranslation from '@src/locales/sc/translation.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: enTranslation
      },
      tc: {
        translations: tcTranslation
      },
      sc: {
        translations: scTranslation
      }
    },
    fallbackLng: 'en',
    // lng: 'tc',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
