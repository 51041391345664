import React from 'react'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

class CustomSwitch extends React.Component {

  constructor (props) {
    super(props)
    const defaultValue = this.props.field.value
    this.state = {
      value: defaultValue
    }
  }

  handleChange = () => {
    const name = this.props.field.name
    const setFieldValue = this.props.form.setFieldValue
    setFieldValue(name, !this.state.value)
    this.setState({ value: !this.state.value })
  }

  render () {
    const { handleChange } = this
    const { value } = this.state
    return (
      <FormControlLabel
        control={<Switch color='primary' checked={value} onChange={handleChange} />}
        label=''
        style={{ marginLeft: 0 }}
      />
    )
  }
}

export default CustomSwitch
