import { observable, action, makeObservable, runInAction } from 'mobx'
// import * as api from '../services/api'
// import { ACCESS_TOKEN } from '../constants/tokenKeys'
import { fetchAdminList, createAdmin, updateAdmin, deleteAdmin } from '@src/api/admin'
import { toJS } from '@src/utils/commonUtil'

export default class AdminStore {
  @observable list = []
  @observable total = 0
  @observable item = null
  @observable error = null
  @observable form = {
    username: '',
    password: '',
    role: ''
  }

  constructor (rootStore) {
    makeObservable(this)
    runInAction(() => {
      this.rootStore = rootStore
    })
  }

  @action
  clearForm = async () => {
    runInAction(() => {
      this.form = {
        username: '',
        password: '',
        role: ''
      }
    })
  }

  @action
  setForm = async (adminId) => {
    runInAction(() => {
      this.form = this.list.find(item => item.id === adminId)
    })
  }

  @action
  fetchAdminList = async (searchText, filter, offset, limit, orderBy, order) => {
    try {
      const data = await fetchAdminList(searchText, filter, offset, limit, orderBy, order)
      runInAction(() => {
        this.list = data.list
        this.total = data.count
      })
    } catch (error) {
      console.log(error)
      console.log('Network Error')
    }
  }

  @action
  createAdmin = async (values) => {
    const { username, password, role } = values
    await createAdmin(username, password, role)
    return true
  }

  @action
  updateAdmin = async (adminId, values) => {
    const { username, password, role } = values
    await updateAdmin(adminId, username, password, role)
    return true
  }

  @action
  deleteAdmin = async (adminId) => {
    await deleteAdmin(adminId)
    return true
  }

  @action
  clearAdmin = async () => {
    this.list = []
  }
}
