import React from 'react'
import { inject, observer } from 'mobx-react'
import MiniDrawerLayout from '@src/components/layouts/MiniDrawerLayout'
import ContentDialog from '@src/components/dialogs/ContentDialog'
import CustomTable from '@src/components/tables/CustomTable'
import queryString from 'query-string'
import DeleteDialog from '@src/components/dialogs/DeleteDialog'
import {
  CREATE,
  UPDATE,
} from '@src/constants/keys'
import { toJS } from '@src/utils/commonUtil'

@inject('authStore', 'structureStore', 'contentStore', 'categoryStore')
@observer

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      action: CREATE,
      isContentDialogOpened: false,
      isDeleteDialogOpened: false,
      currentStructureId: 0,
      currentContentIndex: 0
    }
  }

  componentDidMount () {
    const parsed = queryString.parse(window.location.search)
    const structureId = parseInt(parsed.id)
    this.setState({
      currentStructureId: structureId,
    })
    const { structureStore, categoryStore } = this.props
    structureStore.fetchStructureDetail(structureId)
    this.fetchContentList(structureId)
    categoryStore.fetchAllCategories()
  }

  fetchAllStructures = () => {
    const { structureStore } = this.props
    structureStore.fetchAllStructures()
  }

  fetchContentList = (structureId) => {
    const { contentStore } = this.props
    contentStore.fetchContentList(structureId)
  }

  toogleContentDialog = () => {
    this.setState({ isContentDialogOpened: !this.state.isContentDialogOpened })
  }

  createContent = async (values, { setSubmitting, setErrors }) => {
    const { structureStore, contentStore } = this.props
    const { currentContentIndex } = this.state
    const structureList = structureStore.list
    const structureId = structureList[currentContentIndex].id
    await contentStore.createContent(structureId, values)
    this.toogleContentDialog()
  }

  toogleDeleteDialog = () => {
    this.setState({ isDeleteDialogOpened: !this.state.isDeleteDialogOpened })
  }

  createAction = () => {
    this.setState({
      action: CREATE,
      isContentDialogOpened: !this.state.isContentDialogOpened
    })
  }

  updateAction = async (event, rowData) => {
    const { contentStore } = this.props
    await contentStore.setForm(rowData.id)
    this.setState({
      action: UPDATE,
      currentContentId: rowData.id,
      isContentDialogOpened: !this.state.isContentDialogOpened
    })
  }

  handleSubmitForm = async (values, { setSubmitting, setErrors }) => {
    const { contentStore } = this.props
    const { action, currentContentId, currentStructureId } = this.state
    switch (action) {
      case CREATE:
        await contentStore.createContent(currentStructureId, values)
        break
      case UPDATE:
        await contentStore.updateContent(currentStructureId, currentContentId, values)
        break
      default:
        break
    }
    this.fetchContentList(currentStructureId)
    this.toogleContentDialog()
  }

  deleteAction = (event, rowData) => {
    // toJS(rowData)
    this.setState({
      currentContentId: rowData.id,
      isDeleteDialogOpened: !this.state.isContentDialogOpened
    })
  }

  handleDeleteContent = async () => {
    const { contentStore } = this.props
    await contentStore.deleteContent(this.state.currentStructureId, this.state.currentContentId)
    const { currentStructureId } = this.state
    this.fetchContentList(currentStructureId)
    this.toogleDeleteDialog()
  }

  getContentTableName = () => {
    const { authStore, structureStore, contentStore } = this.props
    let contentTableName = ''
    if (structureStore.item) {
      contentTableName = structureStore.item.displayName
    }
    return contentTableName
  }

  getColumns = () => {
    const { structureStore } = this.props
    // toJS(fieldList)
    const columnList = structureStore.structureFieldList.map((field, key) => {
      return {
        title: field.displayName,
        field: field.identifier,
        name: field.displayName,
        selector: field.identifier,
        sortable: true
      }
    })
    return columnList
  }

  render () {
    const { getColumns, getContentTableName, action, createAction, updateAction, deleteAction, handleSubmitForm, toogleContentDialog, toogleDeleteDialog, handleDeleteContent } = this
    const { authStore, structureStore, contentStore, categoryStore } = this.props
    const switchContent = this.switchContent
    const { isDeleteDialogOpened, isContentDialogOpened } = this.state
    const contentList = contentStore.list
    // toJS(categoryStore.all)
    const contentTableName = getContentTableName()
    const columns = getColumns()
    // toJS(contentStore.form)
    return (
      <div>
        <CustomTable
          title={contentTableName}
          columns={columns}
          data={contentList}
          createAction={createAction}
          updateAction={updateAction}
          deleteAction={deleteAction}
          structureFieldList={structureStore.structureFieldList}
        />
        <ContentDialog
          action={action}
          isDialogOpened={isContentDialogOpened}
          toogleDialog={toogleContentDialog}
          submitForm={handleSubmitForm}
          initialValues={contentStore.form}
          structureFieldList={structureStore.structureFieldList}
          allCategories={categoryStore.all}
        // uploadFile={handleUploadFile}
        />
        <DeleteDialog
          isDialogOpened={isDeleteDialogOpened}
          toogleDialog={toogleDeleteDialog}
          submitForm={handleDeleteContent}
        />
      </div>
    )
  }
}

export default Dashboard
