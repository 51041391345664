import React from 'react'
import { inject, observer } from 'mobx-react'
// import MiniDrawerLayout from '@src/components/layouts/MiniDrawerLayout'
import FormDialog from '@src/components/dialogs/FormDialog'
import CustomTable from '@src/components/tables/CustomTable'
import queryString from 'query-string'
import {
  CREATE,
  UPDATE,
} from '@src/constants/keys'
import DeleteDialog from '@src/components/dialogs/DeleteDialog'
// import fieldColumns from '@src/config/tableColumns/fieldColumns'
import CustomForm from '@src/components/forms/CustomForm'
import FormPaper from '@src/components/papers/FormPaper'
import CustomBreadcrumbs from '@src/components/layouts/CustomBreadcrumbs'
// import UiStore from '../../stores/UiStore'
import { toJS } from '@src/utils/commonUtil'
import CustomTransferList from '@src/components/inputs/CustomTransferList'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  List,
  ListItem,
  Select,
  Switch,
  ListItemIcon,
  ListItemText,
  InboxIcon,
} from '@material-ui/core'

@inject('authStore', 'structureStore', 'structureFieldStore', 'contentStore', 'adminStore', 'categoryStore', 'uiStore')
@observer

class Field extends React.Component {
  constructor (props) {
    super(props)
    const { uiStore } = this.props
    const parsed = queryString.parse(this.props.history.location.search)
    const structureId = parseInt(parsed.id)
    const item = this.props.uiStore.allContents.menuList.find((menu) => menu.id === parseInt(parsed.id))
    const fieldTitle = {
      en: 'Field',
      tc: 'Field',
      sc: 'Field'
    }
    const title = fieldTitle.en
    this.state = {
      title: title,
      action: CREATE,
      currentStructureId: structureId,
      currentStructureFieldIndex: 0,
      isStructureFieldDialogOpened: false,
      isDeleteDialogOpened: false,
      isFormVisible: false,
      searchText: '',
      filter: {
        type: 'ALL'
      },
      offset: 0,
      limit: 10,
      orderBy: uiStore.fieldStructure.orderBy,
      order: uiStore.fieldStructure.orderBy.order.toLowerCase(),
      isTransferListDialogOpened: false
    }
  }

  componentDidMount () {
    this.fetchStructureDetail()
  }

  componentDidUpdate () {
    const parsed = queryString.parse(this.props.history.location.search)
    const structureId = parseInt(parsed.id)
    if (this.state.currentStructureId !== structureId) {
      this.setState({
        currentStructureId: structureId
      }, () => {
        const { structureStore } = this.props
        structureStore.fetchStructureDetail(structureId)
      })
    }
  }

  fetchStructureDetail = async () => {
    const { structureStore, categoryStore, uiStore } = this.props
    await structureStore.fetchStructureDetail(this.state.currentStructureId)
    // if (structureStore.item) {
    //   this.setState({ title: `${structureStore.item.displayName[uiStore.language]} Field` })
    // }
    // toJS(structureStore.item.displayName)
  }

  toogleStructureFieldForm = () => {
    this.setState({ isFormVisible: !this.state.isFormVisible })
  }

  toogleStructureFieldDialog = () => {
    this.setState({
      isStructureFieldDialogOpened: !this.state.isStructureFieldDialogOpened
    })
  }

  toogleDeleteDialog = () => {
    this.setState({ isDeleteDialogOpened: !this.state.isDeleteDialogOpened })
  }

  createAction = () => {
    this.setState({
      action: CREATE,
      isStructureFieldDialogOpened: !this.state.isStructureFieldDialogOpened
      // isFormVisible: !this.state.isFormVisible
    })
  }

  updateAction = async (index) => {
    const { structureStore } = this.props
    await structureStore.setStructureFieldForm(index)
    this.setState({
      action: UPDATE,
      currentStructureFieldIndex: index,
      isStructureFieldDialogOpened: !this.state.isStructureFieldDialogOpened
    })
  }

  handleSubmitForm = async (values, { setSubmitting, setErrors }) => {
    const { structureStore } = this.props
    const { action, currentStructureId, currentStructureFieldIndex } = this.state
    switch (action) {
      case CREATE:
        await structureStore.createStructureField(currentStructureId, values)
        break
      case UPDATE:
        console.log(values)
        await structureStore.updateStructureField(currentStructureId, currentStructureFieldIndex, values)
        break
      default:
        break
    }
    this.toogleStructureFieldDialog()
  }

  deleteAction = (structureFieldIndex) => {
    this.setState({
      currentStructureFieldIndex: structureFieldIndex,
      isDeleteDialogOpened: !this.state.isStructureFieldDialogOpened
    })
  }

  handleDeleteStructureField = async () => {
    const { structureStore } = this.props
    await structureStore.deleteStructureField(this.state.currentStructureId, this.state.currentStructureFieldIndex)
    this.toogleDeleteDialog()
  }

  getStructureOptionList = () => {
    const { structureStore } = this.props
    let optionList = []
    if (structureStore.list) {
      structureStore.list.map((structure) => {
        if (structure.type === 'CATEGORY') {
          optionList.push({
            value: structure.id,
            label: structure.identifier
          })
        }
      })
    }
    return optionList
  }

  submitStructureFieldForm = null

  bindSubmitForm = (submitForm) => {
    this.submitStructureFieldForm = submitForm
  }

  handleDialogSubmitForm = (event) => {
    if (this.submitStructureFieldForm) {
      this.submitStructureFieldForm(event)
    }
  }

  handlePaperSubmitForm = (event) => {
    if (this.submitStructureFieldForm) {
      this.submitStructureFieldForm(event)
    }
  }

  sortAction = (key) => {
    const langCode = this.props.uiStore.language
    const { orderBy, order } = this.state
    const isAsc = orderBy === key && order === 'asc'
    this.setState({
      order: (isAsc ? 'desc' : 'asc'),
      orderBy: key
    }, () => {
      this.fetchStructureFieldList()
    })
  }

  searchAction = (event) => {
    this.setState({ searchText: event.target.value }, () => {
      this.fetchStructureFieldList()
    })
  }

  switchLimitAction = (event) => {
    this.setState({ limit: event.target.value }, () => {
      this.fetchStructureFieldList()
    })
  }

  offsetAction = (event, offset) => {
    this.setState({ offset: offset }, () => {
      this.fetchStructureFieldList()
    })
  }

  filterAction = (identifier, event) => {
    const filter = this.state.filter
    filter[identifier] = event.target.value
    this.setState({ filter: filter }, () => {
      this.fetchStructureFieldList()
    })
  }

  fetchStructureFieldList = () => {
    const { structureStore } = this.props
    const { searchText, filter, offset, limit, orderBy, order } = this.state
    structureStore.fetchStructureFieldList(searchText, filter, offset, limit, orderBy, order)
  }

  toogleTransferListDialog = () => {
    this.setState({
      isTransferListDialogOpened: !this.state.isTransferListDialogOpened
    })
  }

  handleSubmitFieldList = async (list) => {
    const { structureStore } = this.props
    await structureStore.updateStructureFieldList(this.state.currentStructureId, list)
    this.toogleTransferListDialog()
  }

  render () {
    const { toogleTransferListDialog, handleDialogSubmitForm, filterAction, offsetAction, switchLimitAction, sortAction, searchAction, handlePaperSubmitForm, bindSubmitForm, toogleStructureFieldForm, createAction, updateAction, deleteAction, handleSubmitForm, handleDeleteStructureField, toogleDeleteDialog } = this
    const { handleSubmitFieldList, toogleStructureFieldDialog, getStructureOptionList } = this
    const { structureStore, uiStore, authStore } = this.props
    const {
      isStructureFieldDialogOpened, isDeleteDialogOpened, isFormVisible, action,
      searchText,
      offset,
      limit,
      orderBy,
      order,
      title,
      filter,
      isTransferListDialogOpened
    } = this.state
    const structureOptionList = getStructureOptionList()
    // toJS(structureStore.structureFieldList)
    // toJS(authStore.jwt.role)
    return (
      <div>
        <CustomBreadcrumbs />
        <Dialog
          maxWidth='md'
          open={isTransferListDialogOpened}
          onClose={toogleTransferListDialog}
        >
          <DialogContent>
            {
              structureStore.structureFieldList &&
              (<CustomTransferList list={structureStore.structureFieldList} onSubmit={handleSubmitFieldList} />)
            }
          </DialogContent>
        </Dialog>
        {
          !isFormVisible &&
          (
            <CustomTable
              title={title}
              primaryAction={toogleTransferListDialog}
              primaryText='Sequence'
              columns={uiStore.fieldStructure.fieldList}
              data={structureStore.structureFieldList}
              createAction={createAction}
              updateAction={updateAction}
              deleteAction={deleteAction}
              total={structureStore.structureFieldList ? structureStore.structureFieldList.length : 0}
              searchText={searchText}
              offset={offset}
              limit={limit}
              orderBy={orderBy}
              order={order}
              filter={filter}
              sortAction={sortAction}
              searchAction={searchAction}
              switchLimitAction={switchLimitAction}
              offsetAction={offsetAction}
              filterAction={filterAction}
              allCategories={uiStore.allCategories}
              language={uiStore.language}
              categoryOptionList={uiStore.categoryOptionList}
              isInsertable
            // isEditable={false}
            />
          )
        }
        {
          // isFormVisible &&
          // (
          //   <FormPaper
          //     title={title}
          //     action={action}
          //     toogleForm={toogleStructureFieldForm}
          //     submitForm={handlePaperSubmitForm}
          //     form={
          //       <CustomForm
          //         action={action}
          //         initialValues={structureStore.structureFieldForm}
          //         submitForm={handleSubmitForm}
          //         bindSubmitForm={bindSubmitForm}
          //         structureFieldList={uiStore.fieldStructure.fieldList}
          //         allCategories={uiStore.allCategories}
          //         language={uiStore.language}
          //       // error={baseModelStore.error}
          //       />
          //     }
          //   />
          // )
        }
        <FormDialog
          title={title}
          action={action}
          isDialogOpened={isStructureFieldDialogOpened}
          toogleDialog={toogleStructureFieldDialog}
          submitForm={handleDialogSubmitForm}
          dialogMaxWidth={uiStore.dialogMaxWidth}
          structureOptionList={structureOptionList}
          form={
            <CustomForm
              initialValues={structureStore.structureFieldForm}
              submitForm={handleSubmitForm}
              bindSubmitForm={bindSubmitForm}
              structureFieldList={uiStore.fieldStructure.fieldList}
              allCategories={uiStore.allCategories}
              language={uiStore.language}
              role={authStore.jwt.role}
            // error={baseModelStore.error}
            />
          }
        />
        <DeleteDialog
          isDialogOpened={isDeleteDialogOpened}
          toogleDialog={toogleDeleteDialog}
          submitForm={handleDeleteStructureField}
        />
      </div>
    )
  }
}

export default Field
