import React from 'react'
import { inject, observer } from 'mobx-react'
import FormPaper from '@src/components/papers/FormPaper'
import FormDialog from '@src/components/dialogs/FormDialog'
import DeleteDialog from '@src/components/dialogs/DeleteDialog'
import CustomTable from '@src/components/tables/CustomTable'
import {
  CREATE,
  UPDATE
} from '@src/constants/keys'
import structureColumns from '@src/config/tableColumns/structureColumns'
import CustomForm from '@src/components/forms/CustomForm'
import CustomBreadcrumbs from '@src/components/layouts/CustomBreadcrumbs'
import { toJS } from '@src/utils/commonUtil'

@inject('authStore', 'structureStore', 'contentStore', 'adminStore', 'uiStore')
@observer

class Structure extends React.Component {
  constructor (props) {
    super(props)
    const { uiStore } = this.props
    const item = this.props.uiStore.allContents.systemStructureList.find((structure) => structure.identifier === 'structure')
    const title = item.displayName.en
    if (uiStore.adminStructure) {
      this.state = {
        title: title,
        isReady: false,
        action: CREATE,
        isTableLoaded: true,
        isStructureDialogOpened: false,
        isDeleteDialogOpened: false,
        isFormVisible: false,
        isAdminDialogOpened: false,
        currentAdminId: 0,
        searchText: '',
        filter: {
          type: 'ALL'
        },
        offset: 0,
        limit: 10,
        orderBy: uiStore.structureStructure.orderBy,
        order: uiStore.structureStructure.orderBy.order.toLowerCase()
      }
    }
  }

  componentDidMount () {
    this.fetchStructureList()
  }

  fetchStructureList = async () => {
    const { structureStore } = this.props
    const { searchText, filter, offset, limit, orderBy, order } = this.state
    await structureStore.fetchStructureList(searchText, filter, offset, limit, orderBy, order)
    this.setState({ isReady: true })
  }

  toogleStructureForm = () => {
    this.setState({ isFormVisible: !this.state.isFormVisible })
  }

  toogleStructureDialog = () => {
    this.setState({ isStructureDialogOpened: !this.state.isStructureDialogOpened })
  }

  toogleDeleteDialog = () => {
    this.setState({ isDeleteDialogOpened: !this.state.isDeleteDialogOpened })
  }

  createAction = async () => {
    const { structureStore } = this.props
    await structureStore.clearForm()
    this.setState({
      action: CREATE,
      isStructureDialogOpened: !this.state.isStructureDialogOpened
      // isFormVisible: !this.state.isFormVisible
    })
  }

  updateAction = async (index, structureId) => {
    // console.log(rowData)
    const { structureStore } = this.props
    await structureStore.setForm(structureId)
    this.setState({
      action: UPDATE,
      currentStructureId: structureId,
      isStructureDialogOpened: !this.state.isStructureDialogOpened
    })
  }

  handleSubmitForm = async (values, { setSubmitting, setErrors }) => {
    const { structureStore } = this.props
    const { action, currentStructureId } = this.state
    switch (action) {
      case CREATE:
        await structureStore.createStructure(values)
        break
      case UPDATE:
        await structureStore.updateStructure(currentStructureId, values)
        break
      default:
        break
    }
    this.fetchStructureList()
    this.toogleStructureDialog()
    // this.toogleStructureForm()
  }

  deleteAction = (key, structureId) => {
    this.setState({
      currentStructureId: structureId,
      isDeleteDialogOpened: !this.state.isStructureDialogOpened
    })
  }

  handleDeleteStructure = async () => {
    const { structureStore } = this.props
    await structureStore.deleteStructure(this.state.currentStructureId)
    this.fetchStructureList()
    this.toogleDeleteDialog()
  }

  submitStructureForm = null

  bindSubmitForm = (submitForm) => {
    this.submitStructureForm = submitForm
  }

  handleDialogSubmitForm = (event) => {
    if (this.submitStructureForm) {
      this.submitStructureForm(event)
    }
  }

  handlePaperSubmitForm = (event) => {
    if (this.submitStructureForm) {
      this.submitStructureForm(event)
    }
  }

  sortAction = (identifier) => {
    const langCode = this.props.uiStore.language
    const { orderBy, order } = this.state
    const isAsc = orderBy.identifier === identifier && orderBy.langCode === langCode && order === 'asc'
    this.setState({
      order: (isAsc ? 'desc' : 'asc'),
      orderBy: { identifier, langCode, order }
    }, () => {
      // toJS(this.state.orderBy)
      this.fetchStructureList()
    })
  }

  searchAction = (event) => {
    this.setState({ searchText: event.target.value }, () => {
      this.fetchStructureList()
    })
  }

  switchLimitAction = (event) => {
    // console.log(event.target.value)
    this.setState({
      limit: event.target.value
    }, () => {
      this.fetchStructureList()
    })
  }

  offsetAction = (event, offset) => {
    this.setState({ offset: offset }, () => {
      this.fetchStructureList()
    })
  }

  filterAction = (identifier, event) => {
    const filter = this.state.filter
    filter[identifier] = event.target.value
    this.setState({ filter: filter }, () => {
      this.fetchStructureList()
    })
  }

  render () {
    const { handleDialogSubmitForm, filterAction, offsetAction, switchLimitAction, sortAction, searchAction, bindSubmitForm, handlePaperSubmitForm, toogleStructureForm, createAction, updateAction, deleteAction, handleSubmitForm, toogleDeleteDialog, handleDeleteStructure, toogleStructureDialog } = this
    const { structureStore, uiStore } = this.props
    const {
      title,
      action,
      isFormVisible,
      isStructureDialogOpened,
      isDeleteDialogOpened,
      searchText,
      offset,
      limit,
      orderBy,
      order,
      filter
    } = this.state
    // toJS(uiStore.structureFieldList)
    if (!this.state.isReady) {
      return (<></>)
    }
    return (
      <div>
        <CustomBreadcrumbs />
        {
          !isFormVisible && structureStore.list &&
          (
            <CustomTable
              title={title}
              columns={uiStore.structureStructure.fieldList}
              data={structureStore.list}
              createAction={createAction}
              updateAction={updateAction}
              deleteAction={deleteAction}
              total={structureStore.total}
              searchText={searchText}
              offset={offset}
              limit={limit}
              orderBy={orderBy}
              order={order}
              filter={filter}
              sortAction={sortAction}
              searchAction={searchAction}
              switchLimitAction={switchLimitAction}
              offsetAction={offsetAction}
              filterAction={filterAction}
              allCategories={uiStore.allCategories}
              language={uiStore.language}
              isInsertable
            />
          )
        }
        <FormDialog
          title={title}
          action={action}
          isDialogOpened={isStructureDialogOpened}
          toogleDialog={toogleStructureDialog}
          submitForm={handleDialogSubmitForm}
          dialogMaxWidth={uiStore.dialogMaxWidth}
          form={
            <CustomForm
              initialValues={structureStore.form}
              submitForm={handleSubmitForm}
              bindSubmitForm={bindSubmitForm}
              structureFieldList={uiStore.structureStructure.fieldList}
              language={uiStore.language}
              allCategories={uiStore.allCategories}
              categoryOptionList={uiStore.categoryOptionList}
            // error={baseModelStore.error}
            />
          }
        />
        {
          isFormVisible &&
          (
            <FormPaper
              title={title}
              action={CREATE}
              toogleForm={toogleStructureForm}
              submitForm={handlePaperSubmitForm}
              form={
                <CustomForm
                  initialValues={structureStore.form}
                  submitForm={handleSubmitForm}
                  bindSubmitForm={bindSubmitForm}
                  structureFieldList={uiStore.structureFieldList}
                  language={uiStore.language}
                  allCategories={uiStore.allCategories}
                  categoryOptionList={uiStore.categoryOptionList}
                // error={baseModelStore.error}
                />
              }
            />
          )
        }
        <DeleteDialog
          isDialogOpened={isDeleteDialogOpened}
          toogleDialog={toogleDeleteDialog}
          submitForm={handleDeleteStructure}
        />
      </div>
    )
  }
}

export default Structure
