import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'

export const deleteContent = (structureId, contentId) => {
  const authHeaders = authUtil.getAuthHeaders()
  return apiClient.delete(
    paths.content(structureId, contentId),
    authHeaders
  )
}
