import React from 'react'
import Divider from '@material-ui/core/Divider'
import {
  IconButton,
  withStyles,
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import StarBorder from '@material-ui/icons/StarBorder'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone'
import SupervisorAccountTwoToneIcon from '@material-ui/icons/SupervisorAccountTwoTone'
import DeveloperBoardTwoToneIcon from '@material-ui/icons/DeveloperBoardTwoTone'
import ChevronLeftTwoToneIcon from '@material-ui/icons/ChevronLeftTwoTone'
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone'
import KeyboardArrowRightTwoToneIcon from '@material-ui/icons/KeyboardArrowRightTwoTone'
// import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import KeyboardArrowDownTwoToneIcon from '@material-ui/icons/KeyboardArrowDownTwoTone'
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone'
import classnames from 'classnames'
import structureTypeOptions from '@src/config/structureTypeOptions'
import { toJS, truncateString, trimHtmlTag } from '@src/utils/commonUtil'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
})

@withRouter
@withStyles(styles)
@inject('authStore', 'structureStore', 'contentStore', 'adminStore', 'uiStore')
@observer

class DeveloperMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isDrawerOpened: true,
      isStructureDialogOpened: false,
      isMenuItemVisableList: [],
      anchorEl: null,
      openAccountDialog: false,
      activeItem: ''
    }
  }

  componentDidMount () {
    this.initMenuItemActionList()
  }

  initMenuItemActionList = async () => {
    const { uiStore } = this.props
    // toJS(uiStore)
    // await uiStore.init()
    if (uiStore.developerMenuList) {
      const list = uiStore.developerMenuList.map(() => {
        return false
      })
      // console.log(list)
      this.setState({ isMenuItemVisableList: list })
    }
  }

  toogleMenuItem = (index) => {
    const list = this.state.isMenuItemVisableList
    list[index] = !this.state.isMenuItemVisableList[index]
    this.setState({ isMenuItemVisableList: list })
  }

  route = (path) => {
    // this.setState({ activeItem: path })
    this.props.history.push(path)
    if (window.screen.width < 600) {
      this.props.toggleDrawer()
    }
  }

  routeStructure = (path, event) => {
    event.stopPropagation()
    this.route(path)
  }

  clickStructure = (structureType, structureId) => {
    // switch (structureType) {
    //   case 'INFORMATION':
    //     this.route(`/content?id=${structureId}`)
    //     break
    //   case 'USER':
    //     this.route(`/user?id=${structureId}`)
    //     break
    //   default:
    //     break
    // }
  }

  render () {
    const { classes, structureStore, uiStore } = this.props
    const { navigateField, navigateContent, navigateUser, route, routeStructure, toogleMenuItem, clickStructure } = this
    const { isDrawerOpened, isStructureDialogOpened, isMenuItemVisableList, anchorEl, activeItem } = this.state
    // console.log(isMenuItemVisableList)
    const language = uiStore.language

    return (
      <ul className='menu-nav'>
        {
          <li className={classnames('menu-link flex row align-items-center', { active: activeItem === '/dashboard' })} onClick={() => route('/dashboard')}>
            <span className='menu-icon flex row align-items-center'>
              <HomeTwoToneIcon />
            </span>
            <span className='menu-text flex row align-items-center'>Dashboard</span>
          </li>
        }
        {
          <li className={classnames('menu-link flex row align-items-center', { active: activeItem === '/admin' })} onClick={() => route('/administrator')}>
            <span className='menu-icon flex row align-items-center'>
              <SupervisorAccountTwoToneIcon />
            </span>
            <span className='menu-text flex row align-items-center'>Admin</span>
          </li>
        }
        {
          <li className={classnames('menu-link flex row align-items-center', { active: activeItem === '/structure' })} onClick={() => route('/structure')}>
            <span className='menu-icon flex row align-items-center'>
              <DeveloperBoardTwoToneIcon />
            </span>
            <span className='menu-text flex row align-items-center'>Structure</span>
          </li>
        }
        {/* <Divider /> */}
        {
          structureTypeOptions().map((type, key) => (
            <div key={key}>
              {
                uiStore.developerMenu[type.value].length > 0 &&
                (
                  <div key={type.value}>
                    <li className='menu-section'>
                      <span className='menu-text'>{type.label}</span>
                      {/* <i className='menu-icon flaticon-more-v2'></i> */}
                    </li>
                    {
                      uiStore.developerMenu &&
                      (
                        uiStore.developerMenu[type.value].map(structure => {
                          const key = structure.id
                          return (
                            <div key={key}>
                              <li
                                className='menu-link flex row space-between align-items-center'
                                onClick={toogleMenuItem.bind(this, key)}
                              // onClick={clickStructure.bind(this, structure.type, structure.id)}
                              >
                                <div className='flex row'>
                                  <span className='menu-icon flex row align-items-center'>
                                    <DeveloperBoardTwoToneIcon />
                                  </span>
                                  <span className='menu-text flex row align-items-center'>
                                    {/* {structure.displayName[language]} */}
                                    {truncateString(structure.displayName[language], 18, false)}
                                  </span>
                                </div>
                                {
                                  isMenuItemVisableList[key]
                                    ? <KeyboardArrowDownTwoToneIcon className='mr-10' />
                                    : <KeyboardArrowRightTwoToneIcon className='mr-10' />
                                }
                              </li>
                              <Collapse in={isMenuItemVisableList[key]} timeout='auto' unmountOnExit>
                                <ul className='submenu-nav'>
                                  <li onClick={routeStructure.bind(this, `/field?id=${structure.id}`)}>
                                    <div className='submenu-link flex row align-items-center'>
                                      <span className='submenu-bullet' />
                                      <span className='submenu-text'>Fields</span>
                                    </div>
                                  </li>
                                  {
                                    structure.type === 'INITIAL' &&
                                    (
                                      <li onClick={routeStructure.bind(this, `/content?id=${structure.id}`)}>
                                        <div className='submenu-link flex row align-items-center'>
                                          <span className='submenu-bullet' />
                                          <span className='submenu-text'>Initial</span>
                                        </div>
                                      </li>
                                    )
                                  }
                                  {
                                    structure.type === 'INFORMATION' &&
                                    (
                                      <li onClick={routeStructure.bind(this, `/content?id=${structure.id}`)}>
                                        <div className='submenu-link flex row align-items-center'>
                                          <span className='submenu-bullet' />
                                          <span className='submenu-text'>Contents</span>
                                        </div>
                                      </li>
                                    )
                                  }
                                  {
                                    structure.type === 'USER' &&
                                    (
                                      <li onClick={routeStructure.bind(this, `/content?id=${structure.id}`)}>
                                        <div className='submenu-link flex row align-items-center'>
                                          <span className='submenu-bullet' />
                                          <span className='submenu-text'>Users</span>
                                        </div>
                                      </li>
                                    )
                                  }
                                  {
                                    structure.type === 'RELATION' &&
                                    (
                                      <li onClick={routeStructure.bind(this, `/content?id=${structure.id}`)}>
                                        <div className='submenu-link flex row align-items-center'>
                                          <span className='submenu-bullet' />
                                          <span className='submenu-text'>Relations</span>
                                        </div>
                                      </li>
                                    )
                                  }
                                  {
                                    structure.type === 'CATEGORY' &&
                                    (
                                      <li onClick={routeStructure.bind(this, `/content?id=${structure.id}`)}>
                                        <div className='submenu-link flex row align-items-center'>
                                          <span className='submenu-bullet' />
                                          <span className='submenu-text'>{'Categories'}</span>
                                        </div>
                                      </li>
                                    )
                                  }
                                </ul>
                              </Collapse>
                            </div>
                          )
                        })
                      )
                    }
                  </div>
                )
              }
            </div>
          ))
        }
        <Divider />
        {
          // <ListItem button onClick={() => this.props.switchContent('setting', 0)}>
          //   <ListItemIcon><InboxIcon /></ListItemIcon>
          //   <ListItemText primary='Setting' />
          // </ListItem>
        }
      </ul>
    )
  }
}

export default DeveloperMenu
