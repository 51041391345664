import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'

export const refresh = (refreshToken) => {
  return apiClient.post(
    paths.refresh,
    {
      refreshToken
    }
  )
}
