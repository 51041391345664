import React from 'react'
import { inject, observer } from 'mobx-react'
// import MiniDrawerLayout from '@src/components/layouts/MiniDrawerLayout'
import FormDialog from '@src/components/dialogs/FormDialog'
import CustomTable from '@src/components/tables/CustomTable'
import queryString from 'query-string'
import DeleteDialog from '@src/components/dialogs/DeleteDialog'
import {
  CREATE,
  UPDATE,
  IMAGE,
  VIDEO,
  DOCUMENT,
  FILE
} from '@src/constants/keys'
import CustomForm from '@src/components/forms/CustomForm'
import { toJS } from '@src/utils/commonUtil'
import CustomBreadcrumbs from '@src/components/layouts/CustomBreadcrumbs'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'

@inject('uiStore', 'authStore', 'structureStore', 'contentStore', 'categoryStore')
@observer

class Content extends React.Component {
  constructor (props) {
    super(props)
    const parsed = queryString.parse(this.props.location.search)
    const structureId = parseInt(parsed.id)
    const item = this.props.uiStore.allContents.menuList.find((menu) => menu.id === parseInt(parsed.id))
    const title = item.displayName.en
    this.state = {
      title: title,
      action: CREATE,
      currentStructureId: structureId,
      // currentContentIndex: 0,
      isContentDialogOpened: false,
      isDeleteDialogOpened: false,
      isFormVisible: false,
      // isAdminDialogOpened: false,
      searchText: '',
      filter: null,
      offset: 0,
      limit: 10,
      orderBy: null,
      order: 'asc',
      isLoading: true,
      downloaded: false
    }
  }

  componentDidMount () {
    this.fetchStructureDetail(this.state.currentStructureId)
  }

  componentDidUpdate () {
    const parsed = queryString.parse(this.props.location.search)
    const structureId = parseInt(parsed.id)
    if (this.state.currentStructureId !== structureId) {
      this.setState({
        searchText: '',
        offset: 0,
        currentStructureId: structureId,
        isLoading: true
      }, () => {
        this.fetchStructureDetail(structureId)
      })
    }
  }

  fetchAllStructures = () => {
    const { structureStore } = this.props
    structureStore.fetchAllStructures()
  }

  fetchStructureDetail = async (structureId) => {
    const { structureStore, uiStore } = this.props
    await structureStore.fetchStructureDetail(structureId)
    const filter = {}
    structureStore.item.fieldList.filter((column) => column.isFilterable).forEach((item) => {
      filter[item.identifier] = 'ALL'
    })
    // toJS(structureStore.item.fieldList.filter((column) => column.isFilterable))
    this.setState({
      title: structureStore.item.displayName[uiStore.language],
      orderBy: structureStore.item.orderBy,
      filter: filter
    }, async () => {
      await this.fetchContentList()
    })
  }

  fetchContentList = async () => {
    const { contentStore } = this.props
    const { currentStructureId, searchText, filter, offset, limit, orderBy, order } = this.state
    await contentStore.fetchContentList(currentStructureId, searchText, filter, offset, limit, orderBy, order)
    this.setState({
      isLoading: false
    })
  }

  toogleContentDialog = () => {
    this.setState({ isContentDialogOpened: !this.state.isContentDialogOpened })
  }

  // createContent = async (values, { setSubmitting, setErrors }) => {
  //   const { structureStore, contentStore } = this.props
  //   const { currentContentIndex } = this.state
  //   const structureList = structureStore.list
  //   const structureId = structureList[currentContentIndex].id
  //   await contentStore.createContent(structureId, values)
  //   this.toogleContentDialog()
  // }

  toogleDeleteDialog = () => {
    this.setState({ isDeleteDialogOpened: !this.state.isDeleteDialogOpened })
  }

  createAction = async () => {
    const { contentStore } = this.props
    await contentStore.clearForm()
    this.setState({
      action: CREATE,
      isContentDialogOpened: !this.state.isContentDialogOpened
    })
  }

  updateAction = async (index, contentId) => {
    const { contentStore } = this.props
    await contentStore.setForm(contentId)
    this.setState({
      action: UPDATE,
      currentContentId: contentId,
      isContentDialogOpened: !this.state.isContentDialogOpened
    })
  }

  handleSubmitForm = async (values, { setSubmitting, setErrors }) => {
    const { contentStore } = this.props
    const { action, currentContentId, currentStructureId } = this.state
    switch (action) {
      case CREATE:
        await contentStore.createContent(currentStructureId, values)
        break
      case UPDATE:
        await contentStore.updateContent(currentStructureId, currentContentId, values)
        break
      default:
        break
    }
    this.fetchContentList()
    this.toogleContentDialog()
  }

  deleteAction = (key, contentId) => {
    // toJS(rowData)
    this.setState({
      currentContentId: contentId,
      isDeleteDialogOpened: !this.state.isContentDialogOpened
    })
  }

  replicateAction = async (key, contentId) => {
    const { contentStore } = this.props
    await contentStore.setForm(contentId)
    this.setState({
      action: CREATE,
      isContentDialogOpened: !this.state.isContentDialogOpened
    })
  }

  handleDeleteContent = async () => {
    const { contentStore } = this.props
    await contentStore.deleteContent(this.state.currentStructureId, this.state.currentContentId)
    this.fetchContentList()
    this.toogleDeleteDialog()
  }

  submitContentForm = null

  bindSubmitForm = (submitForm) => {
    this.submitContentForm = submitForm
  }

  handleDialogSubmitForm = (event) => {
    if (this.submitContentForm) {
      this.submitContentForm(event)
    }
  }

  sortAction = (identifier, langCode) => {
    const { orderBy, order } = this.state
    const isAsc = orderBy.identifier === identifier && orderBy.langCode === langCode && order === 'asc'
    // const isAsc = orderBy === identifier && order === 'asc'
    const _order = (isAsc ? 'desc' : 'asc')
    // toJS({ identifier, langCode, order: _order })
    this.setState({
      order: _order,
      orderBy: { identifier, langCode, order: _order }
    }, () => {
      this.fetchContentList()
    })
  }

  searchAction = (event) => {
    this.setState({
      offset: 0,
      searchText: event.target.value
    }, () => {
      this.fetchContentList()
    })
  }

  switchLimitAction = (event) => {
    this.setState({
      limit: event.target.value
    }, () => {
      this.fetchContentList()
    })
  }

  offsetAction = (event, offset) => {
    this.setState({ offset: offset }, () => {
      this.fetchContentList()
    })
  }

  filterAction = (identifier, isParrent, event) => {
    const filter = this.state.filter
    filter[identifier] = event.target.value
    if (isParrent) {
      const { structureStore } = this.props
      structureStore.item.fieldList.filter((column) => column.isFilterable).forEach((item) => {
        if (identifier !== item.identifier) {
          filter[item.identifier] = 'ALL'
        }
      })
      this.setState({ filter: filter, offset: 0 }, () => {
        this.fetchContentList()
      })
    } else {
      this.setState({ filter: filter, offset: 0 }, () => {
        this.fetchContentList()
      })
    }
  }

  exportAction = async () => {
    const { contentStore } = this.props
    const data = await contentStore.exportToCsv(this.state.currentStructureId)
    const csvExporter = new ExportToCsv({
      filename: moment().format('YYYY-MM-DD'),
      showLabels: true,
      useKeysAsHeaders: true
    })

    csvExporter.generateCsv(data)

    this.setState({
      downloaded: true
    })
    return data
  }

  handleUploadFile = async (type, file) => {
    const { contentStore } = this.props
    let data = ''
    switch (type) {
      case IMAGE:
        data = await contentStore.uploadImage(this.state.currentStructureId, 0, file)
        break
      case VIDEO:
        data = await contentStore.uploadVideo(this.state.currentStructureId, 0, file)
        break
      case DOCUMENT:
        data = await contentStore.uploadDocument(this.state.currentStructureId, 0, file)
        break
      case FILE:
        data = await contentStore.uploadFile(this.state.currentStructureId, 0, file)
        break
      default:
        break
    }
    return data
  }

  render () {
    const { exportAction, replicateAction, switchContent, handleDialogSubmitForm, bindSubmitForm, handleUploadFile, filterAction, offsetAction, switchLimitAction, sortAction, searchAction, getColumns, getContentTableName, createAction, updateAction, deleteAction, handleSubmitForm, toogleContentDialog, toogleDeleteDialog, handleDeleteContent } = this
    const { uiStore, authStore, structureStore, contentStore, categoryStore } = this.props
    const {
      isDeleteDialogOpened,
      isContentDialogOpened,
      action,
      isFormVisible,
      isStructureDialogOpened,
      searchText,
      offset,
      limit,
      orderBy,
      order,
      title,
      isLoading,
      filter
    } = this.state
    // toJS(filter)
    // const columns = getColumns()
    // toJS(structureStore.structureFieldList)
    if (isLoading) {
      return <></>
    }
    return (
      <div>
        <CustomBreadcrumbs />
        {
          !isFormVisible &&
          (
            <CustomTable
              title={title}
              columns={structureStore.structureFieldList}
              data={contentStore.list}
              total={contentStore.count}
              createAction={createAction}
              updateAction={updateAction}
              deleteAction={deleteAction}
              replicateAction={replicateAction}
              searchText={searchText}
              offset={offset}
              limit={limit}
              orderBy={orderBy}
              order={order}
              filter={filter}
              sortAction={sortAction}
              searchAction={searchAction}
              switchLimitAction={switchLimitAction}
              offsetAction={offsetAction}
              filterAction={filterAction}
              allCategories={uiStore.allCategories}
              language={uiStore.language}
              isInsertable={structureStore.item.isInsertable}
              role={authStore.jwt.role}
              exportAction={exportAction}
              isExportable={structureStore.item.isExportable}
            />
          )
        }
        <FormDialog
          title={title}
          action={action}
          isDialogOpened={isContentDialogOpened}
          toogleDialog={toogleContentDialog}
          submitForm={handleDialogSubmitForm}
          initialValues={contentStore.form}
          dialogMaxWidth={uiStore.dialogMaxWidth}
          // structureFieldList={structureStore.structureFieldList}
          // allCategories={categoryStore.all}
          // uploadFile={handleUploadFile}
          form={
            <CustomForm
              action={action}
              initialValues={contentStore.form}
              submitForm={handleSubmitForm}
              bindSubmitForm={bindSubmitForm}
              structureFieldList={structureStore.structureFieldList}
              allCategories={uiStore.allCategories}
              language={uiStore.language}
              uploadFile={handleUploadFile}
              role={authStore.jwt.role}
            // error={baseModelStore.error}
            />
          }
        />
        <DeleteDialog
          isDialogOpened={isDeleteDialogOpened}
          toogleDialog={toogleDeleteDialog}
          submitForm={handleDeleteContent}
        />
      </div>
    )
  }
}

export default Content
