import React, { Fragment } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker, DateTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
import { enUS, zhCN } from 'date-fns/locale'
// import { connect } from 'formik'
// import { withTranslation, i18n } from '../../i18n'
import TextField from '@material-ui/core/TextField'

const localeMap = {
  en: enUS,
  tc: zhCN,
  sc: zhCN
}

class CustomDatePicker extends React.Component {
  constructor (props) {
    super(props)
    const value = this.props.initialValue
    let convertedValue = ''
    console.log(value)

    const timestamp = Date.parse(value)
    if (isNaN(timestamp) === false) {
      convertedValue = new Date(value).toISOString()
      // this.setState({ value: convertedValue })
    } else {
      // this.setState({ value: value })
    }

    // console.log(value)
    this.state = {
      value: convertedValue
    }
  }

  handleChange = (value) => {
    const name = this.props.field.name
    const setFieldValue = this.props.form.setFieldValue

    const timestamp = Date.parse(value)
    if (isNaN(timestamp) === false) {
      const convertedValue = new Date(value).toISOString()
      setFieldValue(name, convertedValue)
      this.setState({ value: convertedValue })
    } else {
      setFieldValue(name, '')
      this.setState({ value: value })
    }
  }

  render () {
    const { handleChange } = this
    const { label, language = 'en' } = this.props
    const { value } = this.state

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[language]}>
        <KeyboardDateTimePicker
          label={label}
          value={value}
          // disablePast
          onChange={handleChange}
          // label='With Today Button'
          showTodayButton
          format='yyyy/MM/dd hh:mm'
        />
      </MuiPickersUtilsProvider>
    )
  }
}

export default CustomDatePicker
