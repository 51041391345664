// import * as R from 'ramda'
import { observable, runInAction, makeObservable, action } from 'mobx'
import { init } from '@src/api/init'
import { toJS } from '@src/utils/commonUtil'
import structureTypeOptions from '@src/config/structureTypeOptions'
// import { withTranslation } from 'react-i18next'
import { getLanguage, storeLanguage } from '@src/utils/authUtil'

// import * as api from '../utils/api'
// import { i18n } from '../i18n'
// import Router from 'next/router'

// @withTranslation()

class UiStore {
  @observable panelName = ''
  @observable isReady = false
  @observable dialogMaxWidth = 'lg'
  @observable language = 'en'
  @observable menuList = null
  @observable allContents = null
  @observable developerMenu = null
  @observable adminMenu = null
  @observable systemStructureList = null
  @observable systemCategories = null
  @observable categories = null
  @observable allCategories = null
  @observable adminStructure = null
  @observable structureStructure = null
  @observable fieldStructure = null
  // @observable categoryOptionList = null
  // @observable partners = []
  // @observable pageTitle = ''
  // @observable pageContent = {}
  // @observable categories = []
  // @observable committees = {}
  // @observable metaData = {}
  // @observable isReady = false

  // constructor (isServer, authStore, memberStore, initialData = {}) {
  // this.memberStore = memberStore
  // this.init()
  // this.menuItems = R.propOr([], 'menuItems', initialData)
  // reaction(
  //   () => this.language,
  //   (slug, id, section) => {
  //     this.fetchMenuItems()
  //     this.fetchPartnerLogos()
  //     // this.fetchPageTitle(id)
  //     this.fetchPageContent(slug)
  //     this.fetchCategoryList(section)
  //     this.fetchCommitteesList()
  //   }
  // )
  // }

  constructor (rootStore) {
    makeObservable(this)
    runInAction(() => {
      this.rootStore = rootStore
      const language = getLanguage()
      if (language) {
        this.language = language
      }
    })
  }

  @action
  init = async () => {
    // toJS(this.props.i18n)
    // if (i18n.language) {
    //   this.language = i18n.language
    // }
    try {
      const data = await init()
      runInAction(() => {
        this.allContents = data
        this.systemStructureList = data.systemStructureList
        this.systemCategories = data.systemCategories
        this.allCategories = data.allCategories
        this.menuList = data.menuList
        const item = data.panelInitial.find((item) => item.parameter === 'common')
        if (item) {
          this.panelName = item.value.panelName
        }
        // this.categoryOptionList = data.categoryOptionList
        // for (const [key, value] of Object.entries(data.categories)) {
        //   this.categoryOptionList.push({ label: key, value: key })
        //   // console.log(key)
        //   // if (value !== 'ALL') {
        //   //   this.categoryOptionList.where(key, '=', value)
        //   // }
        // }
        // toJS(this.categoryOptionList)
        this.adminStructure = data.systemStructureList.find(structure => structure.identifier === 'administrator')
        this.structureStructure = data.systemStructureList.find(structure => structure.identifier === 'structure')
        this.fieldStructure = data.systemStructureList.find(structure => structure.identifier === 'structureField')
        this.initDeveloperMenu(data.menuList)
        if (data.panelInitial.find((item) => item.parameter === 'adminMenu')) {
          this.initAdminMenu(data.panelInitial.find((item) => item.parameter === 'adminMenu').value.adminMenu)
        }
        this.isReady = true
        // toJS(this.structures)
      })
    } catch (error) {
      console.log(error)
      // location.replace('/login')
    }
  }

  initDeveloperMenu = (menuList) => {
    const developerMenu = {}
    if (menuList) {
      structureTypeOptions().map((type) => {
        developerMenu[type.value] = menuList.filter(item => item.type === type.value)
      })
    }
    runInAction(() => {
      this.developerMenu = developerMenu
    })
  }

  initAdminMenu = (initMenu) => {
    initMenu.forEach((initItem, key) => {
      initMenu[key].detail = this.menuList.find((item) => initItem.identifier === item.identifier)
    })

    // toJS(initMenu)

    const level1ItemList = initMenu.filter((item) => item.level === 1)
    const level2ItemList = initMenu.filter((item) => item.level === 2)
    const adminMenu = level1ItemList

    level1ItemList.forEach((level1Item, level1Key) => {
      adminMenu[level1Key].itemList = level2ItemList.filter((level2Item) => level2Item.parentIdentifier === level1Item.identifier)
    })

    // toJS(adminMenu)

    runInAction(() => {
      this.adminMenu = adminMenu
    })
  }

  @action
  changeLanguage = async (lang) => {
    await storeLanguage(lang)
    runInAction(() => {
      this.language = lang
    })
    // if (currentPath === '/members-list'
    // || currentPath === '/members-project-showcase'
    // || currentPath === '/events'
    // || currentPath === '/members-events'
    // ) {
    //   Router.push(currentPath)
    // }
    // await i18n.changeLanguage(targetLanguage)
    // runInAction(() => {
    //   this.language = targetLanguage
    // })
  }

  // @action
  //   fetchMenuList = async () => {
  //     try {
  //       const data = await fetchStructureList()
  //       this.menuList = data
  //       // toJS(data)
  //       // this.setCurrentStructure(0)
  //     } catch (error) {
  //       console.log(error)
  //       console.log('Network Error')
  //     }
  //   }
}

export default UiStore
