/* eslint-disable no-use-before-define */

import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { toJS } from '@src/utils/commonUtil'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

class CustomTagPicker extends React.Component {
  constructor (props) {
    super(props)
    const { optionList } = this.props
    const defaultValue = this.props.field.value
    const formattedValue = defaultValue.map((value) => {
      const list = optionList.find((option) => option.value === value)
      return list
    })
    // toJS(formattedValue)
    this.state = {
      value: formattedValue,
      inputValue: ''
    }
  }

  handleChange = (event, newValue) => {
    const name = this.props.field.name
    const valueList = newValue.map((item) => {
      return item.value
    })
    // toJS(newValue)
    // const value = valueList.join()
    const setFieldValue = this.props.form.setFieldValue
    setFieldValue(name, valueList)
    this.setState({ value: newValue })
  }

  setInputValue = (event, newInputValue) => {
    // toJS(newInputValue)
    const value = newInputValue
    this.setState({ inputValue: value })
  }

  render () {
    const { handleChange, setInputValue } = this
    const { optionList } = this.props
    const { value, inputValue } = this.state
    // toJS(optionList)
    return (
      <div style={{ padding: 1 }}>
        <Autocomplete
          multiple
          value={value}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={setInputValue}
          options={optionList}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label.en}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label.en}
            </>
          )}
          // style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} variant='outlined' placeholder='' />
          )}
        />
      </div>
    )
  }
}

export default CustomTagPicker
