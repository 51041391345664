const pageOptions = [
  {
    label: '10',
    value: 10
  },
  {
    label: '20',
    value: 20
  }
]

export default pageOptions
