import React from 'react'
import { Formik, Form, Field } from 'formik'
import CustomTextField from '@src/components/inputs/CustomTextField'
import { toJS } from '@src/utils/commonUtil'

class BillForm extends React.Component {

  render () {
    const { initialValues, submitForm, bindSubmitForm } = this.props
    // toJS(initialValues)

    return (
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={submitForm}
        enableReinitialize
      >
        {(formikProps) => {
          bindSubmitForm(formikProps.submitForm)
          return (
            <Form>
              <div className='form-group flex-row'>
                <div className='form-label'>
                  {'username'}
                </div>
                <div className='form-input'>
                  <Field
                    name='username'
                    type='text'
                    label='username'
                    placeholder=''
                    component={CustomTextField}
                  />
                </div>
              </div>
              <div className='mt-20' />
              <div className='form-group flex-row'>
                <div className='form-label'>
                  {'password'}
                </div>
                <div className='form-input'>
                  <Field
                    name='password'
                    type='password'
                    label='password'
                    placeholder=''
                    component={CustomTextField}
                  />
                </div>
              </div>
              <div className='mt-20' />
              <div className='form-group flex-row'>
                <div className='form-label'>
                  {'role'}
                </div>
                <div className='form-input'>
                  <Field
                    name='role'
                    type='text'
                    label='role'
                    placeholder=''
                    component={CustomTextField}
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

export default BillForm
