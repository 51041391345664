import React from 'react'
import { Formik, Form, Field } from 'formik'
import '@src/styles/index.scss'
import CustomTextField from '@src/components/inputs/CustomTextField'
import CategoryForm from '@src/components/forms/CategoryForm'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  List,
  ListItem,
  Select,
  Switch,
  ListItemIcon,
  ListItemText,
  InboxIcon,
} from '@material-ui/core'
import {
  CREATE,
  UPDATE,
} from '@src/constants/keys'

class CategoryDialog extends React.Component {

  getTitle = () => {
    const { action } = this.props
    switch (action) {
      case CREATE:
        return 'Create Category'
      case UPDATE:
        return 'Update Category'
      default:
        break
    }
    return ''
  }

  submitMyForm = null

  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm
  }

  handleSubmitMyForm = (event) => {
    if (this.submitMyForm) {
      this.submitMyForm(event)
    }
  }

  render () {
    const { toogleDialog, isDialogOpened, submitForm, initialValues, structureFieldList } = this.props
    const { bindSubmitForm, handleSubmitMyForm } = this
    const _title = this.getTitle()
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={isDialogOpened}
          onClose={toogleDialog}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">{_title}</DialogTitle>
          <DialogContent>
            <Box mb={3} />
            <CategoryForm
              initialValues={initialValues}
              structureFieldList={structureFieldList}
              submitForm={submitForm}
              onClose={toogleDialog}
              bindSubmitForm={bindSubmitForm}
            // error={baseModelStore.error}
            />
            <div className='mt-20'></div>
            <div className='right'>
              <Button className="background-red color-white border-red" variant="outlined" onClick={handleSubmitMyForm}>提交</Button>
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default CategoryDialog
