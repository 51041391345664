import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'

export const updateStructureField = (structureId, structureFieldList) => {
  const authHeaders = authUtil.getAuthHeaders()
  return apiClient.patch(
    paths.updateStructure(structureId),
    {
      fieldList: structureFieldList
    },
    authHeaders
  )
}

