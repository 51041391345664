import React from 'react'
import {
  Box,
  Typography,
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
// import { TextField, Switch } from 'formik-material-ui'
import CustomPicker from '@src/components/inputs/CustomPicker'
import CustomTagPicker from '@src/components/inputs/CustomTagPicker'
import CustomTextField from '@src/components/inputs/CustomTextField'
import CustomTextArea from '@src/components/inputs/CustomTextArea'
import CustomRichText from '@src/components/inputs/CustomRichText'
import CustomDatePicker from '@src/components/inputs/CustomDatePicker'
import CustomSwitch from '@src/components/inputs/CustomSwitch'
import CustomImagePicker from '@src/components/inputs/CustomImagePicker'
import CustomVideoPicker from '@src/components/inputs/CustomVideoPicker'
import CustomDocumentPicker from '@src/components/inputs/CustomDocumentPicker'
import CustomMultiLangInput from '@src/components/inputs/CustomMultiLangInput'

import { toJS } from '@src/utils/commonUtil'
import {
  IMAGE,
  VIDEO,
  DOCUMENT
} from '@src/constants/keys'

class UserForm extends React.Component {
  getInputComponent = (field, component, uploadFileType, lang) => {
    const { allCategories, uploadFile } = this.props
    let identifierKey
    let inputComponent
    switch (lang) {
      case 'EN':
        identifierKey = `${field.identifier}En`
        inputComponent = (
          <Field
            name={identifierKey}
            type={field.type}
            placeholder=''
            component={component}
            variant='outlined'
            optionList={allCategories[field.related]}
            uploadFile={uploadFile}
            uploadFileType={uploadFileType}
            fullWidth
          />
        )
        break
      case 'TC':
        identifierKey = `${field.identifier}Tc`
        inputComponent = (
          <Field
            name={identifierKey}
            type={field.type}
            placeholder=''
            component={component}
            variant='outlined'
            optionList={allCategories[field.related]}
            uploadFile={uploadFile}
            uploadFileType={uploadFileType}
            fullWidth
          />
        )
        break
      case 'SC':
        identifierKey = `${field.identifier}Sc`
        inputComponent = (
          <Field
            name={identifierKey}
            type={field.type}
            placeholder=''
            component={component}
            variant='outlined'
            optionList={allCategories[field.related]}
            uploadFile={uploadFile}
            uploadFileType={uploadFileType}
            fullWidth
          />
        )
        break
      default:
        inputComponent = (
          <Field
            name={field.identifier}
            type={field.type}
            placeholder=''
            component={component}
            variant='outlined'
            optionList={allCategories[field.related]}
            uploadFile={uploadFile}
            uploadFileType={uploadFileType}
            fullWidth
          />
        )
        break
    }
    return inputComponent
  }

  render () {
    const { initialValues, submitForm, bindSubmitForm, structureFieldList, allCategories, uploadFile } = this.props
    const { getInputComponent } = this
    // toJS(structureFieldList)

    let convertedValues = {
      username: '',
      password: '',
    }
    let uploadFileType = ''
    structureFieldList.map((field, key) => {
      // console.log(field.type)
      const identifierKeyEn = `${field.identifier}En`
      const identifierKeyTc = `${field.identifier}Tc`
      const identifierKeySc = `${field.identifier}Sc`
      switch (field.type) {
        case 'text':
        case 'textarea':
        case 'richtext':
        case 'select':
          if (field.isMultiLang) {
            convertedValues[identifierKeyEn] = ''
            convertedValues[identifierKeyTc] = ''
            convertedValues[identifierKeySc] = ''
            if (initialValues[identifierKeyEn]) {
              convertedValues[identifierKeyEn] = initialValues[identifierKeyEn]
            }
            if (initialValues[identifierKeyTc]) {
              convertedValues[identifierKeyTc] = initialValues[identifierKeyTc]
            }
            if (initialValues[identifierKeySc]) {
              convertedValues[identifierKeySc] = initialValues[identifierKeySc]
            }
          } else {
            convertedValues[field.identifier] = ''
            if (initialValues[field.identifier]) {
              convertedValues[field.identifier] = initialValues[field.identifier]
            }
          }
          break
        case 'number':
          convertedValues[field.identifier] = ''
          break
        case 'date':
          convertedValues[field.identifier] = new Date().toISOString()
          break
        case 'boolean':
          convertedValues[field.identifier] = false
          break
        case 'image':
        case 'video':
        case 'document':
          convertedValues[field.identifier] = []
          break
        case 'tag':
          convertedValues[field.identifier] = []
          break
        default:
          break
      }
    })

    return (
      <Formik
        initialValues={convertedValues}
        // validationSchema={validationSchema}
        onSubmit={submitForm}
        enableReinitialize
      >
        {(formikProps) => {
          bindSubmitForm(formikProps.submitForm)
          return (
            <Form className={'form'}>
              <Typography variant='h6'>{'username'}:</Typography>
              <Field
                name={'username'}
                type={'text'}
                placeholder=''
                component={CustomTextField}
                variant='outlined'
                fullWidth
              />
              <Box mb={3} />
              <Typography variant='h6'>{'password'}:</Typography>
              <Field
                name={'password'}
                type={'password'}
                placeholder={''}
                component={CustomTextField}
                variant='outlined'
                fullWidth
              />
              <Box mb={3} />
              {
                structureFieldList.map((field, key) => {
                  // console.log(field.type)
                  let component
                  switch (field.type) {
                    case 'text':
                    case 'related':
                      component = CustomTextField
                      break
                    case 'number':
                      component = CustomTextField
                      break
                    case 'date':
                      component = CustomDatePicker
                      break
                    case 'textarea':
                      component = CustomTextArea
                      break
                    case 'richtext':
                      component = CustomRichText
                      break
                    case 'boolean':
                      component = CustomSwitch
                      break
                    case 'image':
                      component = CustomImagePicker
                      uploadFileType = IMAGE
                      break
                    case 'video':
                      component = CustomVideoPicker
                      uploadFileType = VIDEO
                      break
                    case 'document':
                      component = CustomDocumentPicker
                      uploadFileType = DOCUMENT
                      break
                    case 'select':
                      component = CustomPicker
                      break
                    case 'tag':
                      component = CustomTagPicker
                      break
                    default:
                      break
                  }
                  const inputComponent = getInputComponent(field, component, uploadFileType)
                  const inputComponentEn = getInputComponent(field, component, uploadFileType, 'EN')
                  const inputComponentTc = getInputComponent(field, component, uploadFileType, 'TC')
                  const inputComponentSc = getInputComponent(field, component, uploadFileType, 'SC')
                  return (
                    <div key={key}>
                      <Typography variant='h6'>{field.displayName}:</Typography>
                      {
                        field.isMultiLang &&
                        <CustomMultiLangInput
                          inputComponent={inputComponent}
                          inputComponentEn={inputComponentEn}
                          inputComponentTc={inputComponentTc}
                          inputComponentSc={inputComponentSc}
                        />
                      }
                      {
                        !field.isMultiLang &&
                        inputComponent
                      }
                      <Box mb={3} />
                    </div>
                  )
                })
              }
              <Box mb={3} />
              {/* {error && error.message} */}
            </Form>
          )
        }}
      </Formik >
    )
  }
}

export default UserForm
