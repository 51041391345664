import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'

export const exportToCsv = (structureId) => {
  const authHeaders = authUtil.getUploadHeaders()
  return apiClient.get(
    `${paths.exportToCsv}/${structureId}`,
    authHeaders
  )
}
