import React from "react"
import MaterialTable, { MTableToolbar, MTablePagination } from "material-table"
import { tableIcons } from '@src/components/tables/TableIcons'
// import AddBox from '@material-ui/icons/AddBox'
import { toJS } from '@src/utils/commonUtil'
import { Filter, Plus, Edit, Trash } from 'react-feather'
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button'

export default class AdminTable extends React.Component {
  state = {
    isFilterOpened: false
  }
  getColumns = () => {
    return [
      { title: 'Role', field: 'role' },
      { title: 'Username', field: 'username' },
    ]
  }
  getActions = () => {
    const { title, data, createAction, updateAction, deleteAction } = this.props
    const { isFilterOpened } = this.state
    return [
      // {
      //   icon: () => <AddTwoToneIcon />,
      //   tooltip: 'Add User',
      //   isFreeAction: true,
      //   onClick: createAction
      // },
      {
        icon: () => <FilterListTwoToneIcon />,
        tooltip: 'Filter',
        isFreeAction: true,
        onClick: () => this.setState({ isFilterOpened: !isFilterOpened })
      },
      {
        icon: () => <EditTwoToneIcon />,
        tooltip: 'Update User',
        onClick: updateAction
      },
      {
        icon: () => <DeleteTwoToneIcon />,
        tooltip: 'Delete User',
        onClick: deleteAction
      }
    ]
  }
  render () {
    const columns = this.getColumns()
    const actions = this.getActions()
    const { toogleDialog, contentDialog, data, isLoading, isDialogOpen, title, createAction } = this.props
    const { isFilterOpened } = this.state
    return (
      <div className='mt-table-container'>
        <div className='right mb-10'>
          {
            contentDialog
          }
        </div>
        {
          !isDialogOpen &&
          <MaterialTable
            style={{ boxShadow: 'none' }}
            isLoading={isLoading}
            icons={tableIcons}
            title={title}
            columns={columns}
            data={data}
            actions={actions}
            options={{
              actionsColumnIndex: -1,
              draggable: false,
              pageSize: 10,
              filtering: isFilterOpened,
              // exportButton: true,
              pageSizeOptions: [10, 15, 25, 50, 100],
              showTitle: false,
              // paginationType: 'stepped',
              // toolbarButtonAlignment: 'left'
            }}
            components={{
              Toolbar: props => {
                // console.log(props)
                return (
                  <div style={{ backgroundColor: '#fff' }}>
                    <div className='flex row space-between align-items-center'>
                      <div className={'mt-table-title'}>
                        {title}
                      </div>
                      <div className={'mt-table-add-button'}>
                        <Button
                          size="small"
                          disableElevation
                          variant="contained"
                          color="primary"
                          // className={classes.button}
                          startIcon={<AddTwoToneIcon />}
                          onClick={createAction}
                        >
                          New Admin
                      </Button>
                      </div>
                    </div>
                    <Divider />
                    <MTableToolbar {...props} />
                  </div>
                )
              },
              // Pagination: props => (
              //   <div style={{ backgroundColor: '#fff' }}>
              //     <MTablePagination {...props} />
              //   </div>
              // ),
            }}
          />
        }

      </div >
    )
  }
}
