import React from 'react'
import TextField from '@material-ui/core/TextField'
// import { observable, action, toJS } from 'mobx'
import ReactJson from 'react-json-view'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import { toJS } from '@src/utils/commonUtil'
import { JsonEditor as Editor } from 'jsoneditor-react'
import 'jsoneditor-react/es/editor.min.css'

class CustomTextArea extends React.Component {

  constructor (props) {
    super(props)
    const defaultValue = this.props.field.value
    this.state = {
      value: defaultValue
    }
  }

  handleChange = (event) => {
    // toJS(event.keyCode)
    // console.log('test')
    const name = this.props.field.name
    const value = event.jsObject
    const setFieldValue = this.props.form.setFieldValue
    setFieldValue(name, value)
    this.setState({ value: value })
  }

  render () {
    const { test, handleChange } = this
    const { label, type, placeholder } = this.props
    const { value } = this.state
    let jsonObject = {}
    try {
      jsonObject = JSON.parse(value)
    } catch (error) {
      // console.log(error)
    }
    // console.log(type)
    return (
      <>
        {/* <TextField
          type={type}
          variant='outlined'
          // margin='normal'
          defaultValue={value}
          required
          fullWidth
          label={label}
          // name='email'
          // autoComplete='email'
          placeholder={placeholder}
          onChange={handleChange}
          multiline
          rows={6}
        /> */}
        <JSONInput
          placeholder={value}
          // colors={{
          //   string: '#DAA520'
          // }}
          locale={locale}
          width='100%'
          height='300px'
          waitAfterKeyPress={2000}
          onChange={handleChange}
          // onKeyPressUpdate={false}
          confirmGood={false}
        />
        {/* <ReactJson src={value} /> */}
      </>
    )
  }
}

export default CustomTextArea
