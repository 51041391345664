import React from 'react'
import TextField from '@material-ui/core/TextField'
// import { observable, action, toJS } from 'mobx'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'

const BootstrapInput = withStyles((theme) => ({
  root: {

  },
  input: {
    padding: '10px 12px',
    // height: '36px',
    // lineHeight: '36px',
    backgroundColor: '#fff',
    border: '1px solid #e4e6ef',
    borderRadius: '.42rem',
    // transition: 'background-color 0.5s ease',
    transition: 'box-shadow 0.5s ease',
    '&:focus': {
      // backgroundColor: '#ebedf3'
      boxShadow: '0 0 0 0.1rem rgba(0,123,255,.25)',
    },
    '&::placeholder': {
      fontSize: '14px',
      // color: 'blue'
    }
  },
  // notchedOutline: {
  //   borderWidth: '0px',
  //   borderColor: '#f3f6f9 !important'
  // }
}))(InputBase)

class CustomTextField extends React.Component {

  constructor (props) {
    super(props)
    const defaultValue = this.props.value
    this.state = {
      value: defaultValue
    }
  }

  handleChange = (event) => {
    const name = this.props.field.name
    let value = event.target.value
    if (this.props.type === 'number') {
      value = parseInt(value)
    }
    // console.log(value)
    const setFieldValue = this.props.form.setFieldValue
    // const setFieldValue = this.props.setFieldValue
    // console.log(name)
    // setTimeout(() => {
    //   setValues('email', 'tetstestets')
    // }, 500)
    setFieldValue(name, value)
    // console.log(toJS(this.props.form.values))
    // console.log(value)
    // console.log(this.props.form.handleChange)
    // this.setState({ value: value })
    // console.log(this.props)
    // setFieldValue(name, 'tetstestets')
  }

  render () {
    const { handleChange } = this
    const { label, type, placeholder, classes, isFormik, onChange } = this.props
    const { value } = this.state
    // console.log(type)
    return (
      <BootstrapInput
        type={type}
        variant='outlined'
        // margin='normal'
        defaultValue={value}
        required
        fullWidth
        // label={label}
        // name='email'
        // autoComplete='email'
        placeholder={placeholder}
        onChange={isFormik ? handleChange : onChange}
      />
    )
  }
}

export default CustomTextField
