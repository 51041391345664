import React from 'react'
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Link,
  // useLocation,
  // useHistory
} from 'react-router-dom'
import MiniDrawerLayout from '@src/components/layouts/MiniDrawerLayout'
import { inject, observer } from 'mobx-react'
import CustomBreadcrumbs from '@src/components/layouts/CustomBreadcrumbs'
import Dashboard from '@src/components/screens/Dashboard'
import Admin from '@src/components/screens/Admin'
import Structure from '@src/components/screens/Structure'
import Field from '@src/components/screens/Field'
import Content from '@src/components/screens/Content'
import User from '@src/components/screens/User'
import Relation from '@src/components/screens/Relation'
import Category from '@src/components/screens/Category'
import Test from '@src/views/Test'
import { toJS, getBreadcrumb } from '@src/utils/commonUtil'
import queryString from 'query-string'
// import { Router, Link } from "@reach/router"
import { createBrowserHistory } from 'history'

@inject('uiStore')
@observer

class Layout extends React.Component {
  // componentDidMount () {
  //   console.log('test')

  //   this.init()
  //   // const parsed = queryString.parse(location.search)
  //   // const list = location.pathname.split('/')
  //   // console.log(list)
  //   // this.setLocationInfo()
  // }

  // componentDidUpdate () {
  //   // const parsed = queryString.parse(location.search)
  //   // const list = location.pathname.split('/')
  //   // console.log(list)
  //   // this.setLocationInfo()
  // }

  // setLocationInfo = () => {
  // this.setState({
  //   search: queryString.parse(location.search)
  // })
  // }

  // init = async () => {
  //   // const { uiStore } = this.props
  //   // const menu = await getBreadcrumb(uiStore.menu, location.pathname)
  //   // toJS(uiStore.menu)
  //   // this.setState({
  //   //   menu: menu
  //   // })
  // }

  render () {
    return (
      <>
        <MiniDrawerLayout>
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route path='/dashboard' component={Dashboard} />
            <Route path='/administrator' component={Admin} />
            <Route path='/structure' component={Structure} />
            <Route path='/field' component={Field} />
            <Route path='/content' component={Content} />
            <Route path='/user' component={User} />
            <Route path='/relation' component={Relation} />
            <Route path='/category' component={Category} />
            <Route path='/test' component={Test} />
          </Switch>
        </MiniDrawerLayout>
      </>
    )
  }
}

export default Layout
