import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'
import {
  IMAGE,
  VIDEO,
  DOCUMENT,
  FILE
} from '@src/constants/keys'

export const uploadFile = (structureId, userId, file) => {
  const authHeaders = authUtil.getUploadHeaders()
  const formData = new window.FormData()
  formData.append('file', file)
  return apiClient.post(
    paths.upload(FILE),
    formData,
    authHeaders
  )
}
