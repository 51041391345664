import React from 'react'
import { Formik, Form, Field } from 'formik'
import '@src/styles/index.scss'
import CustomTextField from '@src/components/inputs/CustomTextField'
import BillForm from '@src/components/forms/BillForm'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  List,
  ListItem,
  Select,
  Switch,
  ListItemIcon,
  ListItemText,
  InboxIcon,
} from '@material-ui/core'

class DeleteConfirmationDialog extends React.Component {

  getTitle = () => {
    const { action } = this.props
    switch (action) {
      case 'create':
        return '新增帳單'
      case 'update':
        return '更改帳單'
      default:
        break
    }
    return ''
  }

  submitMyForm = null

  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm
  }

  handleSubmitMyForm = (event) => {
    if (this.submitMyForm) {
      this.submitMyForm(event)
    }
  }

  render () {
    const { toogleDialog, isDialogOpened, submitForm, initialValues } = this.props
    const { bindSubmitForm, handleSubmitMyForm } = this
    const _title = this.getTitle()
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={isDialogOpened}
          onClose={toogleDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{'系統訊息'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {'確定刪除?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={toogleDialog} color="primary">
              取消
          </Button>
            <Button onClick={submitForm} color="primary" autoFocus>
              確定
          </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default DeleteConfirmationDialog
