import React from 'react'
import BraftEditor from 'braft-editor'
import table from 'braft-extensions/dist/table'
import 'braft-editor/dist/index.css'
import 'braft-extensions/dist/table.css'
import { authUtil } from '@src/utils'
import commonConfig from '@src/config/commonConfig'
import { Upload } from 'antd'
import { PictureFilled } from '@ant-design/icons'

BraftEditor.use(
  table({
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    // withDropdown: true, // 插入表格前是否弹出下拉菜单
    // columnResizable: true, // 是否允许拖动调整列宽，默认false
    // exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
  })
)

const myUploadFn = (param) => {
  const baseURL = commonConfig.apiHost + commonConfig.basePath
  const serverURL = `${baseURL}cms/upload/upload-image`
  const xhr = new XMLHttpRequest()
  const fd = new FormData()

  const successFn = (response) => {
    console.log(response.target.response)
    const url = response.target.response.url
    // 假设服务端直接返回文件上传后的地址
    // 上传成功后调用param.success并传入上传后的文件地址
    param.success({
      url: url.original,
      // meta: {
      //   id: 'xxx',
      //   title: 'xxx',
      //   alt: 'xxx',
      //   loop: true, // 指定音视频是否循环播放
      //   autoPlay: true, // 指定音视频是否自动播放
      //   controls: true, // 指定音视频是否显示控制栏
      //   poster: 'http://xxx/xx.png', // 指定视频播放器的封面
      // }
    })
  }

  const progressFn = (event) => {
    // 上传进度发生变化时调用param.progress
    param.progress(event.loaded / event.total * 100)
  }

  const errorFn = (response) => {
    // 上传发生错误时调用param.error
    param.error({
      msg: 'unable to upload.'
    })
  }

  xhr.upload.addEventListener('progress', progressFn, false)
  xhr.addEventListener('load', successFn, false)
  xhr.addEventListener('error', errorFn, false)
  xhr.addEventListener('abort', errorFn, false)

  fd.append('file', param.file)
  xhr.open('POST', serverURL, true)
  xhr.responseType = 'json'
  xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
  xhr.setRequestHeader('Authorization', authUtil.getAuthorization())
  xhr.send(fd)
}

class CustomRichText extends React.Component {
  constructor (props) {
    super(props)
    const defaultValue = this.props.field.value
    this.state = {
      editorState: BraftEditor.createEditorState(defaultValue)
    }
  }

  handleChange = (editorState) => {
    this.setState({
      editorState: editorState
    }, () => {
      this.handleSave()
    })
  }

  handleSave = () => {
    const htmlContent = this.state.editorState.toHTML()
    const name = this.props.field.name
    const setFieldValue = this.props.form.setFieldValue
    setFieldValue(name, htmlContent)
  }

  render () {
    const { handleChange, handleSave, customUploadAdapterPlugin } = this
    const { editorState } = this.state
    const { uploadFile } = this.props
    const excludeControls = ['emoji', 'letter-spacing', 'superscript', 'subscript', 'code']

    return (
      <div style={{ width: '100%' }}>
        <BraftEditor
          value={editorState}
          onChange={handleChange}
          onBlur={handleSave}
          media={{
            uploadFn: myUploadFn,
            accepts: { image: 'image/png,image/jpeg,image/gif', video: false, audio: false },
            externals: false,
          }}
          excludeControls={excludeControls}
          language='en'
          fontSizes={[
            12, 14, 16, 18, 20, 24,
            28, 30, 32, 36, 40, 48,
            56, 64, 72, 96, 120, 144
          ]}
          lineHeights={[1, 1.2, 1.5, 1.75, 2, 2.5, 3, 4]}
        />
      </div>
    )
  }
}

export default CustomRichText
