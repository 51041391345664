import React from 'react'
import '@src/styles/fonts/flaticon.css'
import '@src/styles/index.scss'
import '@src/styles/common.scss'
import '@src/styles/flex.scss'
import '@src/styles/menu.scss'
import '@src/styles/mt-table.scss'
import '@src/styles/react-table.scss'

class VerticalCenterLayout extends React.Component {
  render () {
    const { children } = this.props
    return (
      <div className='h-center w300'>
        <div className='v-center w300'>
          {children}
        </div>
      </div>
    )
  }
}

export default VerticalCenterLayout
