import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'
import {
  SEARCH
} from '@src/constants/keys'

export const init = () => {
  const authHeaders = authUtil.getAuthHeaders()
  return apiClient.get(
    paths.init(),
    authHeaders
  )
}
