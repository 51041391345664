import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'

export const deleteUser = (structureId, userId) => {
  const authHeaders = authUtil.getAuthHeaders()
  return apiClient.delete(
    paths.user(structureId, userId),
    authHeaders
  )
}
