import { observable, action, decorate } from 'mobx'
// import * as api from '../services/api'
// import { ACCESS_TOKEN } from '../constants/tokenKeys'
import { fetchAllCategories, fetchCategoryList, createCategory, updateCategory, deleteCategory } from '@src/api/category'
import { toJS } from '@src/utils/commonUtil'

export default class CategoryStore {
  @observable all = null
  @observable list = []
  @observable item = null
  @observable error = null
  @observable form = {}

  constructor(rootStore) {
    // console.log(rootStore)
    // window.localStorage.setItem(ACCESS_TOKEN, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiQUNDRVNTX1RPS0VOIiwiaWQiOjQsInVzZXJuYW1lIjoicml2ZXJuQGZhbnRyZW5kLmNvbS5oayIsImlhdCI6MTU5MzcwNzIwMiwiZXhwIjoxNTk0MzEyMDAyfQ.0trDKF69j1-rGJHfnTCCgSVn3yeKlPS4N_OmbtwVCvs')
    this.rootStore = rootStore
  }

  @action
  setForm = async (contentId) => {
    const content = this.list.find(item => item.id === contentId)
    this.form = content.data
  }

  @action
  fetchAllCategories = async () => {
    const data = await fetchAllCategories()
    this.all = data
  }

  @action
  fetchCategoryList = async (structureId) => {
    const data = await fetchCategoryList(structureId)
    this.list = data
  }

  @action
  createCategory = async (structureId, values) => {
    // toJS(this.list[structureId])
    // this.list[structureId].push(values)
    await createCategory(structureId, values)
  }

  @action
  updateCategory = async (structureId, contentId, values) => {
    // toJS(this.list[structureId])
    // this.list[structureId].push(values)
    // await updateCategory(structureId, contentId, values)
  }

  @action
  deleteCategory = async (structureId, contentId) => {
    // toJS(this.list[structureId])
    // this.list[structureId].push(values)
    // console.log(contentId)
    // await deleteCategory(structureId, contentId)
  }

  @action
  clearCategory () {
    this.list = []
  }
}
