import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'

export const createAdmin = (username, password, role) => {
  const authHeaders = authUtil.getAuthHeaders()
  return apiClient.post(
    paths.createAdmin(),
    {
      username,
      password,
      role
    },
    authHeaders
  )
}
