import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'

export const fetchCategoryList = (structureId) => {
  const authHeaders = authUtil.getAuthHeaders()
  return apiClient.get(
    paths.category(structureId),
    authHeaders
  )
}
