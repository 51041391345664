import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'

export const updateContent = (structureId, contentId, data) => {
  const authHeaders = authUtil.getAuthHeaders()
  return apiClient.patch(
    paths.content(structureId, contentId),
    {
      data: data
    },
    authHeaders
  )
}

