import React from 'react'
import { Formik, Form, Field } from 'formik'
import '@src/styles/index.scss'
import CustomTextField from '@src/components/inputs/CustomTextField'
import Button from '@material-ui/core/Button'
import { toJS } from '@src/utils/commonUtil'

class BillForm extends React.Component {

  render () {
    const { initialValues, submitForm, bindSubmitForm } = this.props
    // toJS(initialValues)

    return (
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={submitForm}
        enableReinitialize
      >
        {
          (formikProps) => {
            bindSubmitForm(formikProps.submitForm)
            return (
              <Form>
                <Field
                  name='name'
                  type='text'
                  label='帳單名稱'
                  placeholder='11月的開支'
                  component={CustomTextField}
                />
              </Form>
            )
          }
        }
      </Formik>
    )
  }
}

export default BillForm
