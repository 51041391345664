export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const SEARCH = 'SEARCH'
export const UPLOAD = 'UPLOAD'
export const IMAGE = 'IMAGE'
export const VIDEO = 'VIDEO'
export const DOCUMENT = 'DOCUMENT'
export const FILE = 'FILE'
export const LANGUAGE = 'LANGUAGE'
