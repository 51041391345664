import { observable, action, runInAction, computed, reaction, makeObservable } from 'mobx'
import * as api from '@src/api'
import { toJS } from '@src/utils/commonUtil'
import { getTokens, storeTokens, removeTokens } from '@src/utils/authUtil'
import { login, refresh, register, fetchAccount, updateAccount } from '@src/api/auth'
import ms from 'ms'
import jwt from 'jsonwebtoken'
import commonConfig from '@src/config/commonConfig'

export default class AuthStore {
  @observable registerForm = {
    email: '',
    password: '',
    name: ''
  }

  @observable loginForm = {
    email: '',
    password: ''
  }

  @observable accountForm = {
    name: '',
    fpsMobile: ''
  }

  @observable authInfo = null
  @observable accessToken = null
  @observable refreshToken = null
  @observable error = null

  constructor (rootStore) {
    makeObservable(this)
    runInAction(() => {
      this.rootStore = rootStore

      if (commonConfig.env === 'development') {
        this.loginForm = {
          email: 'rivern@fanttrend.com',
          password: 'nN123456'
        }
      }

      const { accessToken, refreshToken } = getTokens()
      this.accessToken = accessToken
      this.refreshToken = refreshToken

      this.setupTokenTimeoutCheck()

      // watch for the change of access token,
      // when it happens, store to local storage
      reaction(
        () => this.accessToken,
        () => {
          if (this.accessToken && this.refreshToken) {
            storeTokens({
              accessToken: this.accessToken,
              refreshToken: this.refreshToken
            })
          } else {
            removeTokens()
          }
        }
      )
    })
  }

  @computed get isAuthenticated () {
    return !!this.accessToken
  }

  @computed get jwt () {
    if (this.accessToken) {
      return jwt.decode(this.accessToken)
    } else {
      return null
    }
  }

  @action
  register = async (email, password, nickname, billId) => {
    const registerResult = await register(email, password, { nickname }, billId)
    if (registerResult) {
      const loginResult = await this.login(email, password)
      if (loginResult) {
        return true
      }
    }
    return false
  }

  @action
  login = async (email, password) => {
    const { accessToken, refreshToken, data } = await login(email, password)
    this.accountForm = data
    // console.log(accessToken, refreshToken)
    runInAction(() => {
      this.accessToken = accessToken
      this.refreshToken = refreshToken
    })
    // console.log(jwt.decode(this.accessToken))
    return true
  }

  @action
  refresh = async () => {
    try {
      const { accessToken, refreshToken } = await refresh(this.refreshToken)
      runInAction(() => {
        this.accessToken = accessToken
        this.refreshToken = refreshToken
      })
    } catch (error) {
      // background requests no need to set error
      console.log(error)
      runInAction(async () => {
        this.accessToken = null
        this.refreshToken = null
        window.location.replace('/login')
      })
    }
  }

  @action
  fetchAccount = async () => {
    const account = await fetchAccount()
    runInAction(() => {
      this.accountForm = account
    })
    return true
  }

  @action
  updateAccount = async (values) => {
    const account = await updateAccount(values)
    runInAction(() => {
      this.accountForm = account
    })
    return true
  }

  @action
  logout = async () => {
    removeTokens()
    return true
  }

  @action
  clearError = () => {
    this.error = null
  }

  refreshIfExpireSoon = async () => {
    if (this.isAuthenticated) {
      const { exp } = jwt.decode(this.accessToken)
      // console.log(jwt.decode(this.accessToken))
      const now = new Date()
      const fiveMinutesFromNow = new Date(now.getTime() + ms('5mins'))
      if (new Date(exp * 1000) <= fiveMinutesFromNow) {
        await this.refresh()
      }
    }
  }

  setupTokenTimeoutCheck = async () => {
    // invoke once immediately
    await this.refreshIfExpireSoon()
    // check access token every 5 minutes,
    // then refresh it if it expires in 5 minutes
    setInterval(this.refreshIfExpireSoon, ms('5mins'))

    // runInAction(() => {
    //   this.isReady = true
    // })
  }
}
