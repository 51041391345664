import React from 'react'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
// import { observable, action, toJS } from 'mobx'
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  MenuItem,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { toJS } from '@src/utils/commonUtil'

const BootstrapInput = withStyles((theme) => ({
  root: {

  },
  input: {
    padding: '10px 12px',
    height: '36px',
    lineHeight: '36px',
    backgroundColor: '#f3f6f9',
    borderColor: '#f3f6f9',
    borderRadius: '4px',
    // transition: 'background-color 0.5s ease',
    // '&.Mui-focused': {
    //   backgroundColor: '#ebedf3'
    // },
    // notchedOutline: {
    //   borderRadius: '14px'
    // }
  }
}))(InputBase)

class CustomPicker extends React.Component {

  constructor (props) {
    super(props)
    let defaultValue = ''
    if (this.props.field.value) {
      defaultValue = this.props.field.value
    }
    this.state = {
      value: defaultValue
    }
    // toJS(defaultValue)
  }

  handleChange = (event) => {
    const name = this.props.field.name
    const value = event.target.value
    const setFieldValue = this.props.form.setFieldValue
    // const setFieldValue = this.props.setFieldValue
    // console.log(name)
    // setTimeout(() => {
    //   setValues('email', 'tetstestets')
    // }, 500)
    setFieldValue(name, value)
    // console.log(toJS(this.props.form.values))
    // console.log(value)
    // console.log(this.props.form.handleChange)
    this.setState({ value: value })
    // console.log(this.props)
    // setFieldValue(name, 'tetstestets')
  }

  render () {
    const { handleChange } = this
    const { language = 'en', isMultilingual = true, hasNoneOption = false, hasAllOption = false, label, type, placeholder, optionList, classes } = this.props
    const { value } = this.state
    const allOption = {
      en: 'All',
      tc: '全部',
      sc: '全部'
    }
    return (
      <FormControl variant='outlined' fullWidth>
        <Select
          value={value}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {
            hasNoneOption && (
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
            )
          }
          {
            hasAllOption && (
              <MenuItem value='ALL'>
                {
                  isMultilingual ? allOption[language] : allOption.en
                }
              </MenuItem>
            )
          }
          {
            // isMultilingual &&
            optionList.map((option, key) => {
              return <MenuItem key={key} value={option.value}>{option.label[language]}</MenuItem>
            })
          }
          {
            // !isMultilingual &&
            // optionList.map((option, key) => {
            //   return <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
            // })
          }
        </Select>
      </FormControl>
    )
  }
}

export default CustomPicker
