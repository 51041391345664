import React from 'react'
import { inject, observer } from 'mobx-react'
import MiniDrawerLayout from '@src/components/layouts/MiniDrawerLayout'
import UserDialog from '@src/components/dialogs/UserDialog'
import CustomTable from '@src/components/tables/CustomTable'
import queryString from 'query-string'
import DeleteDialog from '@src/components/dialogs/DeleteDialog'
import {
  CREATE,
  UPDATE,
  IMAGE,
  VIDEO,
  DOCUMENT
} from '@src/constants/keys'
import { toJS } from '@src/utils/commonUtil'

@inject('authStore', 'structureStore', 'userStore', 'categoryStore')
@observer

class Dashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      action: CREATE,
      isUserDialogOpened: false,
      isDeleteDialogOpened: false,
      currentStructureId: 0,
      currentUserIndex: 0
    }
  }

  componentDidMount () {
    const parsed = queryString.parse(window.location.search)
    const structureId = parseInt(parsed.id)
    this.setState({
      currentStructureId: structureId,
    })
    const { structureStore, categoryStore } = this.props
    structureStore.fetchStructureDetail(structureId)
    this.fetchUserList(structureId)
    categoryStore.fetchAllCategories()
  }

  fetchAllStructures = () => {
    const { structureStore } = this.props
    structureStore.fetchAllStructures()
  }

  fetchUserList = (structureId) => {
    const { userStore } = this.props
    userStore.fetchUserList(structureId)
  }

  toogleUserDialog = () => {
    this.setState({ isUserDialogOpened: !this.state.isUserDialogOpened })
  }

  createUser = async (values, { setSubmitting, setErrors }) => {
    const { structureStore, userStore } = this.props
    const { currentUserIndex } = this.state
    const structureList = structureStore.list
    const structureId = structureList[currentUserIndex].id
    await userStore.createUser(structureId, values)
    this.toogleUserDialog()
  }

  toogleDeleteDialog = () => {
    this.setState({ isDeleteDialogOpened: !this.state.isDeleteDialogOpened })
  }

  createAction = () => {
    this.setState({
      action: CREATE,
      isUserDialogOpened: !this.state.isUserDialogOpened
    })
  }

  updateAction = async (event, rowData) => {
    // console.log(rowData)
    const { userStore } = this.props
    await userStore.setForm(rowData.id)
    this.setState({
      action: UPDATE,
      currentUserId: rowData.id,
      isUserDialogOpened: !this.state.isUserDialogOpened
    })
  }

  handleSubmitForm = async (values, { setSubmitting, setErrors }) => {
    const { userStore } = this.props
    const { action, currentUserId, currentStructureId } = this.state
    switch (action) {
      case CREATE:
        await userStore.createUser(currentStructureId, values)
        break
      case UPDATE:
        await userStore.updateUser(currentStructureId, currentUserId, values)
        break
      default:
        break
    }
    this.fetchUserList(currentStructureId)
    this.toogleUserDialog()
  }

  deleteAction = (event, rowData) => {
    // toJS(rowData)
    this.setState({
      currentUserId: rowData.id,
      isDeleteDialogOpened: !this.state.isUserDialogOpened
    })
  }

  handleDeleteUser = async () => {
    const { userStore } = this.props
    await userStore.deleteUser(this.state.currentStructureId, this.state.currentUserId)
    const { currentStructureId } = this.state
    this.fetchUserList(currentStructureId)
    this.toogleDeleteDialog()
  }

  handleUploadFile = async (type, file) => {
    const { userStore } = this.props
    let data = ''
    switch (type) {
      case IMAGE:
        data = await userStore.uploadImage(this.state.currentStructureId, 0, file)
        break
      case VIDEO:
        data = await userStore.uploadVideo(this.state.currentStructureId, 0, file)
        break
      case DOCUMENT:
        data = await userStore.uploadDocument(this.state.currentStructureId, 0, file)
        break
      default:
        break
    }
    return data
  }

  getUserTableName = () => {
    const { authStore, structureStore, contentStore } = this.props
    let contentTableName = ''
    if (structureStore.item) {
      contentTableName = structureStore.item.displayName
    }
    return contentTableName
  }

  getColumns = () => {
    const { structureStore } = this.props
    // toJS(fieldList)
    const defaultColumnList = [{ title: 'username', field: 'username' }]
    const customColumnList = structureStore.structureFieldList.map((field, key) => {
      return { title: field.displayName, field: field.identifier }
    })
    return [...defaultColumnList, ...customColumnList]
  }

  getTableData = () => {
    const { userStore } = this.props
    const dataList = userStore.list.map((user) => {
      let data = user.data
      data['username'] = user.username
      return data
    })
    return dataList
  }

  render () {
    const { handleUploadFile, getColumns, getTableData, getUserTableName, action, createAction, updateAction, deleteAction, handleSubmitForm, toogleUserDialog, toogleDeleteDialog, handleDeleteUser } = this
    const { structureStore, userStore, categoryStore } = this.props
    const { isDeleteDialogOpened, isUserDialogOpened } = this.state
    const userTableName = getUserTableName()
    const columns = getColumns()
    const tableData = getTableData()

    // toJS(categoryStore.all)

    return (
      <div>
        <CustomTable
          title={userTableName}
          columns={columns}
          data={tableData}
          createAction={createAction}
          updateAction={updateAction}
          deleteAction={deleteAction}
          structureFieldList={structureStore.structureFieldList}
        />
        <UserDialog
          action={action}
          isDialogOpened={isUserDialogOpened}
          toogleDialog={toogleUserDialog}
          submitForm={handleSubmitForm}
          initialValues={userStore.form}
          structureFieldList={structureStore.structureFieldList}
          allCategories={categoryStore.all}
          uploadFile={handleUploadFile}
        />
        <DeleteDialog
          isDialogOpened={isDeleteDialogOpened}
          toogleDialog={toogleDeleteDialog}
          submitForm={handleDeleteUser}
        />
      </div>
    )
  }
}

export default Dashboard
