import { observable, action, makeObservable, runInAction } from 'mobx'
// import * as api from '../services/api'
// import { ACCESS_TOKEN } from '../constants/tokenKeys'
import { fetchContentList, createContent, updateContent, deleteContent } from '@src/api/content'
import { uploadImage, uploadVideo, uploadDocument, uploadFile } from '@src/api/upload'
import { exportToCsv } from '@src/api/export'
import { toJS } from '@src/utils/commonUtil'

export default class ContentStore {
  @observable list = []
  @observable item = null
  @observable error = null
  @observable form = {}
  @observable count = 0

  constructor (rootStore) {
    makeObservable(this)
    runInAction(() => {
      this.rootStore = rootStore
    })
  }

  @action
  setForm = async (contentId) => {
    const content = this.list.find(item => item.id === contentId)
    const multiLangFieldList = this.rootStore.structureStore.structureFieldList.filter((field) => field.isMultilingual)
    multiLangFieldList.forEach((field) => {
      const langKeyList = ['en', 'tc', 'sc']
      langKeyList.forEach((langCode) => {
        // toJS(field.identifier)
        if(!content[field.identifier]){
          content[`${field.identifier}${langCode}`] = ""
        } else {
          content[`${field.identifier}${langCode}`] = content[field.identifier][langCode]
        }
      })
    })
    this.form = content
  }

  @action
  clearForm = async () => {
    this.form = {}
  }

  @action
  fetchContentList = async (structureId, searchText, filter, offset, limit, orderBy, order) => {
    const data = await fetchContentList(structureId, searchText, filter, offset, limit, orderBy, order)
    runInAction(() => {
      this.list = data.list
      this.count = data.count
    })
  }

  @action
  createContent = async (structureId, data) => {
    // toJS(data)
    // toJS(this.list[structureId])
    // this.list[structureId].push(values)
    const postData = {}
    this.rootStore.structureStore.structureFieldList.forEach((field) => {
      // toJS(field)
      if (field.isMultilingual) {
        const langKeyList = ['en', 'tc', 'sc']
        postData[field.identifier] = {}
        langKeyList.forEach((langCode) => {
          let input = ''
          // toJS(field.identifier)
          if (data[`${field.identifier}${langCode}`]) {
            input = data[`${field.identifier}${langCode}`]
          }
          postData[field.identifier][langCode] = input
        })
      } else {
        postData[field.identifier] = data[field.identifier]
      }
    })
    // toJS(postData)
    await createContent(structureId, postData)
  }

  @action
  updateContent = async (structureId, contentId, data) => {
    // toJS(this.list[structureId])
    // this.list[structureId].push(values)
    const postData = {}
    const langKeyList = ['en', 'tc', 'sc']
    this.rootStore.structureStore.structureFieldList.forEach((field) => {
      if (field.isMultilingual) {
        postData[field.identifier] = {}
        langKeyList.forEach((langCode) => {
          if (data[`${field.identifier}${langCode}`]) {
            postData[field.identifier][langCode] = data[`${field.identifier}${langCode}`]
          } else {
            postData[field.identifier][langCode] = ''
          }
        })
      } else {
        postData[field.identifier] = data[field.identifier]
      }
    })
    await updateContent(structureId, contentId, postData)
  }

  @action
  deleteContent = async (structureId, contentId) => {
    // toJS(this.list[structureId])
    // this.list[structureId].push(values)
    // console.log(contentId)
    await deleteContent(structureId, contentId)
  }

  @action
  clearContent () {
    this.list = []
  }

  @action
  uploadFile = async (structureId, contentId, file) => {
    const data = await uploadFile(structureId, contentId, file)
    // console.log(data)
    return data
  }

  @action
  uploadImage = async (structureId, contentId, file) => {
    const data = await uploadImage(structureId, contentId, file)
    // console.log(data)
    return data
  }

  @action
  uploadVideo = async (structureId, contentId, file) => {
    const data = await uploadVideo(structureId, contentId, file)
    // console.log(data)
    return data
  }

  @action
  uploadDocument = async (structureId, contentId, file) => {
    const data = await uploadDocument(structureId, contentId, file)
    return data
  }

  @action
  exportToCsv = async (structureId) => {
    const data = await exportToCsv(structureId)
    return data
  }
}
