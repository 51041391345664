import React from "react"
import MaterialTable from "material-table"
import { tableIcons } from '@src/components/tables/TableIcons'
import AddBox from '@material-ui/icons/AddBox'
import { toJS } from '@src/utils/commonUtil'

export default class CategoryTable extends React.Component {
  getColumns = () => {
    const { structureFieldList } = this.props
    // toJS(fieldList)
    let columnList = structureFieldList.map((field, key) => {
      return { title: field.displayName, field: field.identifier }
    })
    return columnList
    // const { currentStructure } = this.props
    // if (currentStructure) {
    //   // console.log(currentStructure.data.fieldList)
    // }
    return [
      // { title: 'DisplayName', field: 'displayName' },
      // { title: 'Type', field: 'type' },
      // { title: 'Surname', field: 'surname' },
      // { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
      // {
      //   title: 'Birth Place',
      //   field: 'birthCity',
      //   lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
      // },
    ]
  }
  getData = () => {
    const { data } = this.props
    return data.map(
      (d) => {
        d.data.id = d.id
        return d.data
      }
    )

    // console.log(currentStructure)
    // if (fieldList) {
    //   // console.log(currentStructure.data.fieldList)
    return;
    // }
    // const { currentStructure } = this.props
    // console.log(currentStructure)
    // if (currentStructure) {
    //   currentStructure.map(() => {

    //   })
    // }
    return [
      { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
      { name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
    ]
  }
  getActions = () => {
    const { title, data, createAction, updateAction, deleteAction } = this.props
    return [
      {
        icon: () => <i className='flaticon-043-plus mt-table-icon' />,
        tooltip: 'Add User',
        isFreeAction: true,
        onClick: createAction
      },
      {
        icon: () => <i className='flaticon-162-edit mt-table-icon' />,
        tooltip: 'Update User',
        onClick: updateAction
      },
      {
        icon: () => <i className='flaticon-133-trash mt-table-icon' />,
        tooltip: 'Delete User',
        onClick: deleteAction
      }
    ]
  }
  render () {
    const columns = this.getColumns()
    const actions = this.getActions()
    const convertedData = this.getData()
    const { categoryDialog, isLoading, isDialogOpen, title } = this.props
    // toJS(fieldList)
    return (
      <div>
        <div className='right mb-10'>
          {
            categoryDialog
          }
        </div>
        {
          !isDialogOpen &&
          <MaterialTable
            isLoading={isLoading}
            icons={tableIcons}
            title={title}
            columns={columns}
            data={convertedData}
            actions={actions}
            options={{
              actionsColumnIndex: -1
            }}
          />
        }

      </div>
    )
  }
}
