import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'

export const register = (username, password, data, billId) => {
  return apiClient.post(
    paths.register,
    {
      username,
      password,
      data,
      billId
    }
  )
}
