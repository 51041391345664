import * as mobx from 'mobx'

export const toJS = (string) => {
  console.log(mobx.toJS(string))
}

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value)
}

export const getLocalStorage = (key) => {
  if (process.browser) {
    const value = window.localStorage.getItem(key)
    return value
  }
  return null
}

export const getBreadcrumb = async (menuList, fullPath) => {
  const level1 = menuList.find((menu) => (menu.level === 1))
  const level2 = menuList.find((menu) => (menu.level === 2 && fullPath.includes(menu.path)))
  const level3 = menuList.find((menu) => (menu.level === 3 && fullPath.includes(menu.path)))
  return { level1, level2, level3 }
}

export const truncateString = (str, n, useWordBoundary) => {
  if (str.length <= n) { return str }
  const subString = str.substr(0, n - 1)
  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(' '))
    : subString) + '...'
}

export const trimHtmlTag = (string) => {
  const regex = /(<([^>]+)>)/ig
  const result = string.replace(regex, '')
  return result
}

export const isEmpty = (obj) => {
  if (Object.keys(obj).length === 0) {
    return true
  }
  return false
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
