import structureTypeOptions from '@src/config/structureTypeOptions'

const structureColumns = [
  {
    displayName: 'Type',
    identifier: 'structureType',
    title: 'Type',
    field: 'structureType',
    isFilterable: true,
    optionList: structureTypeOptions(true)
  },
  {
    displayName: 'Identifier',
    identifier: 'identifier',
    title: 'identifier',
    field: 'identifier'
  },
  {
    displayName: 'Display Name',
    identifier: 'displayName',
    title: 'Display Name',
    field: 'displayName'
  }
]

export default structureColumns
