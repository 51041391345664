import React from 'react'
import { Formik, Form, Field } from 'formik'
import '@src/styles/index.scss'
import CustomTextField from '@src/components/inputs/CustomTextField'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  List,
  ListItem,
  Select,
  Switch,
  ListItemIcon,
  ListItemText,
  InboxIcon,
  withStyles
} from '@material-ui/core'
import {
  CREATE,
  UPDATE,
} from '@src/constants/keys'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // '& > *': {
    //   margin: theme.spacing(1),
    //   width: theme.spacing(16),
    //   height: theme.spacing(16),
    // },
  },
  paperHeader: {
    fontSize: 18,
    fontWeight: 600,
    padding: 20
  },
  paperBody: {
    marginTop: 10,
    marginBottom: 10,
    padding: 20
  },
  paperFooter: {
    padding: 20
  }
})

@withStyles(styles)

class AdminFormPaper extends React.Component {

  getTitle = () => {
    const { action, title } = this.props
    switch (action) {
      case CREATE:
        return `Create ${title}`
      case UPDATE:
        return `Update ${title}`
      default:
        break
    }
    return ''
  }

  render () {
    const { submitForm, toogleForm, classes, form } = this.props
    const _title = this.getTitle()
    return (
      <Paper elevation={0} variant='outlined'>
        <div className={classes.paperHeader}>
          {_title}
        </div>
        <Divider />
        <div className={classes.paperBody}>
          {form}
        </div>
        <Divider />
        <div className={classes.paperFooter}>
          <div className='flex-row flex-end'>
            <Button disableElevation variant='contained' color='primary' onClick={submitForm}>Submit</Button>
            <div className='mr-10' />
            <Button disableElevation variant='contained' onClick={toogleForm}>Cancel</Button>
          </div>
        </div>
      </Paper>
    )
  }
}

export default AdminFormPaper
