import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'
import {
  SEARCH
} from '@src/constants/keys'

export const fetchStructureList = (searchText, filter, offset, limit, orderBy, order) => {
  const authHeaders = authUtil.getAuthHeaders(SEARCH, { searchText, filter, offset, limit, orderBy, order })
  return apiClient.get(
    paths.fetchStructureList(),
    authHeaders
  )
}
