import { createTheme } from '@material-ui/core/styles'

export const blueTheme = createTheme({
  palette: {
    background: {
      default: '#e7e8f2'
    },
    primary: {
      main: '#3699ff',
      contrastText: '#fff'
    },
    secondary: {
      main: '#fff',
      contrastText: '#3699ff'
      // light: '#fff',
      // dark: '#ececec',
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
})
