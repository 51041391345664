import { observable, action } from 'mobx'

export default class ExampleStore {
  @observable ExampleForm = {

  }

  // constructor() {

  // }

  @action
  submitForm = async (values) => {
    console.log(values)
  }

}

