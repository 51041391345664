import React from 'react'
import { inject, observer } from 'mobx-react'
import LoginForm from '@src/components/forms/LoginForm'
import VerticalCenterLayout from '@src/components/layouts/VerticalCenterLayout'
// import { navigate } from 'gatsby'

@inject('authStore')
@observer

class Login extends React.Component {

  componentDidMount () {
  }

  submitForm = async (values, { setSubmitting, setErrors }) => {
    const { authStore } = this.props
    const { email, password } = values
    const result = await authStore.login(email, password)
    if (result) {
      // window.location.href = '/content?id=8'
      window.location.href = '/dashboard'
    }
  }

  render () {
    const { authStore } = this.props
    const { loginForm } = authStore
    const { submitForm } = this

    return (
      <VerticalCenterLayout>
        <div className='center'>
          <i className='flaticon-006-key icon-l color-primary'></i>
        </div>
        <LoginForm
          currentForm={loginForm}
          submitForm={submitForm}
        />
      </VerticalCenterLayout>
    )
  }
}

export default Login
