import React from 'react'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/MoveToInbox'
// import { navigate } from 'gatsby'
import {
  IconButton,
  withStyles,
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import StarBorder from '@material-ui/icons/StarBorder'
// import history from '@src/services/history'
// import history from 'history/browser'
// import { createBrowserHistory } from 'history'
import {
  Switch,
  Route,
  BrowserRouter,
  Link,
  useLocation,
  useHistory
} from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
// import LayersSVG from 'assets/media/svg/icons/Design/Layers.svg'
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone'
import SupervisorAccountTwoToneIcon from '@material-ui/icons/SupervisorAccountTwoTone'
import DeveloperBoardTwoToneIcon from '@material-ui/icons/DeveloperBoardTwoTone'
import ChevronLeftTwoToneIcon from '@material-ui/icons/ChevronLeftTwoTone'
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone'
import KeyboardArrowRightTwoToneIcon from '@material-ui/icons/KeyboardArrowRightTwoTone'
// import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import KeyboardArrowDownTwoToneIcon from '@material-ui/icons/KeyboardArrowDownTwoTone'
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone'
import classnames from 'classnames'
import structureTypeOptions from '@src/config/structureTypeOptions'
import { toJS, truncateString, trimHtmlTag } from '@src/utils/commonUtil'
import Icon from '@material-ui/core/Icon'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
})

@withRouter
@withStyles(styles)
@inject('authStore', 'structureStore', 'contentStore', 'adminStore', 'uiStore')
@observer

class CustomMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isDrawerOpened: true,
      isStructureDialogOpened: false,
      isMenuItemVisableList: [],
      anchorEl: null,
      openAccountDialog: false,
      activeItem: ''
    }
  }

  componentDidMount () {
    this.initMenuItemActionList()
  }

  initMenuItemActionList = async () => {
    const { uiStore } = this.props
    // toJS(uiStore)
    // await uiStore.init()
    if (uiStore.menuList) {
      const list = uiStore.menuList.map(() => {
        return false
      })
      // console.log(list)
      this.setState({ isMenuItemVisableList: list })
    }
  }

  toogleMenuItem = (index) => {
    const list = this.state.isMenuItemVisableList
    list[index] = !this.state.isMenuItemVisableList[index]
    this.setState({ isMenuItemVisableList: list })
  }

  route = (path) => {
    this.setState({ activeItem: path })
    this.props.history.push(path)
    console.log(window)

    if (window.screen.width < 600) {
      this.props.toggleDrawer()
    }
  }

  routeStructure = (type, id, event) => {
    event.stopPropagation()
    this.route(`/content?id=${id}`)
  }

  clickStructure = (structureType, structureId) => {
    // switch (structureType) {
    //   case 'INFORMATION':
    //     this.route(`/content?id=${structureId}`)
    //     break
    //   case 'USER':
    //     this.route(`/user?id=${structureId}`)
    //     break
    //   default:
    //     break
    // }
  }

  render () {
    const { classes, structureStore, uiStore } = this.props
    const { navigateField, navigateContent, navigateUser, route, routeStructure, toogleMenuItem, clickStructure } = this
    const { isDrawerOpened, isStructureDialogOpened, isMenuItemVisableList, anchorEl, activeItem } = this.state
    // console.log(isMenuItemVisableList)
    const language = uiStore.language
    // toJS(uiStore.allContents.menuList)

    return (
      <ul className='menu-nav'>
        {
          <li className={classnames('menu-link flex row align-items-center', { active: activeItem === '/admin' })} onClick={() => route('/administrator')}>
            <span className='menu-icon flex row align-items-center'>
              <SupervisorAccountTwoToneIcon />
            </span>
            <span className='menu-text flex row align-items-center'>Admin</span>
          </li>
        }
        {
          // uiStore.adminMenu.map((level1Item) => {
          //   return (
          //     <div>{level1Item.detail.displayName.en}</div>
          //   )
          // })
        }
        {
          uiStore.adminMenu &&
          (
            uiStore.adminMenu.map(level1Item => {
              const structure = level1Item.detail
              const key = level1Item.detail.id
              // toJS(level1Item)
              return (
                <div key={key}>
                  {
                    level1Item.itemList.length > 0 ? (
                      <>
                        <li
                          className='menu-link flex row space-between align-items-center'
                          onClick={toogleMenuItem.bind(this, key)}
                        // onClick={clickStructure.bind(this, structure.type, structure.id)}
                        >
                          <div className='flex row'>
                            <span className='menu-icon flex row align-items-center'>
                              <DeveloperBoardTwoToneIcon />
                              {/* level1Item.icon */}
                              {/* <Icon>{level1Item.icon}</Icon> */}
                            </span>
                            <span className='menu-text flex row align-items-center'>
                              {/* {structure.displayName[language]} */}
                              {truncateString(structure.displayName[language], 18, false)}
                            </span>
                          </div>
                          {
                            isMenuItemVisableList[key]
                              ? <KeyboardArrowDownTwoToneIcon className='mr-10' />
                              : <KeyboardArrowRightTwoToneIcon className='mr-10' />
                          }
                        </li>
                        <Collapse in={isMenuItemVisableList[key]} timeout='auto' unmountOnExit>
                          <ul className='submenu-nav'>
                            {/* <li onClick={routeStructure.bind(this, `/field?id=${structure.id}`)}>
                        <div className='submenu-link flex row align-items-center'>
                          <span className='submenu-bullet' />
                          <span className='submenu-text'>Fields</span>
                        </div>
                      </li> */}
                            <li onClick={routeStructure.bind(this, structure.type, structure.id)}>
                              <div className='submenu-link flex row align-items-center'>
                                <span className='submenu-bullet' />
                                <span className='submenu-text'>{structure.displayName.en}</span>
                              </div>
                            </li>
                            {
                              level1Item.itemList.map((item, key) => {
                                return (
                                  <li key={key} onClick={routeStructure.bind(this, structure.type, item.detail.id)}>
                                    <div className='submenu-link flex row align-items-center'>
                                      <div><div className='submenu-bullet' /></div>
                                      <div className='submenu-text'>{item.detail.displayName.en}</div>
                                    </div>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </Collapse>
                      </>
                    ) : (
                      <li className={classnames('menu-link flex row align-items-center', { active: activeItem === '/admin' })} onClick={routeStructure.bind(this, structure.type, structure.id)}>
                        <span className='menu-icon flex row align-items-center'>
                          <SupervisorAccountTwoToneIcon />
                        </span>
                        <span className='menu-text flex row align-items-center'>{structure.displayName.en}</span>
                      </li>
                    )
                  }
                </div>
              )
            })
          )
        }
      </ul>
    )
  }
}

export default CustomMenu
