import React from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
// import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { inject, observer } from 'mobx-react'
import { capitalize, toJS } from '@src/utils/commonUtil'
import queryString from 'query-string'
import {
  // Switch,
  // Route,
  // BrowserRouter as Router,
  Link,
  // useLocation,
  // useHistory
} from 'react-router-dom'
import { Divider } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

@withRouter
@inject('uiStore')
@observer

class CustomBreadcrumbs extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      level1: null,
      last: null
    }
  }

  // componentDidUpdate () {
  // const { search, pathname } = this.props
  // toJS(search)
  // toJS(pathname)
  // if (search.id) {
  //   const item = this.props.uiStore.allContents.menuList.find((menu) => menu.id === parseInt(search.id))
  //   this.setState({
  //     level1: item
  //   })
  // }
  // }

  componentDidMount () {
    const list = window.location.pathname.split('/')
    let item = this.props.uiStore.allContents.systemStructureList.find((structure) => structure.identifier === list[1])
    let last = 'List'
    if (!item) {
      const parsed = queryString.parse(window.location.search)
      if (parsed.id) {
        item = this.props.uiStore.allContents.menuList.find((menu) => menu.id === parseInt(parsed.id))
        last = list[1]
      }
    }
    // toJS(this.props.uiStore.allContents)
    // const item = this.props.uiStore.allContents.menuList.find((menu) => menu.id === parseInt(parsed.id))

    // toJS(item)
    // const parsed = queryString.parse(location.search)
    // // const list = location.pathname.split('/')
    // if (parsed.id) {
    //   const item = this.props.uiStore.allContents.menuList.find((menu) => menu.id === parseInt(parsed.id))
    //   // toJS(this.props.uiStore.allContents.menuList)
    //   // toJS(parsed.id)
    //   // toJS(item)
    //   this.setState({
    //     level1: item
    //   })
    // }
    this.setState({
      level1: item,
      last: last
    })
    // toJS('/' + list[1])
    // toJS(this.props.uiStore.allContents.menuList)
  }

  componentDidUpdate () {
    // const list = location.pathname.split('/')
    // toJS(list)
    // const parsed = queryString.parse(location.search)
    //   if (parsed.id) {
    //     const item = this.props.uiStore.allContents.menuList.find((menu) => menu.id === parseInt(parsed.id))
    //     // toJS(this.props.uiStore.allContents.menuList)
    //     // toJS(parsed.id)
    //     // toJS(item)
    //     this.setState({
    //       level1: item
    //     })
    //   }
    //   // toJS('/' + list[1])
    //   // toJS(this.props.uiStore.allContents.menuList)
  }

  handleBreadcrumbsClick = (path) => {
    // event.preventDefault()
    // console.info('You clicked a breadcrumb.')
    // toJS(this.state.level1)
    this.props.history.push(path)
  }

  render () {
    const { handleBreadcrumbsClick } = this
    const { menuList } = this.props
    const { level1, last } = this.state
    // toJS(level1)
    // if (!level1) {
    //   return (<></>)
    // }
    return (
      <>
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          {/* {
            menuList && menuList.map((menu) => {

            })
          } */}
          {/* <div color='inherit' onClick={handleBreadcrumbsClick.bind(this, '/')}>Home</div> */}
          {
            level1 &&
            (
              <div color='inherit'>{level1.displayName.en}</div>
            )
          }
          <Typography color='textPrimary'>{capitalize(last)}</Typography>
        </Breadcrumbs>
        <div className='mt-10' />
      </>
    )
  }
}

export default CustomBreadcrumbs
