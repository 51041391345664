import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  UPLOAD,
  SEARCH,
  LANGUAGE
} from '@src/constants/keys'

export const storeLanguage = (language) => {
  window.localStorage.setItem(LANGUAGE, language)
}

export const getLanguage = () => {
  if (process.browser) {
    const language = window.localStorage.getItem(LANGUAGE)
    return language
  }
  return null
}

export const storeTokens = ({ accessToken, refreshToken }) => {
  // console.log(accessToken, refreshToken)
  window.localStorage.setItem(ACCESS_TOKEN, accessToken)
  window.localStorage.setItem(REFRESH_TOKEN, refreshToken)
}

export const removeTokens = () => {
  window.localStorage.removeItem(ACCESS_TOKEN)
  window.localStorage.removeItem(REFRESH_TOKEN)
}

export const getTokens = () => {
  // console.log(process.browser)
  if (process.browser) {
    const accessToken = window.localStorage.getItem(ACCESS_TOKEN)
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN)
    return {
      accessToken,
      refreshToken
    }
  }
  return {
    accessToken: null,
    refreshToken: null
  }
}

export const getAuthorization = () => {
  const token = getTokens()
  return `Bearer ${token.accessToken}`
}

export const getAuthHeaders = (action, params) => {
  const token = getTokens()
  // console.log('getAuthHeaders', token)
  let headers
  switch (action) {
    case UPLOAD:
      headers = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          'content-type': 'multipart/form-data'
        }
      }
      break
    case SEARCH:
      headers = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`
        },
        params: params
      }
      break
    default:
      headers = {
        headers: {
          Authorization: `Bearer ${token.accessToken}`
        }
      }
      break
  }
  return headers
}

export const getUploadHeaders = () => {
  const token = getTokens()
  // console.log('getAuthHeaders', token)
  return {
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'multipart/form-data'
    }
  }
}
