import * as yup from 'yup'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  MenuItem,
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
// import { TextField, Switch } from 'formik-material-ui'
import CustomPicker from '@src/components/inputs/CustomPicker'
import CustomTextField from '@src/components/inputs/CustomTextField'
import CustomDatePicker from '@src/components/inputs/CustomDatePicker'
import { toJS } from '@src/utils/commonUtil'

class CategoryForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    const { initialValues, submitForm, bindSubmitForm, structureFieldList } = this.props
    // toJS(initialValues)

    // const schemaConfig = {
    //   name: yup.string().required(),
    //   identifier: yup.string().required(),
    // }
    // const validationSchema = yup.object().shape(schemaConfig)
    // const initialValues = {
    //   ...values
    // }

    let convertedValues = {}
    structureFieldList.map((field, key) => {
      switch (field.type) {
        case 'text':
          convertedValues[field.identifier] = ''
          if (initialValues[field.identifier]) {
            convertedValues[field.identifier] = initialValues[field.identifier]
          }
          break;
        case 'number':
          convertedValues[field.identifier] = ''
          break;
        case 'date':
          convertedValues[field.identifier] = new Date().toISOString()
          break;
        default:
          break;
      }
    })

    return (
      <Formik
        initialValues={convertedValues}
        // validationSchema={validationSchema}
        onSubmit={submitForm}
        enableReinitialize
      >
        {(formikProps) => {
          bindSubmitForm(formikProps.submitForm)
          return (
            <Form className={'form'}>
              <Box mb={3} />
              {
                structureFieldList.map((field, key) => {
                  let component
                  switch (field.type) {
                    case 'text':
                    case 'related':
                      component = CustomTextField
                      break;
                    case 'number':
                      component = CustomTextField
                      break;
                    case 'date':
                      component = CustomDatePicker
                      break;
                    default:
                      break;
                  }
                  return (
                    <div key={key}>
                      <Typography variant='h6'>{field.displayName}:</Typography>
                      <Field
                        name={field.identifier}
                        type={field.type}
                        placeholder=''
                        component={component}
                        variant='outlined'
                        fullWidth
                      />
                      <Box mb={3} />
                    </div>
                  )
                })
              }
              <Box mb={3} />
              {/* {error && error.message} */}
            </Form>
          )
        }}
      </Formik >
    )
  }
}

export default CategoryForm
