// import * as R from 'ramda'
import axios from 'axios'
import commonConfig from '@src/config/commonConfig'

const apiClient = axios.create({
  baseURL: commonConfig.baseURL,
  timeout: commonConfig.requestTimeout
})

apiClient.interceptors.response.use(
  response => {
    // console.log(response)
    return response.data
  },
  error => {
    // console.log(error.response.data)
    // console.log(error.response.data.errors.message)
    throw error
    // console.log(error.response.data)
    // return error.response.data
    // const errorString = ('' + error)
    // const regex = /error: ([\W\w]+)/gmi
    // const match = regex.exec(errorString)
    // const errorMsg = match[1]
    // const errorCode = errorMsg.replace(' ', '_').toUpperCase()
    // // alert(errorMsg)
    // console.log(errorMsg)
    // throw R.pathOr(
    //   { code: 'UNKNOWN_SERVER_ERROR' },
    //   ['response', 'data'],
    //   error
    // )
  }
)

export default apiClient
