import React from 'react'
import MaterialTable, { MTableToolbar, MTablePagination } from 'material-table'
import { tableIcons } from '@src/components/tables/TableIcons'
// import AddBox from '@material-ui/icons/AddBox'
import { Filter, Plus, Edit, Trash } from 'react-feather'
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone'
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone'
import KeyboardArrowDownTwoToneIcon from '@material-ui/icons/KeyboardArrowDownTwoTone'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TextField from '@material-ui/core/TextField'
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Pagination from 'material-ui-flat-pagination'
import CssBaseline from '@material-ui/core/CssBaseline'
// import Pagination from '@material-ui/lab/Pagination'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import CustomOutlinedTextField from '@src/components/inputs/CustomOutlinedTextField'
import CustomOutlinedPicker from '@src/components/inputs/CustomOutlinedPicker'
import pageOptions from '@src/config/pageOptions'
import commonConfig from '@src/config/commonConfig'
import { isEmpty, truncateString, trimHtmlTag, toJS } from '@src/utils/commonUtil'
import ReactJson from 'react-json-view'
import moment from 'moment'
import FileCopyIcon from '@material-ui/icons/FileCopy'

export default class CustomTable extends React.Component {
  state = {
    offset: 0,
    isFilterOpened: false
  }

  // getActions = () => {
  //   const { title, data, createAction, updateAction, deleteAction } = this.props
  //   const { isFilterOpened } = this.state
  //   return [
  //     // {
  //     //   icon: () => <AddTwoToneIcon />,
  //     //   tooltip: 'Add User',
  //     //   isFreeAction: true,
  //     //   onClick: createAction
  //     // },
  //     {
  //       icon: () => <FilterListTwoToneIcon />,
  //       tooltip: 'Filter',
  //       isFreeAction: true,
  //       onClick: () => this.setState({ isFilterOpened: !isFilterOpened })
  //     },
  //     {
  //       icon: () => <EditTwoToneIcon />,
  //       tooltip: 'Update User',
  //       onClick: updateAction
  //     },
  //     {
  //       icon: () => <DeleteTwoToneIcon />,
  //       tooltip: 'Delete User',
  //       onClick: deleteAction
  //     }
  //   ]
  // }

  getTableHead = () => {
    const { total, count, allCategories, language = 'en', filterAction, filter, offsetAction, switchLimitAction, updateAction, deleteAction, sortAction, searchAction, orderBy, order, searchText, offset, limit, columns, toogleDialog, contentDialog, data, isLoading, isDialogOpen, title, createAction } = this.props
    const langCodeList = ['en', 'tc', 'sc']
    const tableHead = columns && columns.map((column, key) => {
      // toJS(column)
      // if (column.isMultilingual) {
      //   return langCodeList.map((langCode, key2) => {
      //     if (column.isDisplayable[langCode]) {
      //       return (
      //         <TableCell sortDirection={(orderBy.identifier === column.identifier && orderBy.langCode === langCode) ? order : false} className='th' key={key2}>
      //           <TableSortLabel
      //             active={(orderBy.identifier === column.identifier && orderBy.langCode === langCode)}
      //             direction={(orderBy.identifier === column.identifier && orderBy.langCode === langCode) ? order : 'asc'}
      //             onClick={sortAction.bind(this, column.identifier, langCode)}
      //           >
      //             {
      //               `${column.displayName[language]} (${langCode})`
      //             }
      //           </TableSortLabel>
      //         </TableCell>
      //       )
      //     }
      //   })
      // } else {
      if (column.isDisplayable) {
        return (
          <TableCell sortDirection={orderBy.identifier === column.identifier ? orderBy.order.toLowerCase() : false} className='th' key={key}>
            <TableSortLabel
              active={orderBy.identifier === column.identifier}
              direction={orderBy.identifier === column.identifier ? orderBy.order.toLowerCase() : 'asc'}
              onClick={sortAction.bind(this, column.identifier, language)}
            >
              {
                `${column.displayName[language]}`
              }
            </TableSortLabel>
          </TableCell>
        )
      }
      // }
    })
    return (
      <TableRow>
        {tableHead}
        <TableCell align='right' className='th'>Actions</TableCell>
      </TableRow>
    )
  }

  getTableData = () => {
    const { isEditable = true, isReplicable = true, allCategories, language = 'en', replicateAction, filterAction, filter, offsetAction, switchLimitAction, updateAction, deleteAction, sortAction, searchAction, orderBy, order, searchText, offset, limit, total, columns, toogleDialog, contentDialog, data, isLoading, isDialogOpen, title, createAction } = this.props
    const langCodeList = ['en', 'tc', 'sc']
    const tableData = data && data.map((row, key) => {
      return (
        <TableRow key={key}>
          {
            columns.map((column, key) => {
              // toJS('column')
              // toJS(column)
              let optionList = []
              if (column.categoryStructureIdentifier && allCategories[column.categoryStructureIdentifier]) {
                optionList = allCategories[column.categoryStructureIdentifier]
              }
              if (column.isMultilingual) {
                if (column.isDisplayable) {
                  if (column.type === 'PICKER') {
                    const option = optionList.find((option) => option.value === row[column.identifier])
                    if (option) {
                      return <TableCell key={key}>{option.label[language]}</TableCell>
                    }
                  }
                  if (column.type === 'IMAGE') {
                    return <></>
                  }
                  if (column.type === 'IMAGE_ALBUM') {
                    return <></>
                  }
                  if (column.type === 'VIDEO') {
                    return <></>
                  }
                  if (column.type === 'VIDEO_ALBUM') {
                    return <></>
                  }
                  if (column.type === 'RICHTEXT') {
                    return <TableCell key={key}>{truncateString(trimHtmlTag(row[column.identifier][language]), 20)}</TableCell>
                  }
                  if (column.type === 'JSON') {
                    return <TableCell key={key}> </TableCell>
                  }
                  // if (column.type === 'TEXT' || column.type === 'STRING') {
                  //   return <TableCell key={key}>{truncateString(row[column.identifier][language], 20)}</TableCell>
                  // }
                  // toJS(row)
                  // toJS(column.identifier)
                  // toJS(row[column.identifier])
                  return (
                    <TableCell key={key}>
                      {
                        row[column.identifier]
                          ? truncateString(row[column.identifier][language], 20)
                          : ''
                      }
                    </TableCell>
                  )
                }
              } else {
                // toJS(row)
                if (column.isDisplayable) {
                  if (column.type === 'TAG') {
                    // toJS(row[column.identifier])
                    if (row[column.identifier]) {
                      return <TableCell key={key}>{row[column.identifier].join(',')}</TableCell>
                    }
                    return <TableCell key={key}>{row[column.identifier]}</TableCell>
                  }
                  if (column.type === 'IMAGE') {
                    // toJS(row[column.identifier])
                    if (row[column.identifier].length > 0) {
                      // toJS(row[column.identifier])
                      return <TableCell padding='none' key={key}><img src={`${row[column.identifier][0].thumbnail}`} /></TableCell>
                    }
                    return <TableCell key={key}>None</TableCell>
                  }
                  if (column.type === 'DOCUMENT' || column.type === 'FILE') {
                    if (row[column.identifier].length > 0) {
                      // toJS(row[column.identifier])
                      return <TableCell key={key}>{`${row[column.identifier][0].original}`}</TableCell>
                    }
                    return <TableCell key={key}>None</TableCell>
                  }
                  if (column.type === 'JSON') {
                    return <TableCell key={key}><ReactJson src={row[column.identifier]} collapsed={0} enableClipboard={false} /></TableCell>
                  }
                  if (column.type === 'DATETIME') {
                    return <TableCell key={key}>{moment(row[column.identifier]).format('YYYY-MM-DD hh:mm:ss')}</TableCell>
                  }
                  if (column.type === 'DATE') {
                    return <TableCell key={key}>{moment(row[column.identifier]).format('YYYY-MM-DD')}</TableCell>
                  }
                  if (column.type === 'BOOLEAN') {
                    if (row[column.identifier]) {
                      return <TableCell key={key}>Yes</TableCell>
                    } else {
                      return <TableCell key={key}>No</TableCell>
                    }
                  }
                  if (column.type === 'STRING' || column.type === 'TEXT') {
                    return <TableCell key={key}>{truncateString(row[column.identifier], 20)}</TableCell>
                  }
                  if (column.isDisplayable) {
                    return <TableCell key={key}>{row[column.identifier]}</TableCell>
                  }
                }
              }
            })
          }
          <TableCell align='right' padding='none'>
            {
              isReplicable && replicateAction && (
                <IconButton onClick={replicateAction.bind(this, key, row.id)}><FileCopyIcon /></IconButton>
              )
            }
            {
              isEditable && (
                <IconButton onClick={updateAction.bind(this, key, row.id)}><EditTwoToneIcon /></IconButton>
              )
            }
            <IconButton onClick={deleteAction.bind(this, key, row.id)}><DeleteTwoToneIcon /></IconButton>
          </TableCell>
        </TableRow>
      )
    })

    return tableData
  }

  getDisplayName = () => {
    // const { language = 'en', filterAction, filter, offsetAction, switchLimitAction, updateAction, deleteAction, sortAction, searchAction, orderBy, order, searchText, offset, limit, total, columns, toogleDialog, contentDialog, data, isLoading, isDialogOpen, title, createAction } = this.props
    // switch (type) {
    //   case value:

    //     break

    //   default:
    //     break
    // }
  }

  componentWillUnmount () {

  }

  render () {
    // const actions = this.getActions()
    const { isExportable, exportAction, role, isInsertable, primaryText, primaryAction, total, count, allCategories, language = 'en', filterAction, filter, offsetAction, switchLimitAction, updateAction, deleteAction, sortAction, searchAction, orderBy, order, searchText, offset, limit, columns, toogleDialog, contentDialog, data, isLoading, isDialogOpen, title, createAction } = this.props
    if (!orderBy) {
      return <></>
    }
    // return <></>
    // toJS(data)
    // if (data !== null && data.length > 0) {
    //   toJS(data)
    // }
    // console.log(data)
    // toJS(data)
    // console.log(total)
    // console.log(offset)
    // console.log(Math.ceil(total / limit))

    // const { isFilterOpened } = this.state

    let isFilterableList = []
    isFilterableList = columns.filter((column) => column.isFilterable)
    const tableHead = this.getTableHead()
    const tableData = this.getTableData()
    return (
      <div className='custom-table'>
        <Paper elevation={0} variant='outlined'>
          <div className='flex-row space-between align-items-center'>
            <div className='mt-table-title'>
              {title}
            </div>
            <div className='flex-row'>
              {
                primaryText &&
                (
                  <div className='mt-table-add-button'>
                    <Button
                      disableElevation
                      variant='contained'
                      color='primary'
                      // className={classes.button}
                      // startIcon={<AddTwoToneIcon />}
                      onClick={primaryAction}
                    >
                      {primaryText}
                    </Button>
                  </div>
                )
              }
              {
                isExportable && (
                  <div className='mt-table-export-button'>
                    <Button
                      disableElevation
                      variant='contained'
                      color='primary'
                      // className={classes.button}
                      // startIcon={<AddTwoToneIcon />}
                      onClick={exportAction}
                    >
                      {'Export'}
                    </Button>
                  </div>
                )
              }
              {
                (isInsertable || role === 'DEVELOPER') && (
                  <div className='mt-table-add-button'>
                    <Button
                      disableElevation
                      variant='contained'
                      color='primary'
                      // className={classes.button}
                      startIcon={<AddTwoToneIcon />}
                      onClick={createAction}
                    >
                      {`New ${title}`}
                    </Button>
                  </div>
                )
              }
            </div>
          </div>
          <Divider />
          <div className='table-toolbar flex-row align-items-center space-between'>
            <div className='flex-row'>
              <div className='limit-field-container mr-10'>
                <CustomOutlinedPicker
                  optionList={pageOptions}
                  isFormik={false}
                  value={limit}
                  onChange={switchLimitAction}
                  isMultilingual={false}
                />
                <div className='text-muted'><b>Rows</b> per page</div>
              </div>
              {
                filter && isFilterableList.map((column, key) => {
                  let isParrent = true
                  const field = column
                  if (field.displayConditions && !isEmpty(field.displayConditions)) {
                    isParrent = false
                    let isHitTheCondition = false
                    let displayConditions = field.displayConditions
                    if (typeof field.displayConditions === 'string') {
                      displayConditions = JSON.parse(field.displayConditions)
                    }
                    // toJS(displayConditions)
                    if (displayConditions) {
                      if (!displayConditions) {
                        isHitTheCondition = true
                      }
                      if (displayConditions.or) {
                        displayConditions.or.forEach(condition => {
                          if (filter[condition.field] === condition.value) {
                            isHitTheCondition = true
                          }
                        })
                      }
                    }
                    if (!isHitTheCondition) {
                      return <div key={key} />
                    }
                  }
                  let optionList = []
                  if (column.categoryStructureIdentifier && allCategories[column.categoryStructureIdentifier]) {
                    optionList = allCategories[column.categoryStructureIdentifier]
                  }
                  return (
                    <div className='filter-field-container mr-10' key={key}>
                      <CustomOutlinedPicker
                        hasNoneOption={false}
                        hasAllOption
                        optionList={optionList}
                        isFormik={false}
                        value={filter[column.identifier]}
                        onChange={filterAction.bind(this, column.identifier, isParrent)}
                      />
                      <div className='text-muted'><b>Filter</b> by {`${column.displayName[language]}`}</div>
                    </div>
                  )
                })
              }
            </div>
            <div className='search-field-container'>
              <CustomOutlinedTextField
                placeholder='Search'
                isFormik={false}
                value={searchText}
                onChange={searchAction}
              />
              <div className='text-muted'><b>Search</b> in all text fields</div>
            </div>
          </div>
          <TableContainer style={{ minHeight: 360 }}>
            <Table>
              <TableHead>
                {tableHead}
              </TableHead>
              <TableBody>
                {tableData}
              </TableBody>
            </Table>
          </TableContainer>
          <div className='pagination'>
            {/* <CssBaseline /> */}
            {/* <Pagination color='primary' count={Math.ceil(total / limit)} showFirstButton showLastButton /> */}
            <Pagination
              limit={limit}
              offset={offset}
              total={total}
              onClick={offsetAction}
              currentPageColor='primary'
              otherPageColor='default'
            />
          </div>
        </Paper>
      </div>
    )
  }
}
