import React from 'react'
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Link,
  // useLocation,
  // useHistory
} from 'react-router-dom'
import Dashboard from '@src/components/screens/Dashboard'
import Admin from '@src/components/screens/Admin'
import Structure from '@src/components/screens/Structure'
import Field from '@src/components/screens/Field'
import Content from '@src/components/screens/Content'
import User from '@src/components/screens/User'
import Relation from '@src/components/screens/Relation'
import Category from '@src/components/screens/Category'
import Test from '@src/views/Test'
import { createBrowserHistory } from 'history'
import { publicRoutes } from '@src/routes/publicRoutes'
import { withTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import CustomBreadcrumbs from '@src/components/layouts/CustomBreadcrumbs'
import { toJS, getBreadcrumb } from '@src/utils/commonUtil'
import Layout from '@src/components/layouts/Layout'
import '@src/loaders'
import { Helmet } from 'react-helmet'

@withTranslation()
@inject('uiStore')
@observer

class RoutesLayout extends React.Component {
  componentDidMount () {
    this.init()
    // toJS(this.props.i18n)
  }

  init = async () => {
    if (!publicRoutes.find((config) => config.path === window.location.pathname)) {
      const { uiStore } = this.props
      await uiStore.init()
    }
  }

  render () {
    const { uiStore } = this.props
    return (
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{uiStore.panelName}</title>
        </Helmet>
        <Router>
          <Switch>
            {
              publicRoutes.map(config => (
                <Route key={config.path} {...config} />
              ))
            }
            {
              uiStore.isReady && (
                <Route path='/' component={Layout} />
              )
            }
          </Switch>
        </Router>
      </>
    )
  }
}

export default RoutesLayout
