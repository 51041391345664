import React from 'react'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import Dropzone from 'react-dropzone'
// import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone'
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone'
import commonConfig from '@src/config/commonConfig'
// import { Document, Page } from 'react-pdf'
import { PDFViewer } from 'react-view-pdf'
import AttachFileTwoToneIcon from '@material-ui/icons/AttachFileTwoTone';

class CustomImagePicker extends React.Component {

  constructor (props) {
    super(props)
    const defaultValue = this.props.field.value
    this.state = {
      value: defaultValue,
      pageNumber: 1,
      isLoading: false
    }
  }

  onDrop = async (acceptedFiles) => {
    this.setState({ isLoading: true })
    const { section, limit, uploadFile, uploadFileType } = this.props
    const urls = this.props.field.value
    // const setFieldValue = this.props.form.setFieldValue
    var totalImages = acceptedFiles.length
    var uploadedImages = 0
    var totalUrls = urls.length

    const uploadFiles = async () => {
      const name = this.props.field.name
      const setFieldValue = this.props.form.setFieldValue
      // new Promise(async (resolve, reject) => {
      try {
        if (uploadedImages < totalImages) {
          const file = await uploadFile(uploadFileType, acceptedFiles[uploadedImages])
          // console.log(file)
          urls.push({
            original: file.url.original,
            thumbnail: file.url.original
          })
          setFieldValue(name, urls)
          uploadedImages = uploadedImages + 1
          console.log(uploadedImages + '/' + totalImages)
          uploadFiles()
          if (uploadedImages === totalImages) {
            // alert('Images uploaded successfully')
            this.setState({ isLoading: false })
          }
        }
        // resolve()
      } catch (err) {
        console.log(err)
        // reject()
      }
      // })
    }

    if (totalImages + totalUrls > limit) {
      alert('Maximum total number of the files : ' + limit)
    } else {
      uploadFiles()
    }
  }

  deleteFile = (thumbnail) => {
    const files = []
    this.state.value.map(function (file) {
      if (file.thumbnail !== thumbnail) {
        files.push(file)
      }
    })
    const name = this.props.field.name
    const setFieldValue = this.props.form.setFieldValue
    setFieldValue(name, files)
    this.setState({ value: files })
  }

  downloadFile = (thumbnail) => {

  }

  chooseCover = (thumbnail) => {
    // const { formik, name } = this.props
    // const { values } = formik
    // let files = []
    // values[name].map(function (file) {
    //   if (file.thumbnail === thumbnail) {
    //     files.push(file)
    //   }
    // })
    // values[name].map(function (file) {
    //   if (file.thumbnail !== thumbnail) {
    //     files.push(file)
    //   }
    // })
    // console.log(files)
    // formik.setFieldValue(name, files)
  }

  handleChange = () => {
    const name = this.props.field.name
    const setFieldValue = this.props.form.setFieldValue
    setFieldValue(name, !this.state.value)
    this.setState({ value: !this.state.value })
  }

  documentLoadSuccess = (numPages) => {
    this.setState({ pageNumber: numPages })
  }

  render () {
    const fileTypes = {
      // image: 'image/png, image/jpeg'
      pdf: 'application/pdf'
    }
    const { handleChange, documentLoadSuccess } = this
    const { value, pageNumber, isLoading } = this.state
    const { multiple, name } = this.props

    return (
      <div>
        <Dropzone accept={fileTypes.image} onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div className='multiple-files-field'>
              {
                isLoading &&
                <p>{'uploading...'}</p>
              }
              {
                !isLoading && value.length === 0 &&
                (
                  <div className='dropzone-picker' {...getRootProps()}>
                    <input name={name} {...getInputProps()} />
                    <div>
                      <p>
                        {'Drop files here or '}
                        <span className='uppy-DragDrop-browse'>browse</span>
                      </p>
                      {
                        // notes &&
                        // <p className='notes'>{notes}</p>
                      }
                      {/* <p className='notes'>{i18n.t('common:seo-explanation')}</p> */}
                    </div>
                  </div>
                )
              }
              <div className='dropzone-preview'>
                {
                  (multiple && value.length > 0) &&
                  (
                    <div className='square-picker' {...getRootProps()}>
                      <input name={name} {...getInputProps()} />
                      <div className='icon'>
                        <AddTwoToneIcon />
                        {/* <p>
                    {'Drop files here or '}
                    <span className='uppy-DragDrop-browse'>browse</span>
                  </p> */}
                        {
                          // notes &&
                          // <p className='notes'>{notes}</p>
                        }
                        {/* <p className='notes'>{i18n.t('common:seo-explanation')}</p> */}
                      </div>
                    </div>
                  )
                }
                {
                  value && value.map(file => (
                    <div className='wrapper centered-and-cropped' key={file.thumbnail}>
                      <div className='close' onClick={() => this.deleteFile(file.thumbnail)}>
                        <div className='icon'>
                          <DeleteTwoToneIcon />
                        </div>
                      </div>
                      <div className='thumbnail' onClick={() => this.chooseCover(file.thumbnail)}>
                        <div className='download' onClick={() => this.downloadFile(file.thumbnail)}>
                          <div className='icon'>
                            <AttachFileTwoToneIcon fontSize='large' />
                            <div className='url'>{file.thumbnail}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          )}
        </Dropzone>
        {/* <input
          accept="image/*"
          className='hide'
          // id="contained-button-file"
          multiple
          type="file"
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            Upload
        </Button>
        </label>
        <input accept="image/*"
          className='hide'
          // id="icon-button-file"
          type="file"
        />
        <label htmlFor="icon-button-file">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <VideoLabelIcon />
          </IconButton>
        </label> */}
      </div>
    )
  }
}

export default CustomImagePicker
