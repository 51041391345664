import React from 'react'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import Dropzone from 'react-dropzone'
// import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import commonConfig from '@src/config/commonConfig'
import ReactPlayer from 'react-player'
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone'
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone'

class CustomVideoPicker extends React.Component {

  constructor (props) {
    super(props)
    const defaultValue = this.props.field.value
    this.state = {
      value: defaultValue,
      isPlaying: false,
      isLoading: false,
      isFail: false
    }
  }

  onDrop = async (acceptedFiles) => {
    this.setState({ isLoading: true })
    const { section, limit, uploadFile, uploadFileType } = this.props
    const urls = this.props.field.value
    const setFieldValue = this.props.form.setFieldValue
    var totalImages = acceptedFiles.length
    var uploadedImages = 0
    var totalUrls = urls.length

    const uploadFiles = () => {
      const name = this.props.field.name
      const setFieldValue = this.props.form.setFieldValue
      new Promise(async (resolve, reject) => {
        try {
          if (uploadedImages < totalImages) {
            const video = await uploadFile(uploadFileType, acceptedFiles[uploadedImages])
            console.log(video)
            urls.push({
              original: video.url.original,
              thumbnail: video.url.original
            })
            setFieldValue(name, urls)
            uploadedImages = uploadedImages + 1
            console.log(uploadedImages + '/' + totalImages)
            uploadFiles()
            if (uploadedImages === totalImages) {
              // alert('Images uploaded successfully')
              this.setState({ isLoading: false })
            }
          }
          resolve()
        } catch (err) {
          console.log(err)
          this.setState({ isLoading: false, isFail: true })
          reject()
        }
      })
    }

    if (totalImages + totalUrls > limit) {
      alert('Maximum total number of the images : ' + limit)
    } else {
      uploadFiles()
    }
  }

  deleteImage = (thumbnail) => {
    const images = []
    this.state.value.map(function (file) {
      if (file.thumbnail !== thumbnail) {
        images.push(file)
      }
    })
    const name = this.props.field.name
    const setFieldValue = this.props.form.setFieldValue
    setFieldValue(name, images)
    this.setState({ value: images })
  }

  chooseCover = (thumbnail) => {
    // const { formik, name } = this.props
    // const { values } = formik
    // let images = []
    // values[name].map(function (file) {
    //   if (file.thumbnail === thumbnail) {
    //     images.push(file)
    //   }
    // })
    // values[name].map(function (file) {
    //   if (file.thumbnail !== thumbnail) {
    //     images.push(file)
    //   }
    // })
    // console.log(images)
    // formik.setFieldValue(name, images)
  }

  handleChange = () => {
    const name = this.props.field.name
    const setFieldValue = this.props.form.setFieldValue
    setFieldValue(name, !this.state.value)
    this.setState({ value: !this.state.value })
  }

  render () {
    const fileTypes = {
      image: 'video/mp4'
      // pdf: 'application/pdf'
    }
    const { handleChange, onDrop } = this
    const { value, isLoading, isFail } = this.state
    const { name, multiple, limit, placeholder } = this.props

    return (
      <div>
        <Dropzone accept={fileTypes.image} onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div className='multiple-images-field'>
              {
                isLoading &&
                <p>{'uploading...'}</p>
              }
              {
                !isLoading && value.length === 0 &&
                (
                  <div className='dropzone-picker' {...getRootProps()}>
                    <input name={name} {...getInputProps()} />
                    <div>
                      <p>
                        {'Drop files here or '}
                        <span className='uppy-DragDrop-browse'>browse</span>
                      </p>
                      <p>
                        {
                          placeholder && placeholder.en
                        }
                        {
                          isFail && 'Please Retry'
                        }
                      </p>
                      {
                        // notes &&
                        // <p className='notes'>{notes}</p>
                      }
                      {/* <p className='notes'>{i18n.t('common:seo-explanation')}</p> */}
                    </div>
                  </div>
                )
              }
              <div className='dropzone-preview'>
                {
                  (limit > 1 && value.length > 0) &&
                  (
                    <div className='square-picker' {...getRootProps()}>
                      <input name={name} {...getInputProps()} />
                      <div className='icon'>
                        <AddTwoToneIcon />
                        {/* <p>
                    {'Drop files here or '}
                    <span className='uppy-DragDrop-browse'>browse</span>
                  </p> */}
                        {
                          // notes &&
                          // <p className='notes'>{notes}</p>
                        }
                        {/* <p className='notes'>{i18n.t('common:seo-explanation')}</p> */}
                      </div>
                    </div>
                  )
                }
                {
                  value && value.map(file => (
                    <div className='wrapper centered-and-cropped' key={file.thumbnail}>
                      <div className='close' onClick={() => this.deleteImage(file.thumbnail)}>
                        <div className='icon'>
                          <DeleteTwoToneIcon />
                        </div>
                      </div>
                      <div className='thumbnail' onClick={() => this.chooseCover(file.thumbnail)}>
                        <ReactPlayer
                          url={file.thumbnail}
                          playing={false}
                          width='100%'
                          height='100%'
                        />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          )}
        </Dropzone>
        {/* <input
          accept="image/*"
          className='hide'
          // id="contained-button-file"
          multiple
          type="file"
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            Upload
        </Button>
        </label>
        <input accept="image/*"
          className='hide'
          // id="icon-button-file"
          type="file"
        />
        <label htmlFor="icon-button-file">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <VideoLabelIcon />
          </IconButton>
        </label> */}
      </div>
    )
  }
}

export default CustomVideoPicker
