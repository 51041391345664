import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'

export const login = (username, password) => {
  return apiClient.post(
    paths.login,
    {
      username,
      password
    }
  )
}
