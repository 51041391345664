import { observable, action, decorate } from 'mobx'
// import * as api from '../services/api'
// import { ACCESS_TOKEN } from '../constants/tokenKeys'
import { toJS } from '@src/utils/commonUtil'
import { fetchStructureList, createStructure, updateStructure, deleteStructure } from '@src/api/structure'

export default class StructureFieldStore {
  @observable list = []
  @observable item = null
  @observable error = null
  @observable structureForm = {
    structureType: 'INFORMATION',
    identifier: 'test',
    displayName: 'test',
    displayNameEn: 'test',
    displayNameTc: 'test',
    displayNameSc: 'test',
    fieldList: []
  }

  @observable structureFieldForm = {
    identifier: 'test',
    type: 'TEXT',
    multilingual: true,
    sortable: true,
    displayable: true,
    searchable: true,
    isFilterable: false
  }

  @action
  setCurrentStructure = async (index) => {
    // toJS(this.list[index])
    // toJS(this.list.length)
    if (this.list.length > 0) {
      this.structureForm = this.list[index].data
    }
  }

  @action
  fetchStructureList = async () => {
    const data = await fetchStructureList()
    this.list = data
    // toJS(data)
    this.setCurrentStructure(0)
  }

  @action
  createStructure = async (data) => {
    this.clearError()
    try {
      await createStructure(data)
      await this.fetchStructureList()
    } catch (error) {
      console.log(error)
      this.error = error
    }
  }

  @action
  updateStructure = async (id, data) => {
    await updateStructure(id, data)
  }

  @action
  deleteStructure = async (id) => {
    await deleteStructure(id)
  }

  @action
  createStructureField = async (structureId, values) => {
    // toJS(values)
    // this.list.push(values)
    // await this.updateStructureField(structureId, this.list)
  }

  @action
  clearList = () => {
    this.list = []
  }

  @action
  clearItem = () => {
    this.item = []
  }

  @action
  clearError = () => {
    this.error = null
  }
}
