import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './pages'
import reportWebVitals from './reportWebVitals'

import { Provider } from 'mobx-react'
import RootStore from '@src/stores/RootStore'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { blueTheme } from '@src/styles/themes'
import '@src/services/i18n'

const rootStore = new RootStore()

ReactDOM.render(
  <Provider {...rootStore}>
    <MuiThemeProvider theme={blueTheme}>
      <App />
    </MuiThemeProvider>
  </Provider>
  ,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
