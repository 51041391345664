import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'

export const updateStructure = (structureId, data) => {
  const authHeaders = authUtil.getAuthHeaders()
  return apiClient.patch(
    paths.updateStructure(structureId),
    data,
    authHeaders
  )
}
