// import * as api from '../utils/api'
// import { translateErrorMessage } from '../utils/reusedFunctions'
import { observable, action } from 'mobx'

class FileUploaderStore {
  @observable urls = []
  @observable totalUploaded = 0
  @observable uploaded = 0

  constructor (rootStore) {

  }

  @action
  uploadFileList = async (file, section = 'ckeditor') => {
    // try {
    //   const { accessToken } = this.AuthStore
    //   const { url } = await api.uploadFile(accessToken, file, section)
    //   return url
    // } catch (error) {
    //   console.log(error)
    //   alert(translateErrorMessage(error.code))
    //   runInAction(() => {
    //     this.error = error
    //   })
    // }
  }

  @action
  uploadImage = async (file, section = 'ckeditor') => {
    // try {
    //   const { accessToken } = this.AuthStore
    //   const { url, thumbnail } = await api.uploadFile(accessToken, file, section)
    //   return {
    //     url: url,
    //     thumbnail: thumbnail
    //   }
    // } catch (error) {
    //   console.log(error)
    //   alert(translateErrorMessage(error.code))
    //   runInAction(() => {
    //     this.error = error
    //   })
    // }
  }

  @action
  uploadMultipleFiles = async (files, section = 'ckeditor') => {
    //   try {
    //     const { accessToken } = this.AuthStore
    //     runInAction(() => {
    //       this.totalUploaded = files.length
    //       files.map(async file => {
    //           const { url } = await api.uploadFile(accessToken, file, section)
    //           this.urls.push(url)
    //       })
    //     })
    //     // upload success
    //     // window.alert(translateErrorMessage('SUCCESSFULLY_UPLOADED'))
    //     // return urls
    //   } catch (error) {
    //     console.log(error)
    //     alert(translateErrorMessage(error.code))
    //     runInAction(() => {
    //       this.error = error
    //     })
    //   }
  }
}

export default FileUploaderStore
