import { observable, action, decorate } from 'mobx'
// import * as api from '../services/api'
// import { ACCESS_TOKEN } from '../constants/tokenKeys'
import { fetchUserList, createUser, updateUser, deleteUser } from '@src/api/user'
import { toJS } from '@src/utils/commonUtil'

export default class UserStore {
  @observable list = []
  @observable item = null
  @observable error = null
  @observable form = {}
  @observable structureOptions = {}

  constructor (rootStore) {
    // console.log(rootStore)
    // window.localStorage.setItem(ACCESS_TOKEN, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiQUNDRVNTX1RPS0VOIiwiaWQiOjQsInVzZXJuYW1lIjoicml2ZXJuQGZhbnRyZW5kLmNvbS5oayIsImlhdCI6MTU5MzcwNzIwMiwiZXhwIjoxNTk0MzEyMDAyfQ.0trDKF69j1-rGJHfnTCCgSVn3yeKlPS4N_OmbtwVCvs')
    this.rootStore = rootStore
  }

  @action
  setForm = async (userId) => {
    const user = this.list.find(item => item.id === userId)
    this.form = user.data
  }

  @action
  fetchUserList = async (structureId) => {
    const data = await fetchUserList(structureId)
    this.list = data
  }

  @action
  createUser = async (structureId, values) => {
    // toJS(this.list[structureId])
    // this.list[structureId].push(values)
    const { username, password } = values
    await createUser(structureId, username, password, values)
  }

  @action
  updateUser = async (structureId, userId, values) => {
    // toJS(this.list[structureId])
    // this.list[structureId].push(values)
    await updateUser(structureId, userId, values)
  }

  @action
  deleteUser = async (structureId, userId) => {
    // toJS(this.list[structureId])
    // this.list[structureId].push(values)
    console.log(userId)
    await deleteUser(structureId, userId)
  }

  @action
  clearUser () {
    this.list = []
  }
}
