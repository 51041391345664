import React from 'react'
import { inject, observer } from 'mobx-react'
import MiniDrawerLayout from '@src/components/layouts/MiniDrawerLayout'
import CategoryDialog from '@src/components/dialogs/CategoryDialog'
import CategoryTable from '@src/components/tables/CategoryTable'
import queryString from 'query-string'
import DeleteDialog from '@src/components/dialogs/DeleteDialog'
import {
  CREATE,
  UPDATE,
} from '@src/constants/keys'
import { toJS } from '@src/utils/commonUtil'

@inject('authStore', 'structureStore', 'categoryStore')
@observer

class Dashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      action: CREATE,
      isCategoryDialogOpened: false,
      isDeleteDialogOpened: false,
      currentStructureId: 0,
      currentCategoryIndex: 0
    }
  }

  componentDidMount () {
    const parsed = queryString.parse(window.location.search)
    const structureId = parseInt(parsed.id)
    this.setState({
      currentStructureId: structureId,
    })
    const { structureStore } = this.props
    structureStore.fetchStructureDetail(structureId)
    this.fetchCategoryList(structureId)
  }

  fetchAllStructures = () => {
    const { structureStore } = this.props
    structureStore.fetchAllStructures()
  }

  fetchCategoryList = (structureId) => {
    const { categoryStore } = this.props
    categoryStore.fetchCategoryList(structureId)
  }

  toogleCategoryDialog = () => {
    this.setState({ isCategoryDialogOpened: !this.state.isCategoryDialogOpened })
  }

  createCategory = async (values, { setSubmitting, setErrors }) => {
    const { structureStore, categoryStore } = this.props
    const { currentCategoryIndex } = this.state
    const structureList = structureStore.list
    const structureId = structureList[currentCategoryIndex].id
    await categoryStore.createCategory(structureId, values)
    this.toogleCategoryDialog()
  }

  toogleDeleteDialog = () => {
    this.setState({ isDeleteDialogOpened: !this.state.isDeleteDialogOpened })
  }

  createAction = () => {
    this.setState({
      action: CREATE,
      isCategoryDialogOpened: !this.state.isCategoryDialogOpened
    })
  }

  updateAction = async (event, rowData) => {
    // console.log(rowData)
    const { categoryStore } = this.props
    await categoryStore.setForm(rowData.id)
    this.setState({
      action: UPDATE,
      currentCategoryId: rowData.id,
      isCategoryDialogOpened: !this.state.isCategoryDialogOpened
    })
  }

  handleSubmitForm = async (values, { setSubmitting, setErrors }) => {
    const { categoryStore } = this.props
    const { action, currentCategoryId, currentStructureId } = this.state
    switch (action) {
      case CREATE:
        await categoryStore.createCategory(currentStructureId, values)
        break
      case UPDATE:
        await categoryStore.updateCategory(currentStructureId, currentCategoryId, values)
        break
      default:
        break
    }
    this.fetchCategoryList(currentStructureId)
    this.toogleCategoryDialog()
  }

  deleteAction = (event, rowData) => {
    // toJS(rowData)
    this.setState({
      currentCategoryId: rowData.id,
      isDeleteDialogOpened: !this.state.isCategoryDialogOpened
    })
  }

  handleDeleteCategory = async () => {
    const { categoryStore } = this.props
    await categoryStore.deleteCategory(this.state.currentStructureId, this.state.currentCategoryId)
    const { currentStructureId } = this.state
    this.fetchCategoryList(currentStructureId)
    this.toogleDeleteDialog()
  }

  getCategoryTableName = () => {
    const { authStore, structureStore, categoryStore } = this.props
    let categoryTableName = ''
    if (structureStore.item) {
      categoryTableName = structureStore.item.displayName
    }
    return categoryTableName
  }

  render () {
    const { getCategoryTableName, action, createAction, updateAction, deleteAction, handleSubmitForm, toogleCategoryDialog, toogleDeleteDialog, handleDeleteCategory } = this
    const { authStore, structureStore, categoryStore } = this.props
    const switchCategory = this.switchCategory
    const { isDeleteDialogOpened, isCategoryDialogOpened } = this.state
    const categoryList = categoryStore.list
    // toJS(structureStore.item.identifier)
    const categoryTableName = getCategoryTableName()
    return (
      <div>
        <CategoryTable
          title={categoryTableName}
          data={categoryList}
          createAction={createAction}
          updateAction={updateAction}
          deleteAction={deleteAction}
          structureFieldList={structureStore.structureFieldList}
        />
        <CategoryDialog
          action={action}
          isDialogOpened={isCategoryDialogOpened}
          toogleDialog={toogleCategoryDialog}
          submitForm={handleSubmitForm}
          structureFieldList={structureStore.structureFieldList}
          initialValues={categoryStore.form}
        />
        <DeleteDialog
          isDialogOpened={isDeleteDialogOpened}
          toogleDialog={toogleDeleteDialog}
          submitForm={handleDeleteCategory}
        />
      </div>
    )
  }
}

export default Dashboard
