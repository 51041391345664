import React from 'react'
import { inject, observer } from 'mobx-react'
import { toJS } from '@src/utils/commonUtil'

@inject('authStore', 'structureStore', 'contentStore', 'adminStore')
@observer

class Dashboard extends React.Component {
  componentDidMount () {
    toJS(this.props.authStore.jwt)
    if (!this.props.authStore.jwt) {
      this.props.history.replace('/login')
    }
  }

  render () {
    return (
      <div>
        Dashboard
      </div>
    )
  }
}

export default Dashboard
