import React from 'react'
import { withTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import FormPaper from '@src/components/papers/FormPaper'
import FormDialog from '@src/components/dialogs/FormDialog'
import DeleteDialog from '@src/components/dialogs/DeleteDialog'
import CustomTable from '@src/components/tables/CustomTable'
import {
  CREATE,
  UPDATE
} from '@src/constants/keys'
// import adminColumns from '@src/config/tableColumns/adminColumns'
import CustomForm from '@src/components/forms/CustomForm'
import CustomBreadcrumbs from '@src/components/layouts/CustomBreadcrumbs'
import { toJS } from '@src/utils/commonUtil'
import queryString from 'query-string'

@withTranslation()
@inject('authStore', 'structureStore', 'contentStore', 'adminStore', 'uiStore')
@observer

class Admin extends React.Component {
  constructor (props) {
    super(props)
    const { uiStore } = this.props
    const item = this.props.uiStore.allContents.systemStructureList.find((structure) => structure.identifier === 'administrator')
    const title = item.displayName.en
    if (uiStore.adminStructure) {
      this.state = {
        title: title,
        action: CREATE,
        isTableLoaded: true,
        isAdminDialogOpened: false,
        isFormVisible: false,
        isDeleteDialogOpened: false,
        currentAdminId: 0,
        searchText: '',
        filter: {
          role: 'ALL'
        },
        offset: 0,
        limit: 10,
        orderBy: uiStore.adminStructure.orderBy,
        order: uiStore.adminStructure.orderBy.order.toLowerCase()
      }
    }
  }

  componentDidMount () {
    this.fetchAdminList()
  }

  fetchAdminList = () => {
    const { adminStore } = this.props
    const { searchText, filter, offset, limit, orderBy, order } = this.state
    adminStore.fetchAdminList(searchText, filter, offset, limit, orderBy, order)
  }

  toogleAdminForm = () => {
    this.setState({ isFormVisible: !this.state.isFormVisible })
  }

  toogleAdminDialog = () => {
    this.setState({ isAdminDialogOpened: !this.state.isAdminDialogOpened })
  }

  toogleDeleteDialog = () => {
    this.setState({ isDeleteDialogOpened: !this.state.isDeleteDialogOpened })
  }

  createAction = async () => {
    const { adminStore } = this.props
    await adminStore.clearForm()
    this.setState({
      action: CREATE,
      isAdminDialogOpened: !this.state.isAdminDialogOpened
      // isFormVisible: !this.state.isFormVisible
    })
  }

  updateAction = async (index, adminId) => {
    // console.log(rowData)
    const { adminStore } = this.props
    await adminStore.setForm(adminId)
    this.setState({
      action: UPDATE,
      currentAdminId: adminId,
      isAdminDialogOpened: !this.state.isAdminDialogOpened
    })
  }

  handleSubmitForm = async (values, { setSubmitting, setErrors }) => {
    const { adminStore } = this.props
    const { action, currentAdminId } = this.state
    switch (action) {
      case CREATE:
        await adminStore.createAdmin(values)
        break
      case UPDATE:
        await adminStore.updateAdmin(currentAdminId, values)
        break
      default:
        break
    }
    this.fetchAdminList()
    this.toogleAdminDialog()
    // this.toogleAdminForm()
  }

  deleteAction = (key, adminId) => {
    // toJS(rowData.id)
    this.setState({
      currentAdminId: adminId,
      isDeleteDialogOpened: !this.state.isAdminDialogOpened
    })
  }

  handleDeleteAdmin = async () => {
    const { adminStore } = this.props
    await adminStore.deleteAdmin(this.state.currentAdminId)
    this.fetchAdminList()
    this.toogleDeleteDialog()
  }

  submitAdminForm = null

  bindSubmitForm = (submitForm) => {
    this.submitAdminForm = submitForm
  }

  handleDialogSubmitForm = (event) => {
    if (this.submitAdminForm) {
      this.submitAdminForm(event)
    }
  }

  handlePaperSubmitForm = (event) => {
    if (this.submitAdminForm) {
      this.submitAdminForm(event)
    }
  }

  sortAction = (identifier, langCode) => {
    // toJS(identifier)
    // toJS(langCode)
    const { orderBy, order } = this.state
    const isAsc = orderBy.identifier === identifier && orderBy.langCode === langCode && order === 'asc'
    this.setState({
      order: (isAsc ? 'desc' : 'asc'),
      orderBy: { identifier, langCode, order }
    }, () => {
      this.fetchAdminList()
    })
  }

  searchAction = (event) => {
    this.setState({ searchText: event.target.value }, () => {
      this.fetchAdminList()
    })
  }

  switchLimitAction = (event) => {
    console.log(event.target.value)
    this.setState({ limit: event.target.value })
  }

  offsetAction = (event, offset) => {
    this.setState({ offset: offset }, () => {
      this.fetchAdminList()
    })
  }

  filterAction = (identifier, event) => {
    const filter = this.state.filter
    filter[identifier] = event.target.value
    this.setState({ filter: filter }, () => {
      this.fetchAdminList()
    })
  }

  render () {
    const { filterAction, offsetAction, handleDialogSubmitForm, switchLimitAction, sortAction, searchAction, handlePaperSubmitForm, bindSubmitForm, toogleAdminForm, createAction, updateAction, deleteAction, handleSubmitForm, toogleAdminDialog, toogleDeleteDialog, handleDeleteAdmin } = this
    const { t, i18n, adminStore, uiStore } = this.props
    const {
      action,
      isAdminDialogOpened,
      isDeleteDialogOpened,
      isFormVisible,
      searchText,
      offset,
      limit,
      orderBy,
      order,
      filter,
      title
    } = this.state
    // console.log(t('actions'))
    // toJS(uiStore.language)
    // toJS(uiStore.adminFieldList)
    const language = uiStore.language

    return (
      <div>
        <CustomBreadcrumbs />
        {/* <div>
          <button onClick={() => i18n.changeLanguage('tc')}>中文</button>
          <button onClick={() => i18n.changeLanguage('en')}>English</button>
          <button onClick={() => i18n.changeLanguage('es')}>Español</button>
        </div> */}
        {
          !isFormVisible &&
          (
            <CustomTable
              title={title}
              columns={uiStore.adminStructure.fieldList}
              data={adminStore.list}
              createAction={createAction}
              updateAction={updateAction}
              deleteAction={deleteAction}
              total={adminStore.total}
              searchText={searchText}
              offset={offset}
              limit={limit}
              orderBy={orderBy}
              order={order}
              filter={filter}
              sortAction={sortAction}
              searchAction={searchAction}
              switchLimitAction={switchLimitAction}
              offsetAction={offsetAction}
              filterAction={filterAction}
              allCategories={uiStore.allCategories}
              language={uiStore.language}
              isInsertable
            />
          )
        }
        <FormDialog
          title={title}
          action={action}
          isDialogOpened={isAdminDialogOpened}
          toogleDialog={toogleAdminDialog}
          submitForm={handleDialogSubmitForm}
          dialogMaxWidth={uiStore.dialogMaxWidth}
          form={
            <CustomForm
              initialValues={adminStore.form}
              submitForm={handleSubmitForm}
              bindSubmitForm={bindSubmitForm}
              structureFieldList={uiStore.adminStructure.fieldList}
              allCategories={uiStore.allCategories}
              language={uiStore.language}
            // error={baseModelStore.error}
            />
          }
        />
        {
          isFormVisible &&
          (
            <FormPaper
              title={title}
              action={action}
              toogleForm={toogleAdminForm}
              submitForm={handlePaperSubmitForm}
              form={
                <CustomForm
                  initialValues={adminStore.form}
                  submitForm={handleSubmitForm}
                  bindSubmitForm={bindSubmitForm}
                  structureFieldList={uiStore.adminStructure.fieldList}
                  allCategories={uiStore.allCategories}
                  language={uiStore.language}
                // error={baseModelStore.error}
                />
              }
            />
          )
        }
        <DeleteDialog
          isDialogOpened={isDeleteDialogOpened}
          toogleDialog={toogleDeleteDialog}
          submitForm={handleDeleteAdmin}
        />
      </div>
    )
  }
}

export default Admin
