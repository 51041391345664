import apiClient from '@src/services/apiClient'
import * as paths from '@src/config/apiPath'
import { authUtil } from '@src/utils'

export const updateUser = (structureId, userId, data) => {
  const authHeaders = authUtil.getAuthHeaders()
  return apiClient.patch(
    paths.user(structureId, userId),
    {
      data: data
    },
    authHeaders
  )
}
