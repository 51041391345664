import React from 'react'
import { Formik, Form, Field } from 'formik'
import CustomTextField from '@src/components/inputs/CustomTextField'
import Button from '@material-ui/core/Button'
import loginValidation from '@src/validations/loginValidation'
import { JSLog } from '@src/utils/commonUtil'

class LoginForm extends React.Component {
  render () {
    const { currentForm, submitForm } = this.props
    return (
      <Formik
        initialValues={currentForm}
        validationSchema={loginValidation}
        onSubmit={submitForm}
        enableReinitialize
      >
        {
          ({ setFieldValue, values }) => {
            // JSLog(values)
            return <Form>
              <div className='mt-10'></div>
              <Field
                name='email'
                type='text'
                // label='電郵'
                placeholder='username'
                // setFieldValue={setFieldValue}
                component={CustomTextField}
              />
              <div className='mt-10'></div>
              <Field
                name='password'
                type='password'
                // label='密碼'
                placeholder='password'
                setFieldValue={setFieldValue}
                component={CustomTextField}
              />
              <div className='mt-20'></div>
              <div className='right'>
                <Button className="background-red color-white border-red" variant="outlined" type='submit'>Login</Button>
              </div>
            </Form>
          }
        }
      </Formik>
    )
  }
}

export default LoginForm
