import { observable, action, makeObservable, runInAction } from 'mobx'
// import * as api from '../services/api'
// import { ACCESS_TOKEN } from '../constants/tokenKeys'
import { toJS } from '@src/utils/commonUtil'
import { fetchStructureDetail, fetchStructureList, createStructure, updateStructure, updateStructureField, deleteStructure } from '@src/api/structure'
import structureTypeOptions from '@src/config/structureTypeOptions'

export default class StructureStore {
  @observable list = []
  @observable total = 0
  @observable item = null
  @observable error = null
  @observable structureFieldList = null
  @observable contentOptions = {}
  @observable structures = null

  @observable form = {
    tableName: 'test',
    type: 'INFORMATION',
    identifier: 'test',
    isInsertable: false,
    isEditable: true
  }

  @observable structureFieldForm = {
    displayName: '',
    identifier: '',
    type: 'STRING',
    related: '',
    isMultilingual: false,
    isDisplayable: true,
    isSearchable: false,
    isFilterable: false,
    isEditable: true,
    isRequired: false
  }

  constructor (rootStore) {
    makeObservable(this)
    runInAction(() => {
      this.rootStore = rootStore
    })
  }

  @action
  clearForm = async () => {
    runInAction(() => {
      this.form = {
        tableName: '',
        type: '',
        identifier: ''
      }
    })
  }

  @action
  clearStructureFieldForm = async () => {
    runInAction(() => {
      this.structureFieldForm = {
        displayName: '',
        identifier: '',
        type: '',
        related: '',
        isMultilingual: true,
        isDisplayable: true,
        isSearchable: true,
        isFilterable: false
      }
    })
  }

  @action
  setForm = async (structureId) => {
    const structure = this.list.find(item => item.id === structureId)
    const multiLangFieldList = this.rootStore.uiStore.structureStructure.fieldList.filter((field) => field.isMultilingual)
    multiLangFieldList.forEach((field) => {
      const langKeyList = ['en', 'tc', 'sc']
      langKeyList.forEach((langCode) => {
        structure[`${field.identifier}${langCode}`] = structure[field.identifier][langCode]
      })
    })
    runInAction(() => {
      this.form = structure
    })
  }

  @action
  setStructureFieldForm = async (structureFieldIndex) => {
    // toJS(this.structureFieldList[structureFieldIndex])
    const structureField = this.structureFieldList[structureFieldIndex]
    const multiLangFieldList = this.rootStore.uiStore.fieldStructure.fieldList.filter((field) => field.isMultilingual)
    multiLangFieldList.forEach((field) => {
      const langKeyList = ['en', 'tc', 'sc']
      if (structureField[field.identifier]) {
        langKeyList.forEach((langCode) => {
          structureField[`${field.identifier}${langCode}`] = structureField[field.identifier][langCode]
        })
      }
    })
    runInAction(() => {
      this.structureFieldForm = structureField
    })
  }

  @action
  setFeildList = async (structureId) => {
    const structure = this.list.find(item => item.id === structureId)
    console.log(structure)
    // this.structureFieldList = structure.fieldList
  }

  // @action
  // setCurrentStructure = async (index) => {
  //   // toJS(this.list.length)
  //   if (this.list.length > 0) {
  //     this.structureForm = this.list[index].data
  //   }
  // }

  @action
  fetchStructureDetail = async (structureId) => {
    const data = await fetchStructureDetail(structureId)
    runInAction(() => {
      this.item = data
      this.structureFieldList = data.fieldList
    })
    // toJS(data)
    // const contentOptions = {}
    // this.structureFieldList.map(async (field) => {
    //   if (field.type === 'select' || field.type === 'tag') {
    //     contentOptions[field.related] = []
    //     // toJS(field)
    //     // await this.rootStore.contentStore.fetchContentList(field.related)
    //   }
    // })
    // // toJS(contentOptions)
    // this.contentOptions = contentOptions
    // await this.fetchStructureFieldList()
  }

  @action
  fetchStructureFieldList = async (searchText, filter, offset, limit, orderBy, order) => {
    let list = this.item.fieldList
    if (filter) {
      for (const [key, value] of Object.entries(filter)) {
        if (value !== 'ALL') {
          list = list.filter(item => item.type === value)
        }
      }
    }
    if (searchText) {
      list = list.filter(item => item.identifier.indexOf(searchText) !== -1)
    }
    // if (order === 'asc') {
    //   list.sort((a, b) => {
    //     return a[orderBy].localeCompare(b[orderBy])
    //   })
    // }
    // toJS(list)
    // toJS(orderBy)

    runInAction(() => {
      this.structureFieldList = list
    })
    // console.log(this.item)
    // try {
    //   const data = await fetchStructureList(searchText, filter, offset, limit, orderBy, order)
    //   this.list = data.list
    //   this.total = data.count
    // } catch (error) {
    //   console.log(error)
    //   console.log('Network Error')
    // }
    // const structures = {}
    // structureTypeOptions.map((type) => {
    //   structures[type.value] = this.list.filter(structure => structure.structureType === type.value)
    // })
    // this.structures = structures
    // toJS(this.structures)
  }

  @action
  fetchStructureList = async (searchText, filter, offset, limit, orderBy, order) => {
    try {
      const data = await fetchStructureList(searchText, filter, offset, limit, orderBy, order)
      runInAction(() => {
        this.list = data.list
        this.total = data.count
      })
    } catch (error) {
      console.log(error)
      console.log('Network Error')
    }
  }

  @action
  createStructure = async (data) => {
    // console.log(data)
    const postData = {}
    postData.tableName = data.tableName
    postData.type = data.type
    postData.identifier = data.identifier
    const langKeyList = ['en', 'tc', 'sc']
    postData.displayName = {}
    langKeyList.forEach((langCode) => {
      let _displayName = ''
      if (data[`displayName${langCode}`]) {
        _displayName = data[`displayName${langCode}`]
      }
      postData.displayName[langCode] = _displayName
    })
    // this.clearError()
    try {
      await createStructure(postData)
      await this.rootStore.uiStore.init()
    } catch (error) {
      console.log(error)
      this.error = error
    }
  }

  @action
  updateStructure = async (id, data) => {
    const postData = {}
    postData.tableName = data.tableName
    postData.type = data.type
    postData.identifier = data.identifier
    postData.isInsertable = data.isInsertable
    const langKeyList = ['en', 'tc', 'sc']
    postData.displayName = {}
    langKeyList.forEach((langCode) => {
      let _displayName = ''
      if (data[`displayName${langCode}`]) {
        _displayName = data[`displayName${langCode}`]
      }
      postData.displayName[langCode] = _displayName
    })
    try {
      // toJS(postData)
      await updateStructure(id, postData)
      await this.rootStore.uiStore.init()
    } catch (error) {
      console.log(error)
      this.error = error
    }
  }

  @action
  deleteStructure = async (id) => {
    await deleteStructure(id)
  }

  @action
  createStructureField = async (structureId, data) => {
    const postData = {}
    const langKeyList = ['en', 'tc', 'sc']
    this.rootStore.uiStore.fieldStructure.fieldList.forEach((field) => {
      if (field.isMultilingual) {
        postData[field.identifier] = {}
        langKeyList.forEach((langCode) => {
          const fieldName = `${field.identifier}${langCode}`
          if (data[fieldName]) {
            postData[field.identifier][langCode] = data[fieldName]
          } else {
            postData[field.identifier][langCode] = ''
          }
        })
      } else {
        postData[field.identifier] = data[field.identifier]
      }
    })
    this.structureFieldList.push(postData)
    await updateStructureField(structureId, this.structureFieldList)
    this.structureFieldList = []
    await this.fetchStructureDetail(structureId)
  }

  @action
  updateStructureField = async (structureId, structureFieldIndex, data) => {
    const postData = {}
    const langKeyList = ['en', 'tc', 'sc']
    this.rootStore.uiStore.fieldStructure.fieldList.forEach((field) => {
      if (field.isMultilingual) {
        postData[field.identifier] = {}
        langKeyList.forEach((langCode) => {
          if (data[`${field.identifier}${langCode}`]) {
            postData[field.identifier][langCode] = data[`${field.identifier}${langCode}`]
          } else {
            postData[field.identifier][langCode] = ''
          }
        })
      } else {
        postData[field.identifier] = data[field.identifier]
      }
    })
    runInAction(async () => {
      this.structureFieldList[structureFieldIndex] = postData
      await updateStructureField(structureId, this.structureFieldList)
      this.structureFieldList = []
      await this.fetchStructureDetail(structureId)
    })
  }

  @action
  updateStructureFieldList = async (structureId, structureFieldList) => {
    await updateStructureField(structureId, structureFieldList)
    runInAction(() => {
      this.structureFieldList = []
    })
    await this.fetchStructureDetail(structureId)
  }

  @action
  deleteStructureField = async (structureId, structureFieldIndex) => {
    // toJS(this.structureFieldList)
    this.structureFieldList = this.structureFieldList.filter((item, key) => { return key !== structureFieldIndex })
    await updateStructureField(structureId, this.structureFieldList)
    this.structureFieldList = []
    await this.fetchStructureDetail(structureId)
  }

  @action
  clearList = () => {
    this.list = []
  }

  @action
  clearItem = () => {
    this.item = []
  }

  @action
  clearError = () => {
    this.error = null
  }
}
