import React from 'react'
import { inject, observer } from 'mobx-react'
import MiniDrawerLayout from '@src/components/layouts/MiniDrawerLayout'
import AdminDialog from '@src/components/dialogs/AdminDialog'
import DeleteDialog from '@src/components/dialogs/DeleteDialog'
import AdminTable from '@src/components/tables/AdminTable'
import {
  CREATE,
  UPDATE,
} from '@src/constants/keys'
import { toJS } from '@src/utils/commonUtil'

@inject('authStore', 'structureStore', 'contentStore', 'adminStore')
@observer

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      action: CREATE,
      isTableLoading: false,
      isAdminDialogOpened: false,
      isDeleteDialogOpened: false,
      currentAdminId: 0
    }
  }
  componentDidMount () {
    // this.fetchAllAdmins()
  }
  fetchAllAdmins = () => {
    const { adminStore } = this.props
    adminStore.fetchAdminList()
  }
  toogleAdminDialog = () => {
    this.setState({ isAdminDialogOpened: !this.state.isAdminDialogOpened })
  }
  toogleDeleteDialog = () => {
    this.setState({ isDeleteDialogOpened: !this.state.isDeleteDialogOpened })
  }
  createAction = () => {
    this.setState({
      action: CREATE,
      isAdminDialogOpened: !this.state.isAdminDialogOpened
    })
  }
  updateAction = async (event, rowData) => {
    // console.log(rowData)
    const { adminStore } = this.props
    await adminStore.setForm(rowData.id)
    this.setState({
      action: UPDATE,
      currentAdminId: rowData.id,
      isAdminDialogOpened: !this.state.isAdminDialogOpened
    })
  }
  handleSubmitForm = async (values, { setSubmitting, setErrors }) => {
    const { adminStore } = this.props
    const { action, currentAdminId } = this.state
    switch (action) {
      case CREATE:
        await adminStore.createAdmin(values)
        break
      case UPDATE:
        await adminStore.updateAdmin(currentAdminId, values)
        break
      default:
        break
    }
    this.fetchAllAdmins()
    this.toogleAdminDialog()
  }
  deleteAction = (event, rowData) => {
    // toJS(rowData.id)
    this.setState({
      currentAdminId: rowData.id,
      isDeleteDialogOpened: !this.state.isAdminDialogOpened
    })
  }
  handleDeleteAdmin = async () => {
    const { adminStore } = this.props
    await adminStore.deleteAdmin(this.state.currentAdminId)
    this.fetchAllAdmins()
    this.toogleDeleteDialog()
  }
  render () {
    const { createAction, updateAction, deleteAction, handleSubmitForm, toogleAdminDialog, toogleDeleteDialog, handleDeleteAdmin } = this
    const { adminStore } = this.props
    const { action, isAdminDialogOpened, isDeleteDialogOpened } = this.state
    return (
      <div>
        {/* <AdminTable
          title={'Admin'}
          data={adminStore.list}
          createAction={createAction}
          updateAction={updateAction}
          deleteAction={deleteAction}
        />
        <AdminDialog
          action={action}
          isDialogOpened={isAdminDialogOpened}
          toogleDialog={toogleAdminDialog}
          submitForm={handleSubmitForm}
          initialValues={adminStore.form}
        />
        <DeleteDialog
          isDialogOpened={isDeleteDialogOpened}
          toogleDialog={toogleDeleteDialog}
          submitForm={handleDeleteAdmin}
        /> */}
      </div>
    )
  }
}

export default Dashboard
